import Add24 from './Add24';
import List24 from './List24';
import Menu24 from './Menu24';
import Rooms24 from './Rooms24';
import Close24 from './Close24';
import Delete24 from './Delete24';
import Search24 from './Search24';
import CaretDown24 from './CaretDown24';
import CaretUp24 from './CaretUp24';
import Toggle24 from './Toggle24';
import Loading from './Loading';
import Upload24 from './Upload24';
import Check24 from './Check24';
import ArrowLeft24 from './ArrowLeft24';
import Edit24 from './Edit24';

let icons = {
  add: Add24,
  arrowLeft: ArrowLeft24,
  toggle: Toggle24,
  list: List24,
  menu: Menu24,
  rooms: Rooms24,
  close: Close24,
  check: Check24,
  delete: Delete24,
  search: Search24,
  caretDown: CaretDown24,
  caretUp: CaretUp24,
  loading: Loading,
  upload: Upload24,
  edit: Edit24,
};

export default icons;

import React, { useCallback, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

// Custom hooks
import { useDraggableWithinWall } from './hooks/useDraggableWithinWall';
import { useRoomScale } from '../RoomComposer/hooks/useRoomScale';

// Components
import icons from '../_icons';
import LazyImage from '../LazyImage';

// Assets
import './RoomWallArtwork.css';

const MotionLazyImage = motion.custom(LazyImage);

let RoomWallArtwork = ({
  placement,
  artwork,
  isSelected,
  onSelect,
  onUnselect,
  onMoved,
  onRequestRemove,
  wallConstraints,
}) => {
  ///////////////////////////////
  // Custom hooks

  let { toPixels } = useRoomScale();

  let left = toPixels(placement.x_inches);
  let top = toPixels(placement.y_inches);

  let { isDragging, isMouseDown, draftPx, startDrag } = useDraggableWithinWall({
    wallConstraints,
    currentPx: { left, top },
    onValidDrop: useCallback(
      ({ draftPx }) => {
        onMoved({
          placement,
          newPx: draftPx,
        });
      },
      [onMoved, placement]
    ),
  });

  ///////////////////////////////
  // Event handlers

  let onRemoveClick = useCallback(
    (evt) => {
      onRequestRemove({
        placement,
      });
    },
    [onRequestRemove, placement]
  );

  let onPointerDown = useCallback(
    (evt) => {
      evt.currentTarget.setPointerCapture(evt.pointerId);
      startDrag(evt);
    },
    [startDrag]
  );

  let onPointerCancel = useCallback((evt) => {
    evt.currentTarget.releasePointerCapture(evt.pointerId);
  }, []);

  let onMouseUp = useCallback(
    (evt) => {
      // evt.stopPropagation();
      if (!isDragging) {
        // debugger;
        onSelect({
          placement,
        });
      }
    },
    [isDragging, onSelect, placement]
  );

  // Increment placement's current left/top with the dragged px
  if (draftPx) {
    left = draftPx.left;
    top = draftPx.top;
  }

  // Infer px height from width, rather than using height in inches
  // to be more visually accurate to the image
  let aspectRatio =
    artwork.featured_image.canvas.height / artwork.featured_image.canvas.width;
  let width = toPixels(artwork.width_inches);
  let height = width * aspectRatio;

  let spring = {
    type: 'spring',
    stiffness: 1000,
    damping: 50,
  };

  let scaleX = width / artwork.featured_image.canvas.width;
  let scaleY = height / artwork.featured_image.canvas.height;

  let [isHovering, setHovering] = useState(false);
  let onMouseEnter = useCallback(() => {
    setHovering(true);
  }, []);
  let onMouseLeave = useCallback(() => {
    setHovering(false);
  }, []);

  return (
    <OutsideClickHandler
      onOutsideClick={(evt) => {
        setHovering(false);
        onUnselect(evt);
      }}
    >
      <motion.div
        className={cx('RoomWallArtwork', {
          'is-dragging': isMouseDown,
          'is-selected': isSelected,
        })}
        animate={{
          x: left,
          y: top,
        }}
        style={{
          pointerEvents: 'none',
          width,
          height,
        }}
        transition={spring}
        initial={false}
        onPointerDown={onPointerDown}
        onPointerCancel={onPointerCancel}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <MotionLazyImage
          key={artwork.id}
          alt={artwork.title}
          src={artwork.featured_image.canvas.url}
          width={artwork.featured_image.canvas.width}
          height={artwork.featured_image.canvas.height}
          draggable={false}
          transition={spring}
          initial={false}
          placeholder={artwork.featured_image.canvas.lqip_url}
          style={{
            pointerEvents: 'auto',
            width: artwork.featured_image.canvas.width,
            height: artwork.featured_image.canvas.height,
          }}
          animate={{
            originX: 0,
            originY: 0,
            scaleX,
            scaleY,
          }}
        />
        <AnimatePresence>
          {isHovering && !isMouseDown && (
            <div className="RoomWallArtwork-deleteBtnContainer">
              <motion.button
                onPointerDown={(evt) => evt.stopPropagation()}
                onClick={onRemoveClick}
                onMouseUp={(evt) => evt.stopPropagation()}
                className="squareBtn RoomWallArtwork-deleteBtn"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ easing: 'easeInOut', duration: 0.1 }}
                style={{
                  pointerEvents: 'auto',
                }}
              >
                <icons.close />
              </motion.button>
            </div>
          )}
        </AnimatePresence>
      </motion.div>
    </OutsideClickHandler>
  );
};

export default RoomWallArtwork;

import React from 'react';
import { useLifecycles } from 'react-use';

// Assets
import './AccountDisabled.css';

// Components
import Button from '../Button';

// Hooks
import { Heading } from '../../hooks/DocumentOutline';
import { useTheme, LIGHT_MODE, DARK_MODE } from '../../hooks/Theme';

export default function AccountDisabled() {
  let { setTheme } = useTheme();

  useLifecycles(
    () => {
      setTheme(DARK_MODE);
    },
    () => {
      setTheme(LIGHT_MODE);
    }
  );

  return (
    <div className="AccountDisabled">
      <div className="AccountDisabled-leftCol">
        <div className="AccountDisabled-header">
          <Heading className="f-heading-01">
            Your account is currently disabled.
          </Heading>
        </div>

        <div className="AccountDisabled-footer">
          <div className="f-body-01">
            Please contact American Cyborg for more information.
          </div>
          <Button
            label="Contact us"
            element="a"
            elProps={{ href: 'mailto:info@americancyb.org' }}
          />
        </div>
      </div>
    </div>
  );
}

import React from 'react';

let DocumentOutlineContext = React.createContext({
  level: 1,
});

let Section = React.forwardRef((props, ref) => {
  let context = React.useContext(DocumentOutlineContext);

  let value = React.useMemo(() => ({ level: context.level + 1 }), [
    context.level,
  ]);

  let Node = props.nodeName || 'section';

  return (
    <DocumentOutlineContext.Provider value={value}>
      <Node {...props} ref={ref} />
    </DocumentOutlineContext.Provider>
  );
});

let Heading = React.forwardRef((props, ref) => {
  let context = React.useContext(DocumentOutlineContext);
  let HeadingLevel = `h${Math.min(context.level, 6)}`;
  return <HeadingLevel {...props} ref={ref} />;
});

export { Section, Heading };

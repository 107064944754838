import React from 'react';

// Assets
import './Toast.css';

export let Toast = ({ message, type, onCloseClick = () => null }) => {
  return (
    <button
      onClick={onCloseClick}
      className={`f-caption-01 Toast ${type ? `Toast--${type}` : ''}`}
    >
      <div className="Toast-inner">
        {message}
        <div className="Toast-btn">Close</div>
      </div>
    </button>
  );
};

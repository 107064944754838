export let getRoomCrop = (newRoomSize, currentRoomSize) => {
  if (!newRoomSize || !currentRoomSize || newRoomSize === currentRoomSize) {
    return null;
  }
  let widthDiff = newRoomSize.widthInches - currentRoomSize.widthInches;
  let heightDiff = newRoomSize.heightInches - currentRoomSize.heightInches;
  let crop = {
    top: heightDiff / 2,
    left: widthDiff / 2,
    // right: widthDiff / 2 + newRoomSize.widthInches,
    // bottom: heightDiff / 2 + newRoomSize.heightInches,
  };
  return crop;
};

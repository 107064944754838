import axios from 'axios';

export let getArtwork = (id) => {
  return axios.get(`/api/me/artworks/${id}`);
};

export let addArtwork = (data) => {
  return axios.post('/api/me/artworks', data);
};

export let updateArtwork = (data) => {
  return axios.put(`/api/me/artworks/${data.id}`, data);
};

import React, { useEffect, useRef, useState } from 'react';
import { useTransform, motion } from 'framer-motion';
import cx from 'classnames';

// Hooks
import { Heading } from '../../hooks/DocumentOutline';
import { useScrollContext } from '../../hooks/Scroll';
import { useRect } from '../../hooks/useRect';

// Assets
import './IndexLayout.css';

function getComputedTranslateY(el) {
  if (!window.getComputedStyle) return;
  var style = getComputedStyle(el),
    transform = style.transform || style.webkitTransform || style.mozTransform;
  var mat = transform.match(/^matrix3d\((.+)\)$/);
  if (mat) return parseFloat(mat[1].split(', ')[13]);
  mat = transform.match(/^matrix\((.+)\)$/);
  return mat ? parseFloat(mat[1].split(', ')[5]) : 0;
}

export let IndexLayout = React.memo(({ title, children, actions }) => {
  /**
   * Gradually scale down the title, based on scroll position
   */
  let { rect: headerRect, setRef: headerRef } = useRect();
  let { motionY, scrollbarWidth } = useScrollContext();
  let minScale = 0.2;
  let tweenDomain = [0, headerRect.height];
  let scale = useTransform(motionY, tweenDomain, [1, minScale]);

  let translateYMax = 12;
  let [translateYMaxOffset, setTranslateYMaxOffset] = useState(0);
  translateYMax -= translateYMaxOffset;

  let translateY = useTransform(motionY, tweenDomain, [0, translateYMax]);
  let areaScale = useTransform(motionY, tweenDomain, [1.25, 1]);
  let useAreaTitle = scale.get() <= minScale;

  let headingRef = useRef(null);

  useEffect(() => {
    if (headingRef.current) {
      // Account for any translation
      setTranslateYMaxOffset(
        parseInt(getComputedTranslateY(headingRef.current), 10)
      );
    }
  }, [headingRef]);

  return (
    <div className={cx('IndexLayout', {})}>
      <div className="IndexLayout-header" ref={headerRef}>
        <Heading className="f-heading-01 IndexLayout-title" ref={headingRef}>
          <motion.div
            className="IndexLayout-titleInner"
            animate={{
              opacity: useAreaTitle ? 0 : 1,
              transition: { duration: useAreaTitle ? 0.5 : 0.01 },
            }}
            style={{
              scale,
              y: translateY,
            }}
          >
            {title}
          </motion.div>
        </Heading>
        <div
          className="IndexLayout-headerBar"
          style={{
            right: scrollbarWidth || 0,
          }}
        >
          <motion.div
            className="IndexLayout-headerBarTitle f-subhead-01"
            aria-hidden
            initial={{ opacity: 0 }}
            animate={{
              opacity: useAreaTitle ? 1 : 0,
              transition: { duration: useAreaTitle ? 0.5 : 0.01 },
            }}
            style={{
              scale: areaScale,
            }}
          >
            {title}
          </motion.div>
          <div className="IndexLayout-actions">{actions}</div>
        </div>
      </div>
      <div className="IndexLayout-main">{children}</div>
    </div>
  );
});

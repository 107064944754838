import React, {
  useCallback,
  useContext,
  useEffect,
  // useMemo,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';
import { FocusOn } from 'react-focus-on';
import { useHistory } from 'react-router-dom';
import { usePrevious } from 'react-use';

// Assets
import './DetailPanel.css';

// Components
import Button from '../Button';

// Hooks
import { useErrors } from '../../hooks/Error';
import { useBackgroundState } from '../../hooks/BackgroundState';

let DetailPanelContext = React.createContext(null);

let variants = {
  exit: { opacity: 0 },
  enter: { opacity: 1 },
};

let innerVariants = {
  exit: { x: 15 },
  enter: { x: 0 },
};

export let useDetailPanel = () => {
  let context = useContext(DetailPanelContext);
  return context;
};

let spring = {
  type: 'spring',
  damping: 500,
  stiffness: 1000,
  delay: 0.1,
};

export let DetailPanelPortal = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('detailPanelPortal')
  );
};

export let DetailPanel = React.forwardRef(
  (
    { children, style = {}, isPermalink = true, onClose = null, ...props },
    ref
  ) => {
    let history = useHistory();
    let { rootLocation } = useBackgroundState();
    let [isRequestingClose, setRequestingClose] = useState(false);
    let [isClosePrevented, setClosePrevented] = useState(false);
    let { errors } = useErrors();
    let isSystemErrorShown = Boolean(errors.length);

    let closePanel = useCallback(
      (evt) => {
        if (isPermalink) {
          history.push(rootLocation);
        } else if (onClose) {
          onClose();
        }
      },
      [history, rootLocation, isPermalink, onClose]
    );

    let requestClose = useCallback(() => {
      setRequestingClose(true);
    }, []);

    /**
     * Tell the children that this component wants to close
     * ... and give them 100 milliseconds to prevent it doing so
     */

    let listenForClose = isRequestingClose && !isClosePrevented;
    let prevListenForClose = usePrevious(listenForClose);
    useEffect(() => {
      let timeout;
      if (listenForClose && !prevListenForClose) {
        timeout = setTimeout(() => {
          closePanel();
        }, 100);
      }
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }, [listenForClose, prevListenForClose, closePanel]);

    let preventClose = useCallback(() => {
      setClosePrevented(true);
    }, []);

    let cancelRequestClose = useCallback(() => {
      setRequestingClose(false);
    }, []);

    return (
      <DetailPanelContext.Provider
        value={{
          closePanel,
          isRequestingClose,
          preventClose,
          cancelRequestClose,
        }}
      >
        <motion.div
          className="DetailPanel"
          style={style}
          initial="exit"
          animate="enter"
          exit="exit"
          variants={variants}
          ref={ref}
          transition={spring}
        >
          <FocusOn
            enabled={!isSystemErrorShown}
            onClickOutside={requestClose}
            onEscapeKey={requestClose}
            autoFocus={false}
          >
            <motion.div
              className="DetailPanel-content light-mode"
              variants={innerVariants}
              transition={spring}
            >
              {children}
            </motion.div>
            <div className="DetailPanel-close">
              <Button
                className="DetailPanel-close"
                onClick={requestClose}
                icon="close"
              />
            </div>
          </FocusOn>
        </motion.div>
      </DetailPanelContext.Provider>
    );
  }
);

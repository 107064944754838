import { useCallback, useRef, useState } from 'react';
import { shallowEqualObjects } from 'shallow-equal';
import { ResizeObserver } from '@juggle/resize-observer';

let emptyRect = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: 0,
  height: 0,
  x: 0,
  y: 0,
};

function getBoundingClientRect(element) {
  var rect = element.getBoundingClientRect();
  return {
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    width: rect.width,
    height: rect.height,
    x: rect.x,
    y: rect.y,
  };
}

export let useRect = ({ listenToWindow = false } = {}) => {
  let [rect, setRect] = useState(emptyRect);
  let ref = useRef(null);
  let observerRef = useRef(null);

  let handleResize = useCallback(
    (node) => {
      let newRect = getRect(ref.current);
      if (!shallowEqualObjects(rect, newRect)) {
        setRect(newRect);
      }
    },
    [rect]
  );

  let setRef = useCallback(
    (node) => {
      if (ref.current) {
        if (observerRef.current) {
          observerRef.current.disconnect();
          observerRef.current = null;
          window.removeEventListener('resize', handleResize);
        }
      }
      if (node) {
        if (typeof ResizeObserver === 'function') {
          let resizeObserver = new ResizeObserver((entries) => {
            // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
            window.requestAnimationFrame(() => {
              if (!Array.isArray(entries) || !entries.length) {
                return;
              }
              handleResize(node);
            });
          });
          resizeObserver.observe(node);
          observerRef.current = resizeObserver;
          if (listenToWindow) {
            window.addEventListener('resize', handleResize);
          }
        }
      }
      ref.current = node;
    },
    [handleResize, listenToWindow]
  );

  return {
    rect,
    setRef,
  };
};

function getRect(element) {
  if (!element) {
    return emptyRect;
  }

  return getBoundingClientRect(element);
}

import React from 'react';

export default function Search24() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
    >
      <path
        d="M10.5 15.5C13.2614 15.5 15.5 13.2614 15.5 10.5C15.5 7.73858 13.2614 5.5 10.5 5.5C7.73858 5.5 5.5 7.73858 5.5 10.5C5.5 13.2614 7.73858 15.5 10.5 15.5Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M18.4736 18.2663L14.231 14.0237L13.5239 14.7308L17.7665 18.9734L18.4736 18.2663Z"
        fill="black"
      />
    </svg>
  );
}

import React from 'react';

export default function CaretUp24() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 14L12 10L8 14" stroke="currentColor" />
    </svg>
  );
}

function getBoundingBox(artworks) {
  let top, right, bottom, left;
  for (let i = 0; i < artworks.length; i++) {
    let artwork = artworks[i];
    if (top == null || artwork.top < top) {
      top = parseFloat(artwork.top);
    }
    if (left == null || artwork.left < left) {
      left = parseFloat(artwork.left);
    }
    if (right == null || artwork.right > right) {
      right = parseFloat(artwork.right);
    }
    if (bottom == null || artwork.bottom > bottom) {
      bottom = parseFloat(artwork.bottom);
    }
  }
  return {
    top,
    right,
    bottom,
    left,
  };
}

export default getBoundingBox;

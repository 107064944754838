import React from 'react';

import getBoundingBox from '../utils/getBoundingBox';

export let useArtworkPlacementRects = (artworkPlacements) => {
  let artworkPlacementRects = React.useMemo(
    () =>
      artworkPlacements.map((obj) => {
        let { artwork } = obj;

        let ratio =
          artwork.featured_image.height_px / artwork.featured_image.width_px;

        let width = artwork.width_inches;
        let height = width * ratio;

        return {
          top: obj.y_inches,
          right: obj.x_inches + width,
          bottom: obj.y_inches + height,
          left: obj.x_inches,
        };
      }),
    [artworkPlacements]
  );

  let boundingBox = React.useMemo(() => getBoundingBox(artworkPlacementRects), [
    artworkPlacementRects,
  ]);

  return {
    artworkPlacements,
    artworkPlacementRects,
    boundingBox,
  };
};

import { useMemo } from 'react';

// Utils
import { getRoomCrop } from '../utils/getRoomCrop';
import { contains, overlaps } from '../utils/intersects';

export let useValidRoomSizes = ({
  roomSizes,
  currentSize,
  artworkPlacementRects,
}) => {
  let roomSizeValidations = useMemo(() => {
    return roomSizes.map((roomSize) => {
      let isBlocking = false;
      let isWarning = false;

      // Create a 'rect' of the room, ready for the
      // intersection functions
      let roomRect = {
        top: 0,
        right: roomSize.widthInches,
        bottom: roomSize.heightInches,
        left: 0,
      };

      let roomCrop = getRoomCrop(roomSize, currentSize);

      for (let rect of artworkPlacementRects) {
        // Apply the crop to the artwork placement rects,
        // to get an accurate position ahead-of-time
        if (roomCrop) {
          rect = {
            top: rect.top + roomCrop.top,
            right: rect.right + roomCrop.left,
            bottom: rect.bottom + roomCrop.top,
            left: rect.left + roomCrop.left,
          };
        }

        if (!overlaps(roomRect, rect)) {
          isBlocking = true;
          break;
        } else {
          if (!contains(roomRect, rect)) {
            isWarning = true;
          }
        }
      }

      return {
        roomSize,
        isBlocking,
        isWarning,
      };
    });
  }, [roomSizes, artworkPlacementRects, currentSize]);

  return {
    roomSizeValidations,
  };
};

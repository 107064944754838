import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import axios from 'axios';
import { useInfiniteQuery } from 'react-query';
import cx from 'classnames';
import { useDebounce, usePrevious } from 'react-use';
import qs from 'qs';
import { useInView } from 'react-intersection-observer';
import OutsideClickHandler from 'react-outside-click-handler';

// Assets
import './ArtworkPicker.css';

// Components
import icons from '../_icons';
import LazyImage from '../LazyImage';
import { DetailPanel, DetailPanelPortal } from '../DetailPanel/DetailPanel';
import ArtworkForm from '../ArtworkForm';
import SearchField from '../_forms/SearchField';

// Hooks
import { useErrors } from '../../hooks/Error';

let transition = {
  type: 'spring',
  damping: 1000,
  stiffness: 500,
};

let ArtworkPicker = React.memo(
  ({
    isOpen,
    onDismiss,
    canClose,
    onOpen,
    handleArtworkSelect,
    shouldRenderArtwork,
  }) => {
    let [artworkDialogIsOpen, setArtworkDialogOpen] = useState(false);
    let [searchTerm, setSearchTerm] = useState('');
    let [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
    let { setError } = useErrors();

    // Store whether an error was triggered from this component
    // so that we can prevent error messages constantly showing
    let [errored, setErrored] = useState(false);

    /**
     * Artwork data fetching
     */
    const iq = useInfiniteQuery(
      ['me/artworks', { q: debouncedSearchTerm }],
      (key, args, nextPage = 1) =>
        axios.get(
          '/api/me/artworks?' +
            qs.stringify({
              page: nextPage,
              perPage: 20,
              q: args.q,
            })
        ),
      {
        staleTime: 30 * 1000,
        getFetchMore: (lastGroup, allGroups) =>
          lastGroup.data.current_page === lastGroup.data.last_page
            ? null
            : lastGroup.data.current_page + 1,
      }
    );

    let {
      status,
      data: pages,
      error,
      isFetchingMore,
      fetchMore,
      canFetchMore,
    } = iq;

    let artworksErrorMsg = 'Something went wrong trying to find your artworks.';

    useEffect(() => {
      if (error) {
        setErrored(true);
      }
      if (error && !errored && status !== 'loading') {
        setError({
          message: artworksErrorMsg,
          error,
        });
      }
    }, [error, errored, status, setError, artworksErrorMsg]);

    let scrollRef = useRef(null);

    let [loadBtnRef, loadBtnInView] = useInView({
      root: scrollRef.current,
      threshold: 0,
    });

    let loadBtnIsDisabled = !canFetchMore || isFetchingMore;

    useEffect(() => {
      if (
        !loadBtnIsDisabled &&
        isOpen &&
        loadBtnInView &&
        !isFetchingMore &&
        canFetchMore
      ) {
        fetchMore();
      }
    }, [
      loadBtnIsDisabled,
      isOpen,
      loadBtnInView,
      canFetchMore,
      fetchMore,
      isFetchingMore,
    ]);

    let isLoading = status === 'loading';
    let wasLoading = usePrevious(isLoading);
    let didLoad = !isLoading && wasLoading;
    let disableAnimations = didLoad;

    useDebounce(
      () => {
        setDebouncedSearchTerm(searchTerm);
      },
      500,
      [searchTerm]
    );

    let handleSearchFieldInputFocus = useCallback(() => {
      onOpen();
    }, [onOpen]);

    let handleSearchFieldInputChange = useCallback((evt) => {
      setSearchTerm(evt.currentTarget.value);
    }, []);

    let handleClose = useCallback(() => {
      if (artworkDialogIsOpen || !canClose) {
        return;
      }
      onDismiss();
    }, [artworkDialogIsOpen, canClose, onDismiss]);

    return (
      <OutsideClickHandler
        disabled={!isOpen || artworkDialogIsOpen}
        onOutsideClick={handleClose}
      >
        <div
          className={cx('ArtworkPicker', {
            'is-open': isOpen,
          })}
        >
          <div className="ArtworkPicker-header">
            <div className="ArtworkPicker-searchField">
              <SearchField
                onChange={handleSearchFieldInputChange}
                value={searchTerm}
                inputProps={{
                  onFocus: handleSearchFieldInputFocus,
                }}
              />
            </div>

            <button
              className="squareBtn ArtworkPicker-toggleBtn"
              onClick={isOpen ? onDismiss : onOpen}
            >
              <icons.toggle isOpen={isOpen} />
              <div className="tooltip tooltip--top f-caption-01">Add</div>
            </button>
          </div>

          <div className="ArtworkPicker-main">
            <button
              onClick={() => {
                setArtworkDialogOpen(true);
              }}
              className="ArtworkPicker-add"
            >
              <span className="ArtworkPicker-addInner">
                <div className="squareBtn">
                  <icons.add />
                </div>
              </span>
            </button>
            <div className="ArtworkPicker-scroll" ref={scrollRef}>
              <div className="ArtworkPicker-inner">
                <DetailPanelPortal>
                  <AnimatePresence exitBeforeEnter>
                    {artworkDialogIsOpen && (
                      <DetailPanel
                        isPermalink={false}
                        onClose={() => {
                          setArtworkDialogOpen(false);
                        }}
                      >
                        <ArtworkForm id={null} />
                      </DetailPanel>
                    )}
                  </AnimatePresence>
                </DetailPanelPortal>

                {pages[0]?.data?.data?.length ? (
                  pages.map(({ data: { data: rows } }, i) =>
                    rows.map((artwork) => {
                      let url = artwork.featured_image?.square_thumb?.url;

                      if (!url) {
                        return null;
                      }

                      return (
                        <AnimatePresence key={artwork.id}>
                          <motion.div
                            id={artwork.id}
                            className={cx('ArtworkPicker-artwork', {
                              'is-hidden': !shouldRenderArtwork(artwork),
                            })}
                            layoutTransition={transition}
                            initial={
                              disableAnimations
                                ? false
                                : {
                                    opacity: 0,
                                  }
                            }
                            animate={{
                              opacity: 1,
                              transition,
                            }}
                            whileHover={{
                              y: -10,
                            }}
                            exit={{
                              opacity: 0,
                              transition,
                            }}
                          >
                            <LazyImage
                              className="grabbable"
                              alt={artwork.title}
                              src={url}
                              width={96}
                              height={96}
                              onMouseDown={(evt) => {
                                evt.preventDefault();
                                handleArtworkSelect({ artwork, evt });
                              }}
                              preload={
                                artworkDialogIsOpen &&
                                artwork.featured_image.canvas.url
                              }
                            />
                          </motion.div>
                        </AnimatePresence>
                      );
                    })
                  )
                ) : isLoading && !errored ? (
                  <div className="ArtworkPicker-zero">
                    <icons.loading />
                  </div>
                ) : errored ? (
                  <div className="ArtworkPicker-zero">
                    {artworksErrorMsg}{' '}
                    <button
                      className="link"
                      onClick={() => window.location.reload()}
                    >
                      Reload
                    </button>
                  </div>
                ) : (
                  <div className="ArtworkPicker-zero">
                    {debouncedSearchTerm
                      ? 'No results'
                      : 'You have not uploaded any artworks yet. Select the ‘Add’ button to the left to get started.'}
                  </div>
                )}

                {Boolean(pages.length) && canFetchMore && (
                  <div
                    role="cell"
                    className="ArtworkPicker-loadingBox"
                    ref={loadBtnRef}
                  >
                    <icons.loading />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
);

export default ArtworkPicker;

import { useEffect } from 'react';

export let usePreloads = (urls) => {
  useEffect(() => {
    let links = new Map();
    for (let url of urls) {
      let link = document.createElement('link');
      link.href = url;
      link.rel = 'preload';
      link.as = 'image';
      document.head.appendChild(link);
      links.set(url, link);
    }
    return () => {
      for (let url of urls) {
        let link = links.get(url);
        document.head.removeChild(link);
      }
    };
  }, [urls]);
};

export let usePreload = (url) => {
  useEffect(() => {
    let preloadLink = document.createElement('link');
    preloadLink.href = url;
    preloadLink.rel = 'preload';
    preloadLink.as = 'image';
    document.head.appendChild(preloadLink);

    return () => {
      document.head.removeChild(preloadLink);
    };
  }, [url]);
};

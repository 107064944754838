import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useInfiniteQuery, queryCache } from 'react-query';
import axios from 'axios';
import qs from 'qs';
import { Link, useHistory } from 'react-router-dom';
import cx from 'classnames';

// Assets
import './RoomsIndexPreviewable.css';

// Components
import RoomPreview from '../RoomPreview';
import IndexLayout from '../IndexLayout';
import Button from '../Button';

// Hooks
import { useErrors } from '../../hooks/Error';
import { useRoomPreviewTransition } from '../../hooks/RoomPreviewTransition';

function RoomsIndexPreviewableListItem({
  room,
  isCurrent,
  onMouseOver,
  onMouseOut,
  onClick,
}) {
  // let [clicked, setClicked] = useState(false);

  let handleMouseOver = useCallback(
    (evt) => {
      evt.stopPropagation();
      onMouseOver(room, evt);
    },
    [onMouseOver, room]
  );

  let handleMouseOut = useCallback(
    (evt) => {
      evt.stopPropagation();
      onMouseOut(room, evt);
    },
    [onMouseOut, room]
  );

  let handleClick = useCallback(
    (evt) => {
      evt.preventDefault();
      onClick(room);
    },
    [room, onClick]
  );

  return (
    <div
      className={cx('RoomsIndexPreviewable-listItem f-heading-01', {
        'is-current': isCurrent,
      })}
    >
      <a
        href={`/rooms/${room.id}`}
        onClick={handleClick}
        className="RoomsIndexPreviewable-listItemLink"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {room.title}
      </a>
    </div>
  );
}

export default function RoomsIndexPreviewable() {
  let { setError } = useErrors();
  let history = useHistory();
  let {
    startTransition,
    isIntending,
    setIntending,
  } = useRoomPreviewTransition();

  // Uses the same query key as RoomsIndex, to enable caching.
  let { data: pages, error } = useInfiniteQuery(
    ['me/rooms'],
    (key, nextPage = 1) =>
      axios.get(
        '/api/me/rooms?' +
          qs.stringify({
            page: nextPage,
            perPage: 20,
          })
      ),
    {
      getFetchMore: () => null,
    }
  );

  // Just show the first 20 rooms for the preview.
  // User will have to click 'See all' to view more, or manage their rooms.
  let rooms = pages && pages[0]?.data.data;

  // Prevents duplicated system errors
  let [hadError, setHadError] = useState(false);
  useEffect(() => {
    if (error && !hadError) {
      setHadError(true);
      setError({
        message: 'Your rooms could not be fetched. Please try again later.',
      });
    }
  }, [error, hadError, setError]);

  // Hold the hovered room in state
  let [currentRoom, setCurrentRoom] = useState(null);
  let [isClicked, setClicked] = useState(false);
  // let [isOnList, setOnList] = useState(false);

  let onListMouseOver = useCallback(() => {
    // setOnList(true);
  }, []);

  let onListMouseOut = useCallback(() => {
    if (!isClicked) {
      setCurrentRoom(null);
    }
  }, [isClicked]);

  let onListItemMouseOver = useCallback((room) => {
    setCurrentRoom(room);
  }, []);

  let onListItemMouseOut = useCallback(() => {
    if (!isClicked) {
      setCurrentRoom(null);
    }
  }, [isClicked]);

  let onListItemClick = useCallback(() => {
    setClicked(true);
    startTransition(true);
    queryCache
      .prefetchQuery(
        ['me/rooms/:id', { id: currentRoom.id.toString() }],
        (key, vars) =>
          axios.get('/api/me/rooms/' + vars.id).catch((err) => null)
      )
      .then(() => {
        history.push(`/rooms/${currentRoom.id}`, {
          disableRouteAnimation: true,
        });
      });
  }, [history, currentRoom, startTransition]);

  let [seeAllActive, setSeeAllActive] = useState(false);

  let onSeeAllMouseOver = useCallback(() => {
    setSeeAllActive(true);
  }, []);

  let onSeeAllMouseOut = useCallback(() => {
    setSeeAllActive(false);
  }, []);

  useLayoutEffect(() => {
    if (currentRoom && !isIntending) {
      setIntending(true);
    } else if (!currentRoom && isIntending) {
      setIntending(false);
    }
  }, [currentRoom, setIntending, isIntending]);

  return (
    <div
      className={cx('RoomsIndexPreviewable', {
        'has-current-room': Boolean(currentRoom),
        'is-see-all-active': seeAllActive,
        'is-loading-room': isClicked,
        'is-intending': isIntending,
      })}
    >
      <div className="RoomsIndexPreviewable-room">
        <RoomPreview
          room={currentRoom}
          placements={currentRoom?.artwork_placements}
          fade={!isClicked}
        />
      </div>

      <div className="RoomsIndexPreviewable-main">
        <IndexLayout
          title="Rooms"
          actions={
            <Button
              label="Create room"
              element={Link}
              elProps={{ to: '/rooms/new' }}
              icon="add"
            />
          }
        >
          <ul
            className="RoomsIndexPreviewable-list"
            onMouseEnter={onListMouseOver}
            onMouseLeave={onListMouseOut}
          >
            {rooms && (
              <>
                {rooms?.map((room, i) => (
                  <li
                    key={room.id}
                    style={{
                      position: 'relative',
                      zIndex: rooms.length - i,
                    }}
                  >
                    <RoomsIndexPreviewableListItem
                      room={room}
                      isCurrent={room === currentRoom}
                      onClick={onListItemClick}
                      onMouseOver={onListItemMouseOver}
                      onMouseOut={onListItemMouseOut}
                    />
                  </li>
                ))}
                <li
                  className={cx(
                    'RoomsIndexPreviewable-listItem f-heading-01 RoomsIndexPreviewable-listItemSeeAll',
                    {
                      'is-current': seeAllActive,
                    }
                  )}
                >
                  <Link
                    to="/rooms"
                    onMouseOver={onSeeAllMouseOver}
                    onMouseOut={onSeeAllMouseOut}
                  >
                    See all
                  </Link>
                </li>
              </>
            )}
          </ul>
        </IndexLayout>
      </div>
    </div>
  );
}

import React, { useCallback, useContext, useMemo, useState } from 'react';

let RoomCacheBusterContext = React.createContext(null);

export let RoomCacheBusterProvider = (props) => {
  let [shouldBust, setShouldBust] = useState(false);
  let resetBust = useCallback(() => {
    setShouldBust(false);
  }, []);
  let bustRoomCache = useCallback(() => {
    setShouldBust(true);
  }, []);
  let value = useMemo(() => ({ shouldBust, resetBust, bustRoomCache }), [
    shouldBust,
    resetBust,
    bustRoomCache,
  ]);
  return <RoomCacheBusterContext.Provider value={value} {...props} />;
};

export let useRoomCacheBuster = () => {
  let context = useContext(RoomCacheBusterContext);
  return context;
};

import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useLifecycles } from 'react-use';
import { useFormik } from 'formik';
import axios from 'axios';
import cx from 'classnames';

// Assets
import './SettingsScreen.css';

// Components
import { TextField } from '../_forms';
import Portal from '../Portal';
import AcknowledgementDialog from '../AcknowledgementDialog';
import ConfirmDialog from '../ConfirmDialog';
import Button from '../Button';

// Hooks
import { Heading, Section } from '../../hooks/DocumentOutline';
import { useAuth } from '../../hooks/Auth';
import { useTheme, LIGHT_MODE, DARK_MODE } from '../../hooks/Theme';
import { useErrors } from '../../hooks/Error';
import { useToast } from '../../hooks/Toast';

let changePasswordSchema = Yup.object().shape({
  current_password: Yup.string().required().label('Current password'),
  new_password: Yup.string().required().label('New password'),
  new_password_confirmation: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Password fields must match')
    .required()
    .label('Password confirmation'),
});

export default function SettingsScreen() {
  let { user, logout } = useAuth();
  let { setToast } = useToast();

  // Theme
  let { setTheme } = useTheme();
  useLifecycles(
    () => {
      setTheme(DARK_MODE);
    },
    () => {
      setTheme(LIGHT_MODE);
    }
  );

  // Dialog state
  let [isEmailDialogVisible, setEmailDialogVisible] = useState(false);
  let [isPasswordDialogVisible, setPasswordDialogVisible] = useState(false);

  let { setError } = useErrors();

  let changePasswordFormik = useFormik({
    validationSchema: changePasswordSchema,
    // enableReinitialize: true,
    initialValues: {
      current_password: '',
      new_password: '',
      new_password_confirmation: '',
    },
    onSubmit: async () => {
      try {
        await axios.post('/password/change', {
          current_password: changePasswordFormik.values.current_password,
          new_password: changePasswordFormik.values.new_password,
          new_password_confirmation:
            changePasswordFormik.values.new_password_confirmation,
        });
      } catch (err) {
        if (err.response) {
          for (let field of Object.keys(err.response.data?.errors)) {
            let errors = err.response.data.errors[field];
            let error = Array.isArray(errors) ? errors[0] : errors;
            changePasswordFormik.setFieldError('general', error);
            break;
          }
        } else {
          setError({
            message: 'Something went wrong when resetting your password.',
          });
        }
        return;
      }
      setPasswordDialogVisible(false);
      changePasswordFormik.setSubmitting(false);
      changePasswordFormik.resetForm();

      setToast({
        message: 'Your password has been changed.',
      });
    },
  });

  let isChangePasswordFormInvalid = Boolean(
    Object.keys(changePasswordFormik.errors).length
  );

  let firstChangePasswordError =
    isChangePasswordFormInvalid && changePasswordFormik.submitCount > 0
      ? Object.values(changePasswordFormik.errors)[0]
      : null;

  let onChangePasswordDialogConfirm = useCallback(
    (evt) => {
      changePasswordFormik.handleSubmit(evt);
    },
    [changePasswordFormik]
  );

  let onChangePasswordDialogCancel = useCallback(() => {
    setPasswordDialogVisible(false);
    changePasswordFormik.resetForm();
  }, [changePasswordFormik]);

  let onLogoutSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      logout();
    },
    [logout]
  );

  return (
    <div className="SettingsScreen">
      <div className="SettingsScreen-leftCol">
        <Heading className="f-heading-01">Settings</Heading>
      </div>
      <Section className="SettingsScreen-rightCol">
        <div className="SettingsScreen-formTitle">
          <Heading className="f-heading-01">
            Basic
            <br />
            information
          </Heading>
        </div>
        <div className="SettingsScreen-form">
          <div className="SettingsScreen-row">
            {user?.data?.email}
            <button
              onClick={() => setEmailDialogVisible(true)}
              className="link f-caption-01 SettingsScreen-rowNote"
            >
              Change e-mail
            </button>
          </div>
          <div className="SettingsScreen-row">
            *********************
            <button
              onClick={() => setPasswordDialogVisible(true)}
              className="link f-caption-01 SettingsScreen-rowNote"
            >
              Change password
            </button>
          </div>
          <form onSubmit={onLogoutSubmit} className="SettingsScreen-signoutBtn">
            <Button
              label="Sign out"
              elProps={{
                type: 'submit',
              }}
            />
          </form>
        </div>
      </Section>
      <Portal id="dialogPortal">
        {isEmailDialogVisible && (
          <AcknowledgementDialog
            title="Change e-mail"
            message="Please contact American Cyborg to change your e-mail address - info@americancyb.org"
            confirmLabel="OK"
            cancellable={false}
            onConfirm={() => {
              setEmailDialogVisible(false);
            }}
            onCancel={() => {
              setEmailDialogVisible(false);
            }}
          />
        )}
        {isPasswordDialogVisible && (
          <ConfirmDialog
            title="Change password"
            message={
              <>
                <TextField
                  type="password"
                  autoFocus
                  value={changePasswordFormik.values.current_password || ''}
                  placeholder="Current password*"
                  onChange={changePasswordFormik.handleChange}
                  name="current_password"
                  autoComplete="current-password"
                  formik={changePasswordFormik}
                  note={
                    changePasswordFormik.values.current_password
                      ? 'Current password*'
                      : undefined
                  }
                />

                <TextField
                  type="password"
                  value={changePasswordFormik.values.new_password || ''}
                  placeholder="New password*"
                  onChange={changePasswordFormik.handleChange}
                  name="new_password"
                  autoComplete="new-password"
                  formik={changePasswordFormik}
                  note={
                    changePasswordFormik.values.new_password
                      ? 'New password*'
                      : undefined
                  }
                />
                <TextField
                  type="password"
                  autoComplete="new-password"
                  value={
                    changePasswordFormik.values.new_password_confirmation || ''
                  }
                  placeholder="New password confirmation*"
                  onChange={changePasswordFormik.handleChange}
                  name="new_password_confirmation"
                  formik={changePasswordFormik}
                  note={
                    changePasswordFormik.values.new_password_confirmation
                      ? 'New password confirmation*'
                      : undefined
                  }
                />

                <div className="PasswordValidation f-caption-01">
                  <p>
                    We recommend you{' '}
                    <a
                      className="link"
                      href="https://xkcd.com/936/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      use a passphrase
                    </a>{' '}
                    as your password. We require they are at least 20 letters or
                    numbers long (no special characters)
                  </p>
                  <p>
                    <strong>Alternatively,</strong> your new password must:
                  </p>
                  <ul>
                    <li>be at least 8 characters in length</li>
                    <li>contain at least one lowercase letter</li>
                    <li>contain at least one uppercase letter</li>
                    <li>contain at least one digit</li>
                    <li>contain at least one special character</li>
                  </ul>
                </div>
              </>
            }
            confirmLabel="OK"
            cancellable={false}
            onConfirm={onChangePasswordDialogConfirm}
            onCancel={onChangePasswordDialogCancel}
            footerNote={
              <div
                className={cx({
                  'is-error': Boolean(
                    changePasswordFormik.errors.general ||
                      firstChangePasswordError
                  ),
                })}
              >
                {changePasswordFormik.errors.general ||
                  firstChangePasswordError ||
                  '*Required'}
              </div>
            }
          />
        )}
      </Portal>
    </div>
  );
}

import React from 'react';

export default function Toggle24() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.5 15V18.5H6.5V15" stroke="white" strokeMiterlimit="10" />
      <path
        d="M8.26023 10.25L12.5002 6.01L16.7402 10.25"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path d="M12.5 15.0022L12.5 6" stroke="white" strokeMiterlimit="10" />
    </svg>
  );
}

import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

let backgroundColors = [
  'blue',
  'brown',
  'lime',
  'orange',
  'red',
  'slate',
  'teal',
  'yellow',
];

let images = {
  blue: require('../images/sign-in-background/image_blue@2x-min.jpg'),
  brown: require('../images/sign-in-background/image_brown@2x-min.jpg'),
  lime: require('../images/sign-in-background/image_lime@2x-min.jpg'),
  orange: require('../images/sign-in-background/image_orange@2x-min.jpg'),
  red: require('../images/sign-in-background/image_red@2x-min.jpg'),
  slate: require('../images/sign-in-background/image_slate@2x-min.jpg'),
  teal: require('../images/sign-in-background/image_teal@2x-min.jpg'),
  yellow: require('../images/sign-in-background/image_yellow@2x-min.jpg'),
};

let getRandomColor = () => {
  return backgroundColors[Math.floor(Math.random() * backgroundColors.length)];
};

export let useRandomColor = () => {
  let [lastRandomColor, setLastRandomColor] = useLocalStorage(
    'last_random_color',
    null
  );

  let getNewRandomColor = useCallback(() => {
    let rc = getRandomColor();
    if (rc === lastRandomColor) {
      return getNewRandomColor();
    }
    return rc;
  }, [lastRandomColor]);

  let [randomBackgroundColor, setRandomBackgroundColor] = useState(
    getNewRandomColor()
  );

  useEffect(() => {
    setLastRandomColor(randomBackgroundColor);
  }, [randomBackgroundColor, setLastRandomColor]);

  let setColor = useCallback(() => {
    setRandomBackgroundColor(getNewRandomColor());
  }, [getNewRandomColor]);

  console.log('color', randomBackgroundColor);

  return {
    color: randomBackgroundColor,
    image: images[randomBackgroundColor],
    setColor,
  };
};

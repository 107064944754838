import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import { motion } from 'framer-motion';
import { FocusOn } from 'react-focus-on';

// Assets
import './AcknowledgementDialog.css';

let variants = {
  exit: { opacity: 0, duration: 0.1 },
  enter: { opacity: 1, duration: 0.3 },
};

let innerVariants = {
  exit: { y: 10, duration: 0.1 },
  enter: { y: 0, duration: 0.3 },
};

export let AcknowledgementDialog = ({
  title,
  message,
  confirmLabel = 'Add artwork',
  onConfirm,
  onCancel,
}) => {
  let onDismiss = useCallback(() => {
    onCancel();
  }, [onCancel]);

  let onSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      onConfirm();
    },
    [onConfirm]
  );

  return (
    <motion.div
      className="AcknowledgementDialog light-mode"
      initial="exit"
      animate="enter"
      exit="exit"
      variants={variants}
    >
      <motion.div
        variants={innerVariants}
        className="AcknowledgementDialog-box"
        aria-label={title}
      >
        <FocusOn onClickOutside={onDismiss} onEscapeKey={onDismiss}>
          <form onSubmit={onSubmit}>
            <div className="AcknowledgementDialog-header">
              <p className="AcknowledgementDialog-title f-subhead-01">
                {title}
              </p>
              <Button
                className="AcknowledgementDialog-closeBtn"
                icon="close"
                secondary
                elProps={{ type: 'button' }}
                onClick={onDismiss}
              />
            </div>
            <div className="AcknowledgementDialog-inner">
              {message && (
                <p className="AcknowledgementDialog-msg">{message}</p>
              )}
            </div>
            <div className="AcknowledgementDialog-actions">
              <div className="AcknowledgementDialog-actionsInner">
                <Button
                  label={confirmLabel}
                  elProps={{
                    autoFocus: true,
                    type: 'submit',
                  }}
                />
              </div>
            </div>
          </form>
        </FocusOn>
      </motion.div>
    </motion.div>
  );
};

AcknowledgementDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  confirmLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

AcknowledgementDialog.defaultProps = {};

import { useCallback, useEffect, useState } from 'react';

// Hooks
import { useRect } from '../../../hooks/useRect';

export let useDraggableArtwork = ({ onValidDrop }) => {
  let [isDragging, setDragging] = useState(false);
  let [draggedArtwork, setDraggedArtwork] = useState(null);
  let { rect: wallRect, setRef: dropRef } = useRect();

  let [{ docX, docY }, setMouse] = useState({ docX: 0, docY: 0 });

  let onDrag = useCallback((evt) => {
    setMouse({
      docX: evt.pageX,
      docY: evt.pageY,
    });
  }, []);

  let dragRect = draggedArtwork
    ? {
        left: docX - draggedArtwork.widthPx / 2,
        top: docY - draggedArtwork.heightPx / 2,
        bottom: docY - draggedArtwork.heightPx / 2 + draggedArtwork.heightPx,
        right: docX - draggedArtwork.widthPx / 2 + draggedArtwork.widthPx,
      }
    : null;

  let isDraggedArtworkDroppable = draggedArtwork
    ? !(
        dragRect.right - draggedArtwork.widthPx / 2 < wallRect.left ||
        dragRect.left + draggedArtwork.widthPx / 2 > wallRect.right ||
        dragRect.bottom - draggedArtwork.heightPx / 2 < wallRect.top ||
        dragRect.top + draggedArtwork.heightPx / 2 > wallRect.bottom
      )
    : false;

  let onDrop = useCallback(() => {
    setDragging(false);
    window.removeEventListener('mousemove', onDrag);
    window.removeEventListener('mouseup', onDrop);
  }, [onDrag]);

  /**
   * If the artwork is no longer being dragged,
   * and we have a pending 'draggedArtwork', handle the validity of the drop
   */
  useEffect(() => {
    if (!isDragging && draggedArtwork) {
      if (isDraggedArtworkDroppable) {
        let relativePx = {
          top: dragRect.top - wallRect.top,
          left: dragRect.left - wallRect.left,
        };
        console.log('drop: artwork', draggedArtwork.artwork);
        onValidDrop({
          artwork: draggedArtwork.artwork,
          relativePx,
        });
      }
      setDraggedArtwork(null);
    }
  }, [
    dragRect,
    draggedArtwork,
    isDragging,
    isDraggedArtworkDroppable,
    wallRect,
    onValidDrop,
  ]);

  let startDraggedArtwork = useCallback(
    ({ widthPx, heightPx, artwork, evt }) => {
      setMouse({
        docX: evt.pageX,
        docY: evt.pageY,
      });
      setDraggedArtwork({
        widthPx,
        heightPx,
        artwork,
      });
      setDragging(true);
      window.addEventListener('mousemove', onDrag);
      window.addEventListener('mouseup', onDrop);
    },
    [onDrag, onDrop]
  );

  return {
    draggedArtwork,
    isDraggedArtworkDroppable,
    startDraggedArtwork,
    dragRect,
    dropRef,
  };
};

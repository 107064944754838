export let overlaps = (a, b) => {
  // no horizontal overlap
  if (a.left >= b.right || b.left >= a.right) return false;

  // no vertical overlap
  if (a.top >= b.bottom || b.top >= a.bottom) return false;

  return true;
};

export let contains = (a, b) => {
  return !(
    b.left < a.left ||
    b.top < a.top ||
    b.right > a.right ||
    b.bottom > a.bottom
  );
};

import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

let BackgroundStateContext = React.createContext();

export let BackgroundStateProvider = ({ isBackground = false, ...props }) => {
  let value = {
    isBackground,
  };
  return <BackgroundStateContext.Provider value={value} {...props} />;
};

export let useBackgroundState = ({ useState = false } = {}) => {
  let location = useLocation();

  let background;

  if (useState) {
    background = location.state?.background || null;
  } else {
    let queryParams = new URLSearchParams(location.search);
    let from = queryParams.get('from');
    let urlObj = new URL(from, window.location);
    background = from
      ? {
          pathname: urlObj.pathname,
          search: urlObj.search,
        }
      : null;
  }

  let rootLocation = background || location;

  let getUrlWithBackground = useCallback(
    (str) => {
      if (useState) {
        return {
          pathname: str,
          state: {
            background: rootLocation,
          },
        };
      } else {
        let url = new URL(str, window.location);
        let params = new URLSearchParams(url.search);
        params.set('from', rootLocation.pathname + rootLocation.search);
        return `${url.pathname}?${params}`;
      }
    },
    [rootLocation, useState]
  );

  return {
    hasBackground: Boolean(background),
    getUrlWithBackground,
    rootLocation,
  };
};

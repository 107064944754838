import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';

// Assets
import './Slider.css';

// Hooks
import { useRect } from '../../../hooks/useRect';
import { useMouse } from '../../../hooks/Mouse';

// Utils
import closest from '../../../utils/closest';

export let Slider = ({
  value,
  values,
  onChange,
  tooltipLabel = null,
  tooltipPosition = 'bottom',
}) => {
  let [mouseDown, setMouseDown] = useState(false);

  let { client } = useMouse();
  let { rect: trackRect, setRef: setTrackRef } = useRect({
    listenToWindow: true,
  });
  let width = trackRect?.width || 100;

  /**
   * Calculate the position of the mouse
   * (normalized to the bounds of the track element)
   */
  let normalizedClientPx = 0;
  if (trackRect?.width) {
    normalizedClientPx = client.x - trackRect.x;
  }
  normalizedClientPx = Math.max(0, normalizedClientPx);
  normalizedClientPx = Math.min(trackRect.width, normalizedClientPx);

  let notchGapPx = width / (values.length - 1);

  /**
   * Get lookups for value <> pixel position.
   * (memoized)
   */
  let { pxToValue, valueToPx, valuesAsPx } = useMemo(() => {
    let pxToValue = new Map();
    let valueToPx = new Map();
    let valuesAsPx = [];
    let px = 0;
    for (let val of values) {
      pxToValue.set(px, val);
      valueToPx.set(val, px);
      valuesAsPx.push(px);
      px += notchGapPx;
    }
    return {
      pxToValue,
      valueToPx,
      valuesAsPx,
    };
  }, [values, notchGapPx]);

  let valuePx = valueToPx.get(value);

  let onPointerDown = useCallback((evt) => {
    evt.currentTarget.setPointerCapture(evt.pointerId);
    setMouseDown(true);
  }, []);

  let onPointerCancel = useCallback((evt) => {
    evt.currentTarget.releasePointerCapture(evt.pointerId);
    setMouseDown(false);
  }, []);

  let onWindowMouseUp = useCallback(() => {
    setMouseDown(false);
  }, []);

  useEffect(() => {
    if (mouseDown) {
      window.addEventListener('mouseup', onWindowMouseUp);
    }
    return () => {
      window.removeEventListener('mouseup', onWindowMouseUp);
    };
  }, [mouseDown, onWindowMouseUp]);

  useEffect(() => {
    if (mouseDown) {
      let closestPx = closest(valuesAsPx, normalizedClientPx);
      let val = pxToValue.get(closestPx);
      if (val !== value) {
        onChange(val);
      }
    }
  }, [
    mouseDown,
    normalizedClientPx,
    pxToValue,
    values,
    value,
    valuesAsPx,
    onChange,
  ]);

  return (
    <div className="Slider">
      <div className="Slider-track" ref={setTrackRef}></div>
      <motion.div
        onPointerDown={onPointerDown}
        onPointerCancel={onPointerCancel}
        className="Slider-handleArea"
        animate={{
          left: valuePx,
        }}
        transition={{
          type: 'spring',
          stiffness: 1000,
          damping: 1000,
        }}
      >
        <div className="Slider-handle"></div>
        <div className={`num f-caption-01 tooltip tooltip--${tooltipPosition}`}>
          {typeof tooltipLabel === 'function' ? tooltipLabel(value) : value}
        </div>
      </motion.div>
    </div>
  );
};

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import pluralize from 'pluralize';
import { useFormik } from 'formik';
import { useContain } from '../../hooks/useContain';

// Components
import LazyImage from '../LazyImage';
import RoomWall from '../RoomWall';
import ScaleSlider from '../ScaleSlider';
import ArtworkPicker from '../ArtworkPicker';
import icons from '../_icons';
import Button from '../Button';

// Hooks
import { useRoomResizer } from './hooks/useRoomResizer';
import { useRoomData, STATUS_SUCCESSFUL } from './hooks/useRoomData';
import { useRoomScale, RoomScaleProvider } from './hooks/useRoomScale';
import { useDraggableArtwork } from './hooks/useDraggableArtwork';
import { useArtworkPlacementRects } from './hooks/useArtworkPlacementRects';
import { useValidRoomSizes } from './hooks/useValidRoomSizes';
import { useToast } from '../../hooks/Toast';

// Utils
import { formatRoomSize } from '../../utils/formatRoomSize';

// Assets
import './RoomComposer.css';

// Constants
import { PERSPECTIVE_DEGREES } from '../../config/room';

let draggableTransition = {
  type: 'spring',
  stiffness: 1000,
  damping: 50,
};

export default function RoomComposer() {
  // Router
  let { id } = useParams();
  let history = useHistory();

  let { setToast } = useToast();

  let {
    room,
    status,
    placements,
    createRoom,
    editRoom,
    createArtworkPlacement,
    editArtworkPlacement,
    removePlacement,
    repositionArtworkPlacements,
    localReposition,
  } = useRoomData({
    id,
  });

  let { artworkPlacementRects, boundingBox } = useArtworkPlacementRects(
    placements
  );

  let {
    currentSize,
    currentSizeIndex,
    roomSizes,
    setRoomSize,
    isInBounds,
  } = useRoomResizer({
    placements,
    initialWidth: room?.width_inches || 180,
    boundingBox,
    onResize: useCallback(
      (data) => {
        localReposition(data);
      },
      [localReposition]
    ),
    onFit: useCallback(
      (data) => {
        repositionArtworkPlacements(data);
      },
      [repositionArtworkPlacements]
    ),
  });

  useEffect(() => {
    console.log('RoomComposer > mount', id);
    return () => {
      console.log('RoomComposer > unmount', id);
    };
  }, [id]);

  /**
   * Get the currently valid room sizes, ready for the resizer ui
   */
  let { roomSizeValidations } = useValidRoomSizes({
    roomSizes,
    currentSize,
    artworkPlacementRects,
  });

  /**
   * Constrain the pixel size of the wall
   * by the physical room size's aspect ratio
   */
  let aspectRatio = currentSize.widthInches / currentSize.heightInches;
  let { setRef, dimensions: wallDimensionsPx } = useContain({
    aspectRatio,
  });

  /**
   * Get conversion helper functions
   * based on the current inch and px measurements
   */

  let scaleValues = {
    widthInches: currentSize.widthInches,
    widthPx: wallDimensionsPx?.width,
  };

  let { toInches, toPixels } = useRoomScale(scaleValues);

  /**
   * When a new artwork gets dropped onto the wall,
   * either create as a new room, or just add the placement to the room.
   */
  let onRoomWallDrop = ({ artwork, relativePx }) => {
    // Convert it back to physical measurements on drop...
    let x_inches = toInches(relativePx.left);
    let y_inches = toInches(relativePx.top);

    let placement = {
      artwork_id: artwork.id,
      artwork,
      x_inches,
      y_inches,
    };

    console.log('drop....');
    console.log('id', id);

    if (!id) {
      console.log('createRoom...', currentSize);
      createRoom(
        {
          width_inches: currentSize.widthInches,
          height_inches: currentSize.heightInches,
        },
        placement,
        (res) => {
          history.replace(`/rooms/${res.data.id}`, {
            disableRouteAnimation: true,
          });
        }
      );
    } else {
      createArtworkPlacement({
        ...placement,
        room_id: id,
      });
    }
  };

  let onRoomWallArtworkMoved = (data) => {
    let x_inches = toInches(data.newPx.left);
    let y_inches = toInches(data.newPx.top);
    editArtworkPlacement({
      cid: data.placement.cid,
      x_inches,
      y_inches,
    });
  };

  let onRoomWallArtworkDelete = ({ placement }) => {
    removePlacement({
      placement,
      cb: (res) => {
        setToast({
          message: 'Artwork has been removed from the room.',
        });
      },
    });
  };

  /**
   * Handle the dragging of artwork from the picker
   * to the wall canvas
   */
  let {
    draggedArtwork,
    isDraggedArtworkDroppable,
    startDraggedArtwork,
    dragRect,
    dropRef,
  } = useDraggableArtwork({
    onValidDrop: onRoomWallDrop,
  });

  /**
   * When the user clicks an artwork in the picker,
   * immediately set it as the dragging artwork
   */
  let handleArtworkPickerArtworkSelect = useCallback(
    ({ artwork, evt }) => {
      let widthPx = toPixels(artwork.width_inches);
      let ratio =
        artwork.featured_image.height_px / artwork.featured_image.width_px;
      let heightPx = widthPx * ratio;

      startDraggedArtwork({
        widthPx,
        heightPx,
        artwork,
        evt,
      });
    },
    [startDraggedArtwork, toPixels]
  );

  /**
   * Fade out the room outline
   * when hovering over the scale sliders
   */
  let [fade, setFade] = useState(false);
  let onScaleSliderHoverChange = useCallback((isHovering) => {
    setFade(isHovering);
  }, []);

  let onScaleSliderChange = useCallback(
    (val) => {
      setRoomSize(val.value.roomSize);
    },
    [setRoomSize]
  );

  /**
   * Picker toggle
   */
  let [pickerIsOpen, setPickerOpen] = useState(null);
  let handleArtworkPickerOpen = useCallback(() => {
    setPickerOpen(true);
  }, []);

  /**
   * Renaming the room
   */
  let titleInputRef = useRef();
  let titleForm;
  titleForm = useFormik({
    // If the room object changes, and therefore the memoized object too,
    // the form will be reinitialized
    enableReinitialize: true,
    initialValues: useMemo(
      () => ({
        title: room?.title || '',
      }),
      [room]
    ),
    onSubmit: useCallback(
      ({ title }) => {
        // If the user has removed the title when there is a
        if (!id && !title) {
          titleForm.setFieldValue('title', '');
          return;
        }
        editRoom(
          {
            title,
            // Required for creating a room, but not connected to this form.
            width_inches: currentSize.widthInches,
            height_inches: currentSize.heightInches,
          },
          (res) => {
            if (id) {
              setToast({
                message: 'Your room has been renamed',
              });
            } else {
              setToast({
                message: 'Your room has been created',
              });
              history.replace(`/rooms/${res.data.id}`);
            }
          }
        );

        titleForm.setSubmitting(false);
        titleInputRef.current.blur();
      },
      [id, editRoom, titleForm, setToast, currentSize, history]
    ),
  });

  let isPendingExisting = id && status !== STATUS_SUCCESSFUL && !room;

  console.group();
  console.log('id', id);
  console.log('status', status);
  console.log('room', room);
  console.log('isPendingExisting', isPendingExisting);
  console.groupEnd();

  let shouldRenderArtwork = useCallback(
    (artwork) => {
      // Check if the artwork has already been added to the canvas
      let isPlaced = Boolean(
        placements.find((obj) => obj.artwork.id === artwork.id)
      );

      // Check if the artwork is currently being dragged
      if (isPlaced || draggedArtwork?.artwork.id === artwork.id) {
        return false;
      }

      return true;
    },
    [draggedArtwork, placements]
  );

  let onArtworkPickerDismiss = useCallback(() => {
    setPickerOpen(false);
  }, []);

  return (
    <RoomScaleProvider value={scaleValues}>
      <div className={cx('RoomComposer', { 'RoomComposer--fade': fade })}>
        <div className="RoomComposer-header">
          <div className="RoomComposer-backBtn">
            <Button
              secondary
              elProps={{ to: '/rooms-preview' }}
              element={Link}
              icon="arrowLeft"
            />
          </div>
          <div className="RoomComposer-areaTitle  f-subhead-01">
            <Link to="/rooms-preview">Rooms /</Link>{' '}
            {isPendingExisting ? (
              <span className="RoomComposer-areaTitleInput f-subhead-01">
                ...
              </span>
            ) : (
              <form
                onSubmit={titleForm.handleSubmit}
                style={{
                  display: 'inline',
                }}
              >
                <input
                  ref={titleInputRef}
                  type="text"
                  name="title"
                  placeholder="New Room"
                  className="RoomComposer-areaTitleInput f-subhead-01"
                  value={titleForm.values.title}
                  onChange={titleForm.handleChange}
                />{' '}
              </form>
            )}
          </div>
        </div>
        <div className="RoomComposer-mid">
          <div ref={setRef} className="RoomComposer-inner">
            {wallDimensionsPx && (
              <div
                className={cx('RoomComposer-wall', {
                  'is-dragging': isDraggedArtworkDroppable,
                  'is-warning': !isInBounds,
                })}
                ref={dropRef}
                style={{
                  width: wallDimensionsPx.width,
                  height: wallDimensionsPx.height,
                }}
              >
                <AnimatePresence>
                  {isPendingExisting && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="RoomComposer-loading"
                      key="loading"
                    >
                      <icons.loading />
                    </motion.div>
                  )}

                  {!placements.length &&
                    !isDraggedArtworkDroppable &&
                    !pickerIsOpen &&
                    !isPendingExisting &&
                    !id && (
                      <motion.button
                        className="RoomComposer-zeroState"
                        key="zeroStateBtn"
                        onClick={() => {
                          setPickerOpen(true);
                        }}
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{
                          opacity: 0,
                        }}
                      >
                        <span className="RoomComposer-zeroStateInner">
                          <div className="squareBtn">
                            <icons.add />
                          </div>
                        </span>
                      </motion.button>
                    )}
                </AnimatePresence>

                <div
                  className="RoomComposer-wallLine"
                  style={{
                    top: 0,
                    left: 0,
                    transform: `rotate(${PERSPECTIVE_DEGREES}deg) translate(-100%, -100%)`,
                    transformOrigin: '0% 0%',
                  }}
                ></div>
                <div
                  className="RoomComposer-wallLine"
                  style={{
                    bottom: 0,
                    left: 0,
                    transform: `rotate(${
                      0 - PERSPECTIVE_DEGREES
                    }deg) translate(-100%, 100%)`,
                    transformOrigin: '0% 0%',
                  }}
                ></div>
                <div
                  className="RoomComposer-wallLine"
                  style={{
                    top: 0,
                    right: 0,
                    transform: `rotate(${
                      0 - PERSPECTIVE_DEGREES
                    }deg) translate(100%, 0)`,
                    transformOrigin: '100% 0%',
                  }}
                ></div>
                <div
                  className="RoomComposer-wallLine"
                  style={{
                    bottom: 0,
                    right: 0,
                    transform: `rotate(${PERSPECTIVE_DEGREES}deg) translate(100%, 0)`,
                    transformOrigin: '100% 0%',
                  }}
                ></div>

                {Boolean(placements.length) && (
                  <RoomWall
                    placements={placements}
                    onArtworkMoved={onRoomWallArtworkMoved}
                    onArtworkDelete={onRoomWallArtworkDelete}
                    wallConstraints={wallDimensionsPx}
                  />
                )}

                {!isPendingExisting &&
                  ['top', 'right', 'bottom', 'left'].map((pos) => (
                    <div
                      key={pos}
                      className={cx(
                        'RoomComposer-sliderContainer',
                        'RoomComposer-sliderContainer--' + pos
                      )}
                    >
                      <ScaleSlider
                        values={roomSizeValidations}
                        value={currentSizeIndex}
                        onHoverChange={onScaleSliderHoverChange}
                        isInBounds={isInBounds}
                        position={pos}
                        vertical={pos === 'left' || pos === 'right'}
                        onChange={onScaleSliderChange}
                      />
                    </div>
                  ))}

                <motion.div
                  className="RoomComposer-wallSize num"
                  style={{
                    opacity: fade ? 1 : 0,
                  }}
                >
                  {formatRoomSize(currentSize)}
                </motion.div>
              </div>
            )}
          </div>
        </div>

        <motion.div
          className="RoomComposer-picker"
          initial={{
            y: '100%',
          }}
          animate={{
            y: pickerIsOpen ? '0%' : '100%',
          }}
          transition={{
            type: 'spring',
            damping: 50,
            stiffness: 500,
          }}
        >
          <ArtworkPicker
            isOpen={pickerIsOpen}
            canClose={!draggedArtwork}
            onOpen={handleArtworkPickerOpen}
            handleArtworkSelect={handleArtworkPickerArtworkSelect}
            shouldRenderArtwork={shouldRenderArtwork}
            onDismiss={onArtworkPickerDismiss}
          />
        </motion.div>

        {draggedArtwork && (
          <motion.div
            className="RoomComposer-draggable"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
            }}
            initial={false}
            animate={{ x: dragRect.left, y: dragRect.top }}
            transition={draggableTransition}
          >
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{
                scale: 1,
                opacity: 1,
              }}
            >
              <LazyImage
                className="grabbable"
                alt={draggedArtwork.artwork.title}
                src={draggedArtwork.artwork.featured_image.canvas.url}
                placeholder={
                  draggedArtwork.artwork.featured_image.canvas.lqip_url
                }
                width={draggedArtwork.widthPx}
                height={draggedArtwork.heightPx}
              />
            </motion.div>
          </motion.div>
        )}

        <AnimatePresence>
          {!isInBounds && (
            <motion.div
              className="RoomComposer-boundsWarning f-caption-01"
              initial={{
                y: '100%',
              }}
              animate={{
                y: '0%',
              }}
              exit={{
                y: '100%',
              }}
              transition={{
                ease: 'easeInOut',
              }}
            >
              <div className="RoomComposer-boundsWarningInner">
                Room is too small for selected{' '}
                {pluralize('artwork', placements.length)}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </RoomScaleProvider>
  );
}

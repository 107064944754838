import React, { useCallback } from 'react';
import { useMount } from 'react-use';

// Components
import { Wall } from './svg/Wall';
import { Bench } from './svg/Bench';
import LazyImage from '../LazyImage';
import ArtworkSizeSlider from '../ArtworkSizeSlider';

// Hooks
import { useContain } from '../../hooks/useContain';
import { useEstimatorSizes } from './hooks/useEstimatorSizes';

// Assets
import './ArtworkSizeEstimator.css';

// Config
const FIGMA_WALL_WIDTH_PX = 465;
const FIGMA_WALL_HEIGHT_PX = 258;
const WALL_ASPECT_RATIO = FIGMA_WALL_WIDTH_PX / FIGMA_WALL_HEIGHT_PX;
const FIGMA_ROOM_WIDTH_PX = 575;
const FIGMA_BENCH_WIDTH_PX = 200;
const DEFAULT_WALL_TO_ROOM_SCALE = FIGMA_ROOM_WIDTH_PX / FIGMA_WALL_WIDTH_PX;
const BENCH_TO_WALL_SCALE = FIGMA_WALL_WIDTH_PX / FIGMA_BENCH_WIDTH_PX;

export let ArtworkSizeEstimator = ({
  image,
  benchWidthInches = 5 * 12,
  onChange = () => null,
  onApply = () => null,
  onCancel = () => null,
}) => {
  let imageRatio = image.width / image.height;

  let { dimensions, setRef } = useContain({
    aspectRatio: FIGMA_WALL_WIDTH_PX / FIGMA_WALL_HEIGHT_PX,
  });

  let wallWidthInches = benchWidthInches * BENCH_TO_WALL_SCALE;
  let wallHeightInches = wallWidthInches / WALL_ASPECT_RATIO;
  let roomWidthInches = wallWidthInches * DEFAULT_WALL_TO_ROOM_SCALE;
  let roomWidthPx = dimensions?.width ? dimensions?.width : 100;

  let { sizeInchesRange, currentSize, setCurrentSize } = useEstimatorSizes({
    width: image.width,
    height: image.height,
    maxWidthInches: Math.floor(wallWidthInches) - 12,
    maxHeightInches: Math.floor(wallHeightInches) - 12,
  });

  /**
   * pixel:inch ratio for the wall
   */
  let pxPerInch = roomWidthPx / roomWidthInches;

  let benchWidthPx = benchWidthInches * pxPerInch;

  let onSliderChange = useCallback(
    (value) => {
      setCurrentSize(value);
      onChange(value);
    },
    [setCurrentSize, onChange]
  );

  useMount(() => {
    onChange(currentSize);
  });

  /**
   * Infer image dimensions
   */
  let imgWidthPx = currentSize.width * pxPerInch;
  let imgHeightPx = imgWidthPx / imageRatio;

  return (
    <div className="ArtworkSizeEstimator">
      <div className="ArtworkSizeEstimator-mid" ref={setRef}>
        <div className="ArtworkSizeEstimator-inner">
          <Wall />
          {dimensions?.width && (
            <div className="ArtworkSizeEstimator-artwork">
              <LazyImage
                alt="artwork preview"
                src={image.url}
                width={imgWidthPx}
                height={imgHeightPx}
              />
            </div>
          )}
          <div className="ArtworkSizeEstimator-bench">
            {dimensions?.width && <Bench width={benchWidthPx} />}
          </div>
        </div>
      </div>
      <div className="ArtworkSizeEstimator-slider">
        <ArtworkSizeSlider
          values={sizeInchesRange}
          value={currentSize}
          imageRatio={imageRatio}
          onChange={onSliderChange}
          onApply={onApply}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
};

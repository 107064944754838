import React from 'react';
import produce from 'immer';
import { v4 as uuid } from 'uuid';
import * as Sentry from '@sentry/browser';

const SET_ERROR = 'SET_ERROR';
const CLEAR_ERROR = 'CLEAR_ERROR';

let reducer = produce((state, action) => {
  switch (action.type) {
    case SET_ERROR: {
      let id = uuid();
      // Supports only one error at a time.
      // Push to the array if multiple error support is ever needed.
      state[0] = {
        id,
        ...action.error,
      };
      return state;
    }
    case CLEAR_ERROR: {
      state = [];
      return state;
    }
    default: {
      return state;
    }
  }
});

let ErrorContext = React.createContext();

function ErrorProvider(props) {
  let [state, dispatch] = React.useReducer(reducer, []);
  let value = React.useMemo(() => [state, dispatch], [state]);
  return <ErrorContext.Provider value={value} {...props} />;
}

function useErrors() {
  let context = React.useContext(ErrorContext);
  if (!context) {
    throw new Error(`useErrors must be used within a ErrorProvider`);
  }

  let [state, dispatch] = context;

  let setError = React.useCallback(
    (error) => {
      Sentry.captureException(error.error);
      dispatch({
        type: SET_ERROR,
        error,
      });
    },
    [dispatch]
  );

  let clearError = React.useCallback(
    (id) => {
      return dispatch({
        type: CLEAR_ERROR,
        id,
      });
    },
    [dispatch]
  );

  return {
    errors: state,
    setError,
    clearError,
  };
}

export { ErrorProvider, useErrors };

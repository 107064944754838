import React from 'react';

// Assets
import './RoomPreviewArtwork.css';

// Components
import LazyImage from '../LazyImage';

// Hooks
import { useRoomScale } from '../RoomComposer/hooks/useRoomScale';

export default function RoomPreviewArtwork({ placement }) {
  let { artwork } = placement;
  let image = artwork.featured_image.canvas;

  let { toPixels } = useRoomScale();
  let left = toPixels(placement.x_inches);
  let top = toPixels(placement.y_inches);

  let aspectRatio = image.height / image.width;
  let width = toPixels(artwork.width_inches);
  let height = width * aspectRatio;

  return (
    <div
      className="RoomPreviewArtwork"
      style={{
        top,
        left,
      }}
    >
      <LazyImage
        width={width}
        height={height}
        src={image.url}
        placeholder={image.lqip_url}
      />
    </div>
  );
}

import React, { useCallback, useContext } from 'react';

let RoomScaleContext = React.createContext({
  widthPx: null,
  widthInches: null,
});

export let RoomScaleProvider = ({ widthPx, widthInches, ...props }) => {
  return (
    <RoomScaleContext.Provider
      value={{
        widthPx,
        widthInches,
      }}
      {...props}
    />
  );
};

export let useRoomScale = ({ widthPx, widthInches } = {}) => {
  let context = useContext(RoomScaleContext);
  widthPx = widthPx || context.widthPx;
  widthInches = widthInches || context.widthInches;

  let toPixels = useCallback((inch) => inch * (widthPx / widthInches), [
    widthInches,
    widthPx,
  ]);

  let toInches = useCallback((px) => px * (widthInches / widthPx), [
    widthInches,
    widthPx,
  ]);

  return {
    toPixels,
    toInches,
  };
};

// https://blog.webiny.com/upload-files-to-aws-s3-using-pre-signed-post-data-and-a-lambda-function-7a9fb06d56c1
let uploadFileToS3 = (presignedPostData, url, userPrefixedKey, file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(presignedPostData).forEach((key) => {
      let value = presignedPostData[key];
      if (key === 'key') {
        value = userPrefixedKey;
      }
      formData.append(key, value);
    });

    formData.append('Content-Type', file.type);

    // Actual file has to be appended last.
    formData.append('file', file);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.send(formData);
    xhr.onload = function () {
      this.status === 204 ? resolve() : reject(this.responseText);
    };
  });
};

export default uploadFileToS3;

import React, { useCallback, useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, queryCache } from 'react-query';
import pluralize from 'pluralize';
import { useInView } from 'react-intersection-observer';
import axios from 'axios';
import qs from 'qs';
import produce from 'immer';
import { Link, useLocation, useHistory } from 'react-router-dom';
import cx from 'classnames';

// Assets
import './IndexTable.css';

// Components
import {
  ConfirmDialog,
  ConfirmDialogPortal,
} from '../ConfirmDialog/ConfirmDialog';
import { IndexTableRow } from './IndexTableRow';
import icons from '../_icons';

// Hooks
import { useToast } from '../../hooks/Toast';
import { useErrors } from '../../hooks/Error';

let MemoizedIndexTableRow = React.memo(IndexTableRow);

let deleteArtwork = ({ id }) => {
  return axios.delete(`/api/me/artworks/${id}`);
};

let deleteArtworkReducer = (state = { artworks: [] }, artwork) => {
  return produce(state, (draft) => {
    for (let i = 0; i < draft.artworks.length; i++) {
      let group = draft.artworks[i].data.data;
      let matchedArtwork = group.find((v) => v.id === artwork.id);
      let idx = group.indexOf(matchedArtwork);
      if (idx !== -1) {
        group.splice(idx, 1);
        break;
      }
    }
    return draft;
  });
};

export default function IndexTable({
  searchTerm,
  searchInputValue,
  isParentLoading,
  isParentEmpty,
}) {
  let location = useLocation();
  let history = useHistory();

  let queryParams = new URLSearchParams(location.search);
  let order_by = queryParams.get('order_by');
  let order_dir = queryParams.get('order_dir');

  searchTerm = searchTerm || '';

  const iq = useInfiniteQuery(
    ['me/artworks', { q: searchTerm, order_by, order_dir }],
    async (key, args, nextPage = 1) => {
      let data = await axios.get(
        '/api/me/artworks?' +
          qs.stringify({
            page: nextPage,
            perPage: 20,
            q: args.q,
            order_by: args.order_by,
            order_dir: args.order_dir,
          })
      );
      return data;
    },
    {
      staleTime: 30 * 1000,
      getFetchMore: (lastGroup, allGroups) =>
        lastGroup.data.current_page === lastGroup.data.last_page
          ? null
          : lastGroup.data.current_page + 1,
    }
  );

  let { status, data, error, isFetchingMore, fetchMore, canFetchMore } = iq;

  let total = data[0]?.data?.total;

  let [loadBtnRef, loadBtnInView] = useInView({
    threshold: 0,
    root: document.getElementById('AppMain'),
  });

  let onLoadBtnClick = useCallback(() => {
    fetchMore();
  }, [fetchMore]);

  let loadBtnIsDisabled = !canFetchMore || isFetchingMore;

  useEffect(() => {
    if (
      !loadBtnIsDisabled &&
      loadBtnInView &&
      !isFetchingMore &&
      canFetchMore
    ) {
      fetchMore();
    }
  }, [
    loadBtnIsDisabled,
    loadBtnInView,
    fetchMore,
    isFetchingMore,
    canFetchMore,
  ]);

  let { setToast } = useToast();
  let { setError } = useErrors();

  let [deleteMutate] = useMutation(deleteArtwork, {
    onSettled() {
      queryCache.refetchQueries('me/artworks', {
        force: true,
      });
    },
    onSuccess() {
      setToast({
        message: 'Your artwork has been deleted.',
      });
    },
    onError(eror) {
      setError({
        message: 'Your artwork could not be deleted',
        error,
      });
    },
  });

  let onIndexTableRowDeleteClick = useCallback((data) => {
    setDeleteDialog({
      artwork: data.artwork,
    });
  }, []);

  let [deleteDialog, setDeleteDialog] = useState(null);

  let onDeleteDialogConfirm = useCallback(() => {
    let queryKey = ['me/artworks', { q: searchTerm }];
    queryCache.setQueryData(queryKey, () => {
      let oldData = queryCache.getQuery(queryKey);
      return deleteArtworkReducer(
        { artworks: oldData.state.data },
        deleteDialog.artwork
      ).artworks;
    });
    deleteMutate({
      id: deleteDialog.artwork.id,
      artwork: deleteDialog.artwork,
    });
    setDeleteDialog(null);
  }, [deleteDialog, deleteMutate, searchTerm]);

  let onDeleteDialogCancel = useCallback(() => {
    setDeleteDialog(null);
  }, []);

  let isLoading =
    isParentLoading || (status === 'loading' && (!data || !data.length));

  let onHeaderLinkClick = useCallback(
    (fieldName) => {
      if (order_by === fieldName) {
        if (order_dir === 'desc') {
          history.replace({
            search: qs.stringify({
              ...Object.fromEntries(queryParams),
              order_dir: undefined,
              order_by: undefined,
              q: undefined,
            }),
          });
        } else {
          history.replace({
            search: qs.stringify({
              ...Object.fromEntries(queryParams),
              q: undefined,
              order_dir: 'desc',
            }),
          });
        }
      } else {
        history.replace({
          search: qs.stringify({
            ...Object.fromEntries(queryParams),
            order_dir: undefined,
            order_by: fieldName,
            q: undefined,
          }),
        });
      }
    },
    [order_by, order_dir, history, queryParams]
  );

  return (
    <>
      <div
        role="table"
        aria-label="Index"
        className={`IndexTable ${
          status === 'loading' || searchInputValue !== searchTerm
            ? 'is-loading'
            : ''
        }`}
      >
        <div className="IndexTable-header" role="rowgroup">
          <div
            role="columnheader"
            className="IndexTable-headerCell IndexTable-imageCol"
          >
            <div className="IndexTable-headerInner">
              {total != null && `${total} ${pluralize('item', total)}`}
            </div>
          </div>
          <div
            role="columnheader"
            className="IndexTable-headerCell IndexTable-titleCol"
          >
            <button
              onClick={() => onHeaderLinkClick('title')}
              className={cx('IndexTable-headerLink IndexTable-headerInner', {
                'is-current': order_by === 'title',
              })}
            >
              Title
              <div className="IndexTable-headerIcon">
                {order_by === 'title' && order_dir === 'desc' ? (
                  <icons.caretDown />
                ) : order_by === 'title' ? (
                  <icons.caretUp />
                ) : null}
              </div>
            </button>
          </div>
          <div
            role="columnheader"
            className="IndexTable-headerCell IndexTable-artistCol"
          >
            <button
              onClick={() => onHeaderLinkClick('artist_name')}
              className={cx('IndexTable-headerLink IndexTable-headerInner', {
                'is-current': order_by === 'artist_name',
              })}
            >
              Artist
              <div className="IndexTable-headerIcon">
                {order_by === 'artist_name' && order_dir === 'desc' ? (
                  <icons.caretDown />
                ) : order_by === 'artist_name' ? (
                  <icons.caretUp />
                ) : null}
              </div>
            </button>
          </div>
          <div
            role="columnheader"
            className="IndexTable-headerCell IndexTable-yearCol"
          >
            <button
              onClick={() => onHeaderLinkClick('date_made')}
              className={cx('IndexTable-headerLink IndexTable-headerInner', {
                'is-current': order_by === 'date_made',
              })}
            >
              Created
              <div className="IndexTable-headerIcon">
                {order_by === 'date_made' && order_dir === 'desc' ? (
                  <icons.caretDown />
                ) : order_by === 'date_made' ? (
                  <icons.caretUp />
                ) : null}
              </div>
            </button>
          </div>
          <div
            role="columnheader"
            className="IndexTable-headerCell IndexTable-dateAcquiredCol IndexTable-lastCol"
          >
            <button
              onClick={() => onHeaderLinkClick('date_acquired')}
              className={cx('IndexTable-headerLink IndexTable-headerInner', {
                'is-current': order_by === 'date_acquired',
              })}
            >
              Acquired
              <div className="IndexTable-headerIcon">
                {order_by === 'date_acquired' && order_dir === 'desc' ? (
                  <icons.caretDown />
                ) : order_by === 'date_acquired' ? (
                  <icons.caretUp />
                ) : null}
              </div>
            </button>
          </div>
        </div>
        <div>
          {isLoading ? (
            Array(10)
              .fill(0)
              .map((e, i) => i + 1)
              .map((i) => (
                <div
                  key={i}
                  role="rowgroup"
                  className="IndexTable-row IndexTable-loadingRow"
                >
                  <div
                    role="cell"
                    className="IndexTable-cell IndexTable-imageCol"
                  >
                    <div className="IndexTable-imagePlaceholder" />
                  </div>
                  <div
                    role="cell"
                    className="IndexTable-cell IndexTable-loadingCol"
                  >
                    <div className="IndexTable-loadingRowText">Loading...</div>
                  </div>
                </div>
              ))
          ) : status === 'error' ? (
            <div role="rowgroup" className="IndexTable-row">
              <div
                role="cell"
                className="IndexTable-cell IndexTable-loadingRow"
              >
                Something went wrong.
              </div>
            </div>
          ) : data[0]?.data?.data?.length ? (
            data.map((page, i) => (
              <React.Fragment key={i}>
                {page.data.data.map((artwork, ii) => (
                  <MemoizedIndexTableRow
                    key={artwork.id}
                    onDeleteClick={onIndexTableRowDeleteClick}
                    artwork={artwork}
                  />
                ))}
              </React.Fragment>
            ))
          ) : (
            <div className="IndexTable-empty">
              <b>No {searchTerm ? 'results' : 'artworks'} found.</b>
              <br />
              {searchTerm && (
                <>
                  There are no results for your search
                  {searchTerm && ` “${searchTerm}”`}.
                  <br /> Try adjusting your search terms to find results.
                </>
              )}
              {searchTerm && (
                <div className="IndexTable-emptyBtn">
                  <Link
                    to={{
                      pathname: location.pathname,
                      search: qs.stringify({
                        ...{},
                        q: undefined,
                      }),
                    }}
                    className="link"
                  >
                    Clear filters
                  </Link>
                </div>
              )}
            </div>
          )}
          {canFetchMore && (
            <div role="rowgroup" className="IndexTable-row" ref={loadBtnRef}>
              <div role="cell" className="IndexTable-loadMore">
                {isFetchingMore ? (
                  'Loading more...'
                ) : (
                  <button onClick={onLoadBtnClick} disabled={loadBtnIsDisabled}>
                    Load More
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <ConfirmDialogPortal>
        {deleteDialog && (
          <ConfirmDialog
            onConfirm={onDeleteDialogConfirm}
            onCancel={onDeleteDialogCancel}
            confirmLabel="Delete"
            title="Delete this file?"
            message={`Do you want to delete ${deleteDialog.artwork.title} from your collection?`}
          />
        )}
      </ConfirmDialogPortal>
    </>
  );
}

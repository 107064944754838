import React from 'react';

export default function Edit24() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 12V18H6V6H12" stroke="currentColor" />
      <path
        d="M17.3106 4.57755L19.432 6.69887L13.5456 12.5853L11.159 12.8504L11.4242 10.4639L17.3106 4.57755Z"
        stroke="currentColor"
      />
      <rect
        x="15.8964"
        y="5.28455"
        width="4"
        height="1"
        transform="rotate(45 15.8964 5.28455)"
        fill="currentColor"
      />
    </svg>
  );
}

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery, queryCache } from 'react-query';
import { useLocalStorage } from 'react-use';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

// Hooks
import { useToast } from './Toast';
import { useErrors } from './Error';

// Utils
import sleeper from '../utils/sleeper';

let AuthContext = React.createContext();

const LOCAL_STORAGE_LOGGEDIN_KEY = 'loggedin';

function AuthProvider({ ...props }) {
  let { setToast } = useToast();
  let [loggedIn, setLoggedIn] = useLocalStorage(
    LOCAL_STORAGE_LOGGEDIN_KEY,
    null
  );
  let { setError } = useErrors();
  let [isActive, setActive] = useState(true);

  useEffect(() => {
    let interceptor = axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        // Unauthorized
        // If it's an HTTP 401 error, it will assume the user's session has expired.
        if (error.response?.status === 401) {
          setLoggedIn(null);
          window.localStorage.removeItem(LOCAL_STORAGE_LOGGEDIN_KEY);
          // setToast({
          //   message: 'You have been signed out.',
          // });
        } else if (
          error.response?.status === 403 &&
          error.response?.data.error === 'Inactive'
        ) {
          setActive(false);
        } else {
          return Promise.reject(error);
        }
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  });

  let getUser = useCallback(() => {
    if (!loggedIn) return null;
    return axios.get('/api/me');
  }, [loggedIn]);

  let { data: user, status: userStatus, error } = useQuery(
    loggedIn ? ['me'] : null,
    getUser,
    {
      staleTime: 30 * 1000,
    }
  );

  useEffect(() => {
    Sentry.setUser({ email: user?.data?.email });
  }, [user]);

  useEffect(() => {
    if (error) {
      setError({
        message: 'Something went wrong when getting your user data.',
        error,
      });
      setLoggedIn(null);
      window.localStorage.removeItem(LOCAL_STORAGE_LOGGEDIN_KEY);
    }
  }, [error, setError, setLoggedIn]);

  let logout = useCallback(() => {
    setLoggedIn(null);
    window.localStorage.removeItem(LOCAL_STORAGE_LOGGEDIN_KEY);
    queryCache.clear();
    return axios
      .post('/logout')
      .then(() => {
        setToast({
          message: 'You are now signed out.',
        });
      })
      .catch((error) => {
        setError({
          message: 'Something went wrong when logging out.',
          error,
        });
      });
  }, [setLoggedIn, setError, setToast]);

  let login = useCallback(
    async ({ email, password }) => {
      let loginPromise;
      await axios.get('/sanctum/csrf-cookie');
      try {
        loginPromise = await axios.post('/login', {
          email,
          password,
        });
        setToast({
          message: 'You are now signed in.',
        });
      } catch (error) {
        if (error.response?.status !== 422) {
          setError({
            message: 'Something went wrong when getting your user data.',
            error,
          });
          return;
        } else {
          throw error;
        }
      }

      let userPromise = queryCache.prefetchQuery(['me'], () =>
        axios.get('/api/me').catch((err) => null)
      );

      let promises = Promise.all([sleeper(2000), userPromise, loginPromise]);

      promises.then(() => {
        setLoggedIn('true');
      });

      return promises;
    },
    [setLoggedIn, setError, setToast]
  );

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        user,
        userStatus,
        login,
        logout,
        isActive,
      }}
      {...props}
    />
  );
}

function useAuth() {
  let context = useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}

export { AuthProvider, useAuth };

import { useCallback, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import produce from 'immer';
import { usePrevious } from 'react-use';
import { v4 as uuid } from 'uuid';
import { useDebouncedCallback } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import { useQuery, queryCache } from 'react-query';

// Hooks
import { useCheckVersion } from '../../../hooks/useCheckVersion';
import { useCallQueue } from '../../../hooks/useCallQueue';
import { useRoomCacheBuster } from '../../../hooks/RoomCacheBuster';

// Constants
const ADD_ARTWORK_PLACEMENT = 'ADD_ARTWORK_PLACEMENT';
const ARTWORK_PLACEMENT_ADDED = 'ARTWORK_PLACEMENT_ADDED';
const UPDATE_ARTWORK_PLACEMENT = 'UPDATE_ARTWORK_PLACEMENT';
const REPOSITION_ARTWORKS = 'REPOSITION_ARTWORKS';
const REMOVE_ARTWORK = 'REMOVE_ARTWORK';
const HYDRATE_ROOM = 'HYDRATE_ROOM';

export const STATUS_LOADING = 'loading';
export const STATUS_FAILED = 'error';
export const STATUS_SUCCESSFUL = 'success';

let storeRoom = (data) => axios.post('/api/me/rooms', data);

let updateRoom = (data) => axios.put(`/api/me/rooms/${data.id}`, data);

let storeArtworkPlacement = (data) =>
  axios.post('/api/me/artwork_placements', data);

let updateArtworkPlacement = (data) => {
  return axios.put(`/api/me/artwork_placements/${data.id}`, data);
};

let destroyArtworkPlacement = (data) =>
  axios.delete(`/api/me/artwork_placements/${data.id}`, {
    headers: {
      'X-Rookery-Sync': data.sync,
    },
  });

let callRepositionArtworkPlacements = (data) =>
  axios.patch(`/api/me/rooms/${data.id}/reposition`, data);

let roomReducer = produce((state, action) => {
  switch (action.type) {
    case HYDRATE_ROOM: {
      state.artwork_placements = [];
      action.artworkPlacements.forEach((artworkPlacement) => {
        state.artwork_placements.push({
          ...artworkPlacement,
        });
      });
      return state;
    }
    case ADD_ARTWORK_PLACEMENT: {
      state.artwork_placements.push({
        cid: action.cid,
        x_inches: action.x_inches,
        y_inches: action.y_inches,
        artwork: action.artwork,
      });
      return state;
    }
    case ARTWORK_PLACEMENT_ADDED: {
      let obj = state.artwork_placements.find((val) => val.cid === action.cid);
      obj.id = action.id;
      obj.room_id = action.room_id;
      obj.artwork_id = action.artwork_id;
      return state;
    }
    case UPDATE_ARTWORK_PLACEMENT: {
      let obj = state.artwork_placements.find((val) => val.cid === action.cid);
      let idx = state.artwork_placements.indexOf(obj);
      state.artwork_placements[idx].x_inches = action.x_inches;
      state.artwork_placements[idx].y_inches = action.y_inches;
      return state;
    }
    case REPOSITION_ARTWORKS: {
      action.coordinates.forEach((coord) => {
        let obj = state.artwork_placements.find((v) => v.cid === coord.cid);
        obj.x_inches = coord.x_inches;
        obj.y_inches = coord.y_inches;
      });
      return state;
    }
    case REMOVE_ARTWORK: {
      let idx = state.artwork_placements.indexOf(
        state.artwork_placements.find((obj) => obj.cid === action.cid)
      );
      state.artwork_placements.splice(idx, 1);
      return state;
    }
    default: {
      return state;
    }
  }
});

export let useRoomData = ({ id }) => {
  let [room, setRoom] = useState(null);
  let history = useHistory();

  let [state, dispatch] = useReducer(roomReducer, {
    artwork_placements: [],
  });

  let { shouldBust, resetBust } = useRoomCacheBuster();

  let placements = state.artwork_placements;

  let checkVersion = useCallback(() => {
    return axios
      .get(`/api/me/rooms/${id}/version`)
      .then((res) => res.data.version);
  }, [id]);

  let { lastVersion, setLastVersion, resetVersion } = useCheckVersion({
    checkVersion,
    resetMessage: 'Room was reloaded with latest version.',
  });

  /**
   * If another component has requested cache invalidation for the current room,
   * then reset the version (which will in-turn refetch the room)
   */
  useEffect(() => {
    if (shouldBust) {
      resetVersion();
      resetBust();
    }
  }, [shouldBust, resetBust, resetVersion]);

  /**
   * Whenever there is no 'lastVersion', then declaratively fetch the latest room.
   * (We apply latest room to the room state in the useEffect below).
   * This allows us to keep working mostly with local data,
   * but utilising the react-query cache where sensible.
   */
  let { data: { data: latestRoom } = {}, status, error, refetch } = useQuery(
    lastVersion || !id ? null : ['me/rooms/:id', { id }],
    (key, vars) => axios.get(`/api/me/rooms/${vars.id}`),
    {
      // manual: true,
    }
  );

  // console.log('latestRoom', latestRoom);
  // console.log('status', status);
  // console.log('id', id, typeof id);
  // console.log('latestRoom', latestRoom, status);

  /**
   * Apply the latest server data to local state whenever the sync value has changed.
   * (or there is not yet any local state)
   */
  useEffect(() => {
    // console.group();
    // console.log('room', room);
    // console.log('lastVersion', lastVersion);
    // console.groupEnd();
    if (
      latestRoom &&
      (!lastVersion ||
        !room ||
        (latestRoom.sync && latestRoom.sync !== room.sync))
    ) {
      console.log('latestRoom.sync', latestRoom.sync);
      console.log('latestRoom', latestRoom);
      setLastVersion(latestRoom.sync);
      setRoom(latestRoom);
      dispatch({
        type: HYDRATE_ROOM,
        artworkPlacements: latestRoom.artwork_placements,
      });
    }
  }, [latestRoom, lastVersion, setLastVersion, room]);

  useEffect(() => {
    if (!lastVersion) {
      refetch();
    }
  }, [lastVersion, refetch]);

  /**
   * Go to the 404 screen if the room data is not found on the server.
   */
  useEffect(() => {
    if (status === STATUS_FAILED) {
      if (error.response?.status === 404) {
        history.replace('/404');
      }
    }
  }, [status, error, history]);

  /**
   * If the ID is switched, then reset the version (and therefore trigger a data reload)
   */
  let prevId = usePrevious(id);
  useEffect(() => {
    if (id !== prevId && prevId) {
      resetVersion();
    }
  }, [id, prevId, resetVersion]);

  let { enqueueCall, callQueue, triggerNextCall } = useCallQueue();

  /**
   * Allow pending calls to wait for a given case to be resolved,
   * before being triggered.
   */
  let nextPendingCall = callQueue[0];
  useEffect(() => {
    if (!nextPendingCall || nextPendingCall.status === 'loading') {
      return;
    }
    if (nextPendingCall.name === 'updateArtworkPlacement') {
      // Ensure that the artwork placement has the ID from the server
      // before calling any PUT request on it
      let placement = state.artwork_placements.find(
        (val) => val.cid === nextPendingCall.data.cid
      );
      if (placement?.id) {
        triggerNextCall({
          placement,
          id,
          sync: lastVersion,
        });
      }
    } else {
      triggerNextCall({
        id,
        sync: lastVersion,
        room,
      });
    }
  }, [
    id,
    triggerNextCall,
    lastVersion,
    nextPendingCall,
    state.artwork_placements,
    room,
  ]);

  let createRoom = useCallback(
    (room, artworkPlacement, cb) => {
      let cid = uuid();
      dispatch({
        type: ADD_ARTWORK_PLACEMENT,
        cid,
        ...artworkPlacement,
      });
      setRoom(room);
      console.log('enqueueCall...');
      enqueueCall(
        'storeRoom',
        {},
        () => {
          console.log('storeRoom...');

          let ret = storeRoom({
            ...room,
            sync: lastVersion,
            artwork_placements: [
              {
                cid,
                ...artworkPlacement,
              },
            ],
          });

          ret.then((res) => {
            setLastVersion(res.data.sync);
            queryCache.removeQueries('me/rooms', {
              force: true,
              exact: false,
            });
            queryCache.removeQueries('me/rooms/:id', {
              force: true,
              exact: false,
            });
            // let queryKey = ['me/rooms/:id', { id: res.data.id.toString() }];
            // console.log('res.data', res.data);
            // queryCache.setQueryData(queryKey, res);

            queryCache
              .prefetchQuery(
                ['me/rooms/:id', { id: res.data.id.toString() }],
                (key, vars) =>
                  axios.get('/api/me/rooms/' + vars.id).catch((err) => null)
              )
              .then(() => {
                cb && cb(res);
              });
          });

          ret.then((res) => {
            dispatch({
              type: ARTWORK_PLACEMENT_ADDED,
              id: res.data.id,
              room_id: res.data.room_id,
              artwork_id: res.data.artwork_id,
              cid,
            });
          });

          return ret;
        },
        true
      );
    },
    [enqueueCall, lastVersion, setLastVersion]
  );

  let editRoom = useCallback(
    (newRoomObj, cb) => {
      setRoom({
        ...room,
        ...newRoomObj,
      });
      enqueueCall('editRoom', {}, ({ id, sync, room }) => {
        let ret;

        if (id) {
          // If this room has an ID by this point, then
          // PUT the title
          ret = updateRoom({
            id,
            sync,
            ...newRoomObj,
          });
        } else {
          // But if this is the first user action on a new room,
          // then store the room
          ret = storeRoom({
            room,
            ...newRoomObj,
            sync,
          });
        }

        ret.then((res) => {
          setLastVersion(res.data.sync);

          queryCache.removeQueries('me/rooms', {
            force: true,
            exact: false,
          });

          queryCache.removeQueries('me/rooms/:id', {
            force: true,
            exact: false,
          });

          cb && cb(res);
        });

        return ret;
      });
    },
    [enqueueCall, room, setLastVersion]
  );

  let createArtworkPlacement = useCallback(
    (artworkPlacement) => {
      let cid = uuid();
      // Update local state
      dispatch({
        type: ADD_ARTWORK_PLACEMENT,
        cid,
        ...artworkPlacement,
      });
      enqueueCall('storeArtworkPlacement', {}, ({ sync }) => {
        let ret = storeArtworkPlacement({
          cid,
          sync,
          ...artworkPlacement,
        });

        ret.then((res) => {
          setLastVersion(res.data.room.sync);
          queryCache.removeQueries('me/rooms', {
            force: true,
            exact: false,
          });
          queryCache.removeQueries('me/rooms/:id', {
            force: true,
            exact: false,
          });
        });

        ret.then((res) => {
          dispatch({
            type: ARTWORK_PLACEMENT_ADDED,
            id: res.data.id,
            room_id: res.data.room_id,
            artwork_id: res.data.artwork_id,
            cid,
          });
        });

        return ret;
      });
    },
    [enqueueCall, setLastVersion]
  );

  let editArtworkPlacement = useCallback(
    (artworkPlacement) => {
      // Update local state
      dispatch({
        type: UPDATE_ARTWORK_PLACEMENT,
        ...artworkPlacement,
      });
      enqueueCall(
        'updateArtworkPlacement',
        { cid: artworkPlacement.cid },
        ({ placement, sync }) => {
          let ret = updateArtworkPlacement({
            ...placement,
            sync,
          });

          ret.then((res) => {
            setLastVersion(res.data.room.sync);
            queryCache.removeQueries('me/rooms', {
              force: true,
              exact: false,
            });
            queryCache.removeQueries('me/rooms/:id', {
              force: true,
              exact: false,
            });
          });

          ret.catch((err) => {
            console.error('Update artwork placement error', err);
          });
          return ret;
        }
      );
    },
    [enqueueCall, setLastVersion]
  );

  let [debouncedEnqueueReposition] = useDebouncedCallback((data) => {
    enqueueCall('callRepositionArtworkPlacements', {}, ({ sync }) => {
      let ret = callRepositionArtworkPlacements({
        ...data,
        id,
        sync,
      });
      ret.then((res) => {
        setLastVersion(res.data.sync);
        queryCache.removeQueries('me/rooms', {
          force: true,
          exact: false,
        });
        queryCache.removeQueries('me/rooms/:id', {
          force: true,
          exact: false,
        });
      });
      return ret;
    });
  }, 500);

  let localReposition = useCallback(
    ({ roomCrop }) => {
      let coordinates = placements.map((obj) => ({
        cid: obj.cid,
        x_inches: obj.x_inches + (roomCrop?.left || 0),
        y_inches: obj.y_inches + (roomCrop?.top || 0),
      }));

      // Update local state
      dispatch({
        type: REPOSITION_ARTWORKS,
        coordinates,
      });
    },
    [placements]
  );

  let repositionArtworkPlacements = useCallback(
    ({ roomSize, isInBounds }) => {
      let { widthInches, heightInches } = roomSize;

      // Call API
      if (id && isInBounds) {
        debouncedEnqueueReposition({
          width_inches: widthInches,
          height_inches: heightInches,
          coordinates: placements.map(({ cid, x_inches, y_inches }) => ({
            cid,
            x_inches,
            y_inches,
          })),
        });
      }
    },
    [id, placements, debouncedEnqueueReposition]
  );

  let removePlacement = useCallback(
    ({ placement, cb }) => {
      dispatch({
        type: REMOVE_ARTWORK,
        cid: placement.cid,
      });
      enqueueCall('destroyArtworkPlacement', {}, ({ sync }) => {
        let ret = destroyArtworkPlacement({
          ...placement,
          sync,
        });
        ret.then((res) => {
          setLastVersion(res.data.sync);
          queryCache.removeQueries('me/rooms', {
            force: true,
            exact: false,
          });
          queryCache.removeQueries('me/rooms/:id', {
            force: true,
            exact: false,
          });
          cb && cb(res);
        });
        return ret;
      });
    },
    [enqueueCall, setLastVersion]
  );

  room = room || latestRoom;

  return {
    room,
    placements,
    createRoom,
    editRoom,
    createArtworkPlacement,
    editArtworkPlacement,
    removePlacement,
    localReposition,
    repositionArtworkPlacements,
    resetVersion,
    status,
  };
};

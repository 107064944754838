import React, { useContext, useMemo } from 'react';
import { useMotionValue } from 'framer-motion';

let ScrollContext = React.createContext(null);

export let useScrollContext = () => {
  let { scrollXY, scrollbarWidth } = useContext(ScrollContext);
  let { x, y } = scrollXY;
  let motionY = useMotionValue(y);
  motionY.set(y);
  return useMemo(() => ({ x, y, motionY, scrollbarWidth }), [
    x,
    y,
    motionY,
    scrollbarWidth,
  ]);
};

export let ScrollProvider = (props) => {
  return <ScrollContext.Provider value={props.value} {...props} />;
};

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { usePrevious } from 'react-use';
import qs from 'qs';

// Assets
import './ResetPassword.css';

// Components
import Button from '../Button';
import TextField from '../_forms/TextField';

// Hooks
import { Heading } from '../../hooks/DocumentOutline';
import { useErrors } from '../../hooks/Error';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useRandomColor } from '../../hooks/useRandomColor';

let logo = require('../../images/logo.svg');

let resetPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required().label('E-mail address'),
  password: Yup.string().required('Password'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password fields must match')
    .required()
    .label('Password confirmation'),
});

export default function ResetPassword() {
  let { loggedIn, logout } = useAuth();
  let { setToast } = useToast();

  useEffect(() => {
    if (loggedIn) {
      logout();
    }
  }, [loggedIn, logout]);

  let location = useLocation();
  let history = useHistory();
  let queryParams = new URLSearchParams(location.search);
  let token = queryParams.get('token');
  let email = queryParams.get('email');
  let isCreatingPassword = Boolean(queryParams.get('create'));

  let [isLoading, setLoading] = useState(false);
  let { setError } = useErrors();

  let standardVerb = 'Reset';
  let submittingVerb = 'Resetting';
  let pastVerb = 'reset';

  if (isCreatingPassword) {
    standardVerb = 'Create';
    submittingVerb = 'Creating';
    pastVerb = 'created';
  }

  let formik = useFormik({
    validationSchema: resetPasswordSchema,
    enableReinitialize: true,
    initialValues: {
      token,
      email,
      password: '',
      password_confirmation: '',
    },
    onSubmit: async () => {
      setLoading(true);
      try {
        await axios.post('/password/reset', {
          email: formik.values.email,
          token: formik.values.token,
          password: formik.values.password,
          password_confirmation: formik.values.password_confirmation,
        });
      } catch (err) {
        setLoading(false);
        formik.setSubmitting(false);
        if (err.response) {
          Object.keys(err.response.data?.errors || {}).forEach((field) => {
            let errors = err.response.data.errors[field];
            let error = Array.isArray(errors) ? errors[0] : errors;
            formik.setFieldError('general', error);
          });
        }

        return;
      }

      setToast({
        message: `Password for ${formik.values.email} has been ${pastVerb}`,
      });

      setLoading(false);
      history.push('/', {
        disableRouteAnimation: true,
      });
    },
  });

  let isFormInvalid = Boolean(Object.keys(formik.errors).length);

  let firstError =
    isFormInvalid && formik.submitCount > 0
      ? Object.values(formik.errors)[0]
      : null;

  let { data: { data: { token_detail = null } = {} } = {}, error } = useQuery(
    ['password/token', { token, email }],
    (key, vars) =>
      axios.get(
        `/password/token?${qs.stringify({
          email: vars.email,
          token: vars.token,
        })}`
      ),
    {
      retry: 0,
    }
  );

  let [errorState, setErrorState] = useState(null);

  let prevError = usePrevious(error);

  useEffect(() => {
    if (error && !prevError) {
      setErrorState(error);
    }
  }, [error, prevError]);

  useEffect(() => {
    if (errorState) {
      setError({
        message: 'Something went wrong. Please try another password reset.',
      });
    }
  }, [errorState, setError]);

  let { color: randomColor, image: randomImage } = useRandomColor();

  return (
    <div
      className={cx('ResetPassword', { 'is-loading': isLoading })}
      style={{
        '--theme-input-hover':
          randomColor && !isLoading
            ? `var(--color-brand-${randomColor}-15)`
            : undefined,
        '--theme-base-bg': randomColor
          ? `var(--color-brand-${randomColor}-10)`
          : undefined,
      }}
    >
      <div className="ResetPassword-imageCol">
        <img src={randomImage} alt="" className="ResetPassword-imageBg" />
        <img
          src={logo}
          alt="Rookery - An art recollection tool"
          className="ResetPassword-logo"
          width={368}
          height={75}
        />
      </div>
      <div className="ResetPassword-formCol">
        <div className="ResetPassword-loader"></div>
        <motion.div
          className="ResetPassword-title"
          initial={false}
          animate={{ opacity: isLoading ? 0 : 1 }}
        >
          <Heading className="f-heading-01">
            {standardVerb}
            <br />
            Password
          </Heading>
          {token_detail?.email && (
            <p className="ResetPassword-titleSub f-subhead-01">
              For {token_detail.email}
            </p>
          )}
        </motion.div>
        {!error && (
          <form className="ResetPassword-form" onSubmit={formik.handleSubmit}>
            <input type="hidden" name="token" value={formik.values.token} />

            <motion.div
              className="ResetPassword-formInner"
              initial={false}
              animate={{ opacity: isLoading ? 0 : 1 }}
            >
              <TextField
                label="Password*"
                placeholder="Password*"
                name="password"
                type="password"
                autoFocus
                onChange={formik.handleChange}
                value={formik.values.password || ''}
                formik={formik}
              />
              <TextField
                label="Password confirmation*"
                placeholder="Password confirmation*"
                name="password_confirmation"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password_confirmation || ''}
                formik={formik}
              />
              <div className="PasswordValidation f-caption-01">
                <p>
                  We recommend you{' '}
                  <a
                    className="link"
                    href="https://xkcd.com/936/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    use a passphrase
                  </a>{' '}
                  as your password. We require they are at least 20 letters or
                  numbers long (no special characters).
                </p>
                <p>
                  <strong>Alternatively,</strong> your new password must:
                </p>
                <ul>
                  <li>be at least 8 characters in length</li>
                  <li>contain at least one lowercase letter</li>
                  <li>contain at least one uppercase letter</li>
                  <li>contain at least one digit</li>
                  <li>contain at least one special character</li>
                </ul>
              </div>
              <div className="ResetPassword-footer">
                <div
                  className={cx('f-caption-01 ResetPassword-formNote', {
                    'is-error': Boolean(formik.errors.general || firstError),
                  })}
                >
                  {formik.errors.general || firstError || '*Required'}
                </div>
                <Button
                  tabIndex="0"
                  label={`${
                    formik.isSubmitting ? `${submittingVerb}...` : standardVerb
                  } password`}
                  elProps={{ type: 'submit' }}
                  error={
                    isFormInvalid &&
                    !formik.isSubmitting &&
                    formik.submitCount > 0
                  }
                  disabled={formik.isSubmitting}
                />
              </div>
            </motion.div>
          </form>
        )}
      </div>
    </div>
  );
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';

// Hooks
import { useToast } from './Toast';

export let useCheckVersion = ({
  checkVersion,
  resetMessage = 'Version was reset',
  checkEvery = 10000,
}) => {
  let [lastVersionCheck, setLastVersionCheck] = useState(null);
  let prevLastVersionCheck = usePrevious(lastVersionCheck);
  let [lastVersion, setLastVersion] = useState(null);
  let { setToast } = useToast();

  let resetVersion = useCallback(() => {
    setLastVersion(null);
  }, []);

  useEffect(() => {
    if (!lastVersion) {
      // No need to check the version, if the base data hasn't been fetched.
      return;
    }

    let interval = setInterval(() => {
      checkVersion()
        .then((version) => {
          setLastVersionCheck(version);
        })
        .catch(() => {
          console.error('checkVersion catch');
          resetVersion();
        });
    }, checkEvery);

    return () => {
      clearInterval(interval);
    };
  }, [lastVersion, checkEvery, checkVersion, resetVersion]);

  useEffect(() => {
    if (
      lastVersion &&
      lastVersionCheck &&
      prevLastVersionCheck !== lastVersionCheck &&
      lastVersionCheck !== lastVersion
    ) {
      resetVersion();
      setToast({
        message: resetMessage,
      });
    }
  }, [
    lastVersion,
    prevLastVersionCheck,
    setToast,
    resetVersion,
    lastVersionCheck,
    resetMessage,
  ]);

  let hook = useMemo(
    () => ({
      lastVersion,
      setLastVersion,
      resetVersion,
    }),
    [lastVersion, resetVersion]
  );

  return hook;
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Assets
import './SystemError.css';

// Components
import icons from '../_icons';

export let SystemError = ({ error, onClick }) => {
  let handleClick = useCallback(() => {
    onClick && onClick(error);
  }, [error, onClick]);

  return (
    <div className="SystemError">
      <div className="SystemError-body f-caption-01">{error.message}</div>
      <button className="SystemError-close" onClick={handleClick}>
        <icons.close />
      </button>
    </div>
  );
};

SystemError.propTypes = {
  onClick: PropTypes.func,
  error: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.string,
  }),
};

SystemError.defaultProps = {};

import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

// Components
import RoomWallArtwork from '../RoomWallArtwork';

// Assets
import './RoomWall.css';

// Hooks
import { useBackgroundState } from '../../hooks/BackgroundState';

let RoomWall = ({
  placements,
  onArtworkDelete,
  onArtworkMoved,
  wallConstraints,
}) => {
  let history = useHistory();
  let { getUrlWithBackground } = useBackgroundState();
  let [selectedArtworkPlacement, setSelectedArtworkPlacement] = useState(null);

  let onSelect = useCallback(
    ({ placement }) => {
      setSelectedArtworkPlacement(placement);
      history.push(getUrlWithBackground(`/artworks/${placement.artwork.id}`));
    },
    [history, getUrlWithBackground]
  );

  let onUnselect = useCallback(() => {
    setSelectedArtworkPlacement(null);
  }, []);

  return (
    <div className={cx('RoomWall')}>
      {placements.map((placement) => (
        <RoomWallArtwork
          key={placement.cid}
          placement={placement}
          artwork={placement.artwork}
          onSelect={onSelect}
          onUnselect={onUnselect}
          wallConstraints={wallConstraints}
          isSelected={
            selectedArtworkPlacement &&
            selectedArtworkPlacement.cid === placement.cid
          }
          onRequestRemove={onArtworkDelete}
          onMoved={onArtworkMoved}
        />
      ))}
    </div>
  );
};
export default RoomWall;

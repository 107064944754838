import React from 'react';

export let Wall = React.memo(() => {
  return (
    <svg
      className="WallSVG"
      width="579"
      height="324"
      viewBox="0 0 579 324"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          vectorEffect="non-scaling-stroke"
          d="M521.443 290.5L578.061 323.46"
          stroke="black"
        />
        <path
          vectorEffect="non-scaling-stroke"
          d="M0.895241 323.446L57.5568 290.5"
          stroke="black"
        />
        <path
          vectorEffect="non-scaling-stroke"
          d="M521.443 33.5L578.14 0.550014"
          stroke="black"
        />
        <path
          vectorEffect="non-scaling-stroke"
          d="M0.751511 0.478271L57.5566 33.5001"
          stroke="black"
        />
        <rect
          vectorEffect="non-scaling-stroke"
          x="57.5"
          y="33.5"
          width="464"
          height="257"
          stroke="black"
        />
      </g>
    </svg>

    // <svg width="100%" height="100%" viewBox="0 0 578 322" fill="none">
    //   <path
    //
    //     d="M2 2H577V324H2V2Z"
    //     fill="white"
    //   />
    //   <path
    //
    //     d="M57.1621 291.433L2 323.759"
    //     stroke="black"
    //   />
    //   <path
    //
    //     d="M521.443 291.433L577 324"
    //     stroke="black"
    //   />
    //   <path
    //
    //     d="M57.7192 34.5L1.21924 1.5"
    //     stroke="black"
    //   />
    //   <path
    //
    //     d="M521.443 34.3262L577.719 1"
    //     stroke="black"
    //   />
    //   <rect
    //
    //     x="57.5"
    //     y="34.5"
    //     width="464"
    //     height="257"
    //     stroke="black"
    //   />
    // </svg>
  );
});

import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useInfiniteQuery, queryCache, useMutation } from 'react-query';
import qs from 'qs';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';
import produce from 'immer';
import { useHistory } from 'react-router-dom';

// Assets
import './RoomsIndex.css';

// Components
import {
  ConfirmDialog,
  ConfirmDialogPortal,
} from '../ConfirmDialog/ConfirmDialog';
import Button from '../Button';
import { RoomsIndexRow } from './RoomsIndexRow';
import AcknowledgementDialog from '../AcknowledgementDialog';
import IndexLayout from '../IndexLayout';

// Hooks
import { useToast } from '../../hooks/Toast';
import { useErrors } from '../../hooks/Error';

let deleteRoom = ({ id }) => {
  return axios.delete(`/api/me/rooms/${id}`);
};

let deleteRoomReducer = (state = { rooms: [] }, room) => {
  return produce(state, (draft) => {
    for (let i = 0; i < draft.rooms.length; i++) {
      let group = draft.rooms[i].data.data;
      let matchedRoom = group.find((v) => v.id === room.id);
      let idx = group.indexOf(matchedRoom);
      if (idx !== -1) {
        group.splice(idx, 1);
        break;
      }
    }
    return draft;
  });
};

export default function RoomsIndex() {
  let history = useHistory();

  let {
    status,
    data: pages,
    error,
    isFetchingMore,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery(
    ['me/rooms'],
    (key, nextPage = 1) =>
      axios.get(
        '/api/me/rooms?' +
          qs.stringify({
            page: nextPage,
            perPage: 20,
          })
      ),
    {
      staleTime: 30 * 1000,
      getFetchMore: (lastGroup, allGroups) => {
        if (!lastGroup) {
          return;
        }
        return lastGroup.data.current_page === lastGroup.data.last_page
          ? null
          : lastGroup.data.current_page + 1;
      },
    }
  );
  let total = pages[0]?.data?.total;

  let [loadBtnRef, loadBtnInView] = useInView({
    threshold: 0,
    root: document.getElementById('AppMain'),
  });

  let onLoadBtnClick = useCallback(() => {
    fetchMore();
  }, [fetchMore]);

  let loadBtnIsDisabled = !canFetchMore || isFetchingMore;

  useEffect(() => {
    if (
      !loadBtnIsDisabled &&
      loadBtnInView &&
      !isFetchingMore &&
      canFetchMore
    ) {
      fetchMore();
    }
  }, [
    loadBtnIsDisabled,
    loadBtnInView,
    canFetchMore,
    fetchMore,
    isFetchingMore,
  ]);

  /**
   * Handle room deletion
   */

  let { setError } = useErrors();
  let [deleteDialog, setDeleteDialog] = useState(null);
  let { setToast } = useToast();

  let [deleteMutate] = useMutation(deleteRoom, {
    onSettled: (...args) => {
      queryCache.refetchQueries('me/rooms', {
        force: true,
      });
    },
  });

  let onDeleteDialogConfirm = useCallback(async () => {
    let queryKey = ['me/rooms'];
    queryCache.setQueryData(queryKey, () => {
      let oldData = queryCache.getQuery(queryKey);
      return deleteRoomReducer(
        { rooms: oldData.state.data || [] },
        deleteDialog.room
      ).rooms;
    });
    try {
      setDeleteDialog(null);
      await deleteMutate(
        {
          id: deleteDialog.room.id,
        },
        {
          throwOnError: true,
        }
      );
      setToast({
        message: 'Your room has been deleted.',
      });
    } catch (err) {
      setError({
        message: 'Your room could not be deleted.',
        error: err,
      });
    }
  }, [deleteDialog, deleteMutate, setToast, setError]);

  let onDeleteDialogCancel = useCallback(() => {
    setDeleteDialog(null);
  }, []);

  let handleRoomsIndexRowDeleteClick = useCallback(({ room }) => {
    setDeleteDialog({
      room,
    });
  }, []);

  let hasRooms = pages[0]?.data?.data?.length;
  let [dialogDismissed, setDialogDismissed] = useState(false);
  let dialogMessage =
    'Select the ‘Create a room’ button below to create a new room and begin adding artworks.';
  let isLoading = status === 'loading' && (!pages || !total);

  let loadingPlaceholders = useMemo(
    () =>
      Array(10)
        .fill(0)
        .map((e, i) => i + 1)
        .map((i) => {
          let random = parseInt(Math.random() * (5 - 1) + 1, 10);
          return (
            <div
              key={i}
              role="rowgroup"
              className="RoomsIndex-row RoomsIndex-loadingRow"
            >
              <div role="cell" className="RoomsIndex-loadingCol">
                <div className="RoomsIndex-loadingRowText">Loading...</div>
              </div>
              <div
                role="cell"
                className="RoomsIndex-cell RoomsIndex-loadingImageCol"
              >
                {Array(random)
                  .fill(0)
                  .map((e, i) => i + 1)
                  .map((i) => (
                    <div className="RoomsIndex-imagePlaceholder" key={i} />
                  ))}
              </div>
            </div>
          );
        }),
    []
  );

  let actions = (
    <Button
      label="Create room"
      element={Link}
      elProps={{ to: '/rooms/new' }}
      icon="add"
    />
  );

  return (
    <>
      <IndexLayout title="Rooms" actions={actions}>
        <div role="table" aria-label="Rooms" className="RoomsIndex-rows">
          <div className="RoomsIndex-header" role="rowgroup">
            <div
              role="columnheader"
              className="RoomsIndex-headerCell RoomsIndex-titleCol"
            >
              Title
            </div>
            <div
              role="columnheader"
              className="RoomsIndex-headerCell RoomsIndex-dateCol"
            >
              Last updated
            </div>
            <div
              role="columnheader"
              className="RoomsIndex-headerCell RoomsIndex-artworksCol"
            >
              Artworks
            </div>
          </div>
          {isLoading ? (
            loadingPlaceholders
          ) : status === 'error' ? (
            <div role="rowgroup" className="RoomsIndex-row">
              <div role="cell" className="RoomsIndex-loadingRow">
                {error.message}
              </div>
            </div>
          ) : hasRooms ? (
            pages.map(({ data: { data: rows } }, i) =>
              rows.map((room) => (
                <div className="RoomsIndex-row" key={room.id}>
                  <RoomsIndexRow
                    room={room}
                    onDeleteClick={handleRoomsIndexRowDeleteClick}
                  />
                </div>
              ))
            )
          ) : dialogDismissed ? (
            <div className="RoomsIndex-zero">
              {dialogMessage}
              <div className="RoomsIndex-zeroBtn">
                <Button
                  label="Create room"
                  icon="add"
                  element={Link}
                  elProps={{ to: '/rooms/new' }}
                />
              </div>
            </div>
          ) : null}
          {canFetchMore && (
            <div role="cell" className="RoomsIndex-loadMore" ref={loadBtnRef}>
              {isFetchingMore ? (
                'Loading more...'
              ) : (
                <button onClick={onLoadBtnClick} disabled={loadBtnIsDisabled}>
                  Load More
                </button>
              )}
            </div>
          )}
        </div>
      </IndexLayout>
      <ConfirmDialogPortal>
        {deleteDialog ? (
          <ConfirmDialog
            onConfirm={onDeleteDialogConfirm}
            onCancel={onDeleteDialogCancel}
            confirmLabel="Delete"
            title="Delete this room?"
            message={`Do you want delete the '${deleteDialog.room.title}' room?`}
          />
        ) : (
          !isLoading &&
          !hasRooms &&
          !dialogDismissed && (
            <AcknowledgementDialog
              title="No rooms created yet"
              message={dialogMessage}
              confirmLabel="Create a room"
              onConfirm={() => {
                history.push('/rooms/new');
                setDialogDismissed(true);
              }}
              onCancel={() => setDialogDismissed(true)}
            />
          )
        )}
      </ConfirmDialogPortal>
    </>
  );
}

import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { useInView } from 'react-intersection-observer';
import { queryCache } from 'react-query';
import cx from 'classnames';

// Components
import LazyImage from '../LazyImage';
import ImagePreloader from '../ImagePreloader';
import Button from '../Button';

// Hooks
import { useBackgroundState } from '../../hooks/BackgroundState';

// Utils
import { getArtwork } from '../../utils/endpoints';

export let IndexTableRow = ({ artwork, onDeleteClick }) => {
  let history = useHistory();
  let { getUrlWithBackground } = useBackgroundState();

  let [ref, inView] = useInView({
    threshold: 0,
  });

  let onDeleteBtnClick = useCallback(
    (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      onDeleteClick({
        artwork,
      });
    },
    [onDeleteClick, artwork]
  );

  let href = getUrlWithBackground(`/artworks/${artwork.id}`);

  /**
   * Fetch the artwork data before opening the DetailPanel
   */
  let [isLoadingDetail, setLoadingDetail] = useState(false);
  let onLinkClick = useCallback(
    (evt) => {
      evt.preventDefault();
      setLoadingDetail(true);
      queryCache
        .prefetchQuery(
          ['me/artworks/:id', { id: artwork.id.toString() }],
          (key, vars) => getArtwork(vars.id),
          {
            retry: false,
            throwOnError: true,
          }
        )
        .then(() => {
          setLoadingDetail(false);
          history.push(href);
        })
        .catch(() => {
          // Even if it fails, try and go to the page anyway, and
          // handle errors from there?
          setLoadingDetail(false);
          history.push(href);
        });
    },
    [artwork.id, history, href]
  );

  return (
    <a
      href={href}
      onClick={onLinkClick}
      role="rowgroup"
      className={cx('IndexTable-row', {
        'is-loading': isLoadingDetail,
      })}
      ref={ref}
    >
      <div role="cell" className="IndexTable-cell IndexTable-imageCol">
        {artwork.featured_image ? (
          <>
            {inView && (
              <ImagePreloader url={artwork.featured_image.detail.url} />
            )}
            <LazyImage
              alt={artwork.title}
              src={artwork.featured_image.square_thumb.url}
              placeholder={artwork.featured_image.square_thumb.lqip_url}
              width={74}
              height={74}
            />
          </>
        ) : (
          <div className="IndexTable-imagePlaceholder" />
        )}
      </div>
      <div role="cell" className="IndexTable-cell IndexTable-titleCol">
        {artwork.title}
      </div>
      <div role="cell" className="IndexTable-cell IndexTable-artistCol">
        {artwork.artist_name}
      </div>
      <div role="cell" className="IndexTable-cell IndexTable-yearCol num">
        {artwork.date_made && format(parseISO(artwork.date_made), 'Y')}
      </div>
      <div
        role="cell"
        className="IndexTable-cell IndexTable-dateAcquiredCol IndexTable-lastCol num"
      >
        {artwork.date_acquired && format(parseISO(artwork.date_acquired), 'Y')}
      </div>
      <div className="IndexTable-rowDeleteBtn">
        <Button
          secondary
          elProps={{ type: 'button' }}
          icon="close"
          onClick={onDeleteBtnClick}
        />
      </div>
    </a>
  );
};

import React from 'react';

let Check24 = React.memo(() => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2017 16.0814L5.95906 11.8388L5.25195 12.5459L10.2017 17.4956L19.7476 7.9497L19.0405 7.2426L10.2017 16.0814Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default Check24;

import React, { useCallback } from 'react';

// Components
import { Slider } from '../_forms';
import Button from '../Button';

// Assets
import './ArtworkSizeSlider.css';

export let ArtworkSizeSlider = ({
  values,
  onChange,
  value,
  onCancel = () => null,
  onApply = () => null,
}) => {
  let onSliderChange = useCallback(
    (value) => {
      onChange(value);
    },
    [onChange]
  );

  let tooltipLabel = useCallback((value) => value.label, []);

  return (
    <div className="ArtworkSizeSlider">
      <div className="ArtworkSizeSlider-cancelBtn">
        <Button icon="close" onClick={onCancel}>
          <div className="tooltip tooltip--top f-caption-01">Discard</div>
        </Button>
      </div>
      <div className="ArtworkSizeSlider-inner dark-mode">
        <Slider
          values={values}
          value={value}
          onChange={onSliderChange}
          tooltipLabel={tooltipLabel}
          tooltipPosition="top"
        />
      </div>
      <div className="ArtworkSizeSlider-applyBtn">
        <Button icon="check" onClick={() => onApply(value)}>
          <div className="tooltip tooltip--top f-caption-01">Set as size</div>
        </Button>
      </div>
    </div>
  );
};

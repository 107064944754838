import React from 'react';
import { v4 as uuid } from 'uuid';

// Components
import icons from '../../_icons';

// Assets
import './SearchField.css';

export let SearchField = React.forwardRef(
  (
    { value, onChange, placeholder = 'Search artworks', inputProps = {} },
    ref
  ) => {
    let id = uuid();
    return (
      <div className="SearchField" ref={ref}>
        <label htmlFor={id} className="SearchField-icon">
          <icons.search />
        </label>
        <input
          id={id}
          type="text"
          className="SearchField-input f-body-01"
          placeholder={placeholder}
          onChange={onChange}
          value={value || ''}
          {...inputProps}
        />
      </div>
    );
  }
);

import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useLocation, useHistory, Link } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import nl2br from 'react-nl2br';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useHoverDirty } from 'react-use';

// Assets
import './ArtworkDetail.css';

// Components
import Button from '../Button';
import LazyImage from '../LazyImage';
import ArtworkFileRow from '../ArtworkFileRow';

// Hooks
import { useBackgroundState } from '../../hooks/BackgroundState';
import { useContain } from '../../hooks/useContain';
import { useErrors } from '../../hooks/Error';
import { Heading } from '../../hooks/DocumentOutline';

// Utils
import { getArtwork } from '../../utils/endpoints';
import formatArtworkSize from '../../utils/formatArtworkSize';

const NEWLINE_REGEX = /(\r\n|\r|\n)/g;

let ArtworkDetail = React.memo(() => {
  let { id } = useParams();
  let location = useLocation();
  let history = useHistory();
  let { setError } = useErrors();

  let getArtworkWithErrorHandling = (id) => {
    let ret = getArtwork(id);
    ret.catch((error) => {
      if (error.response?.status === 404) {
        history.replace('/404');
      }
    });
    return ret;
  };

  let { data: { data: artwork } = {}, status, error } = useQuery(
    ['me/artworks/:id', { id }],
    (key, vars) => getArtworkWithErrorHandling(vars.id),
    {
      staleTime: 30 * 1000,
    }
  );

  useEffect(() => {
    if (error && status !== 'loading') {
      setError({
        message: "Something went wrong trying to find your artwork's details.",
        error,
      });
    }
  }, [error, status, setError]);

  const [largeTitleRef, largeTitleInView] = useInView({
    threshold: 0.5,
  });

  let { getUrlWithBackground } = useBackgroundState();

  let { dimensions, setRef } = useContain({
    aspectRatio: artwork?.featured_image
      ? artwork.featured_image.detail.width /
        artwork.featured_image.detail.height
      : 1,
  });

  let imageRef = useRef(null);
  let isHoveringOnImage = useHoverDirty(imageRef);

  let [selectedTab, setSelectedTab] = useState('details');

  let artistOrMadeLabel =
    artwork &&
    [
      artwork.artist_name,
      artwork.date_made && format(parseISO(artwork.date_made), 'Y'),
    ]
      .filter(Boolean)
      .join(', ');

  return (
    <div className="ArtworkDetail">
      <div className="ArtworkDetail-leftCol">
        <div className="ArtworkDetail-leftColInner" ref={setRef}>
          <div
            ref={imageRef}
            style={{
              width: dimensions?.width || artwork?.featured_image.detail.width,
              height:
                dimensions?.height || artwork?.featured_image.detail.height,
            }}
          >
            {artwork?.featured_image && (
              <LazyImage
                contained
                src={artwork.featured_image.detail.url}
                placeholder={artwork.featured_image.detail.lqip_url}
                width={dimensions?.width || artwork.featured_image.detail.width}
                height={
                  dimensions?.height || artwork.featured_image.detail.height
                }
              />
            )}
          </div>
        </div>
        <AnimatePresence>
          {artwork?.date_acquired && !isHoveringOnImage && (
            <motion.div
              key="dateAcquired"
              className="ArtworkDetail-dateAcquired f-caption-02"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: 'easeInOut', duration: 0.2 }}
            >
              Acquired {format(parseISO(artwork.date_acquired), 'Y')}
            </motion.div>
          )}
          {artwork && isHoveringOnImage && (
            <motion.div
              key="imageDimensions"
              className="ArtworkDetail-imageDimensions"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: 'easeInOut', duration: 0.2 }}
            >
              {formatArtworkSize(artwork)}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="ArtworkDetail-rightCol">
        <div className="ArtworkDetail-rightColInner">
          {artwork && (
            <>
              <motion.div
                className="ArtworkDetail-areaTitle f-subhead-02"
                initial={{ opacity: 0 }}
                animate={{ opacity: largeTitleInView ? 0 : 1 }}
              >
                {artwork.title}
              </motion.div>
              <div className="ArtworkDetail-title">
                <div className="ArtworkDetail-titleInner" ref={largeTitleRef}>
                  <Heading className="f-heading-02">{artwork.title}</Heading>
                </div>
              </div>
              <div className="ArtworkDetail-metaBar">
                <div className="ArtworkDetail-meta">
                  <Button
                    label="Details"
                    secondary
                    selected={selectedTab === 'details'}
                    onClick={() => setSelectedTab('details')}
                  />
                  <Button
                    label="Story"
                    secondary
                    selected={selectedTab === 'story'}
                    onClick={() => setSelectedTab('story')}
                  />
                  <Button
                    label="Files"
                    secondary
                    selected={selectedTab === 'files'}
                    onClick={() => setSelectedTab('files')}
                  />
                </div>
                <div className="ArtworkDetail-editAction">
                  <Button
                    element={Link}
                    secondary
                    label="Edit"
                    elProps={{
                      to: getUrlWithBackground(
                        `/artworks/edit/${id}?editingFrom=${location.pathname}&initialTab=${selectedTab}`
                      ),
                    }}
                  />
                </div>
              </div>

              {selectedTab === 'details' && (
                <div className="ArtworkDetail-desc num">
                  {artistOrMadeLabel && (
                    <div className="f-body-02">{artistOrMadeLabel}</div>
                  )}
                  {artwork.description &&
                    artwork.description.split(NEWLINE_REGEX).map((line, i) => (
                      <p className="f-body-02" key={i}>
                        {line}
                      </p>
                    ))}
                </div>
              )}

              {selectedTab === 'story' &&
                (artwork?.story ? (
                  <div className="ArtworkDetail-desc num">
                    {artwork.story &&
                      artwork.story.split(NEWLINE_REGEX).map((line, i) => (
                        <p className="f-body-02" key={i}>
                          {line}
                        </p>
                      ))}
                  </div>
                ) : artwork ? (
                  <div className="ArtworkDetail-desc num">
                    <div className="f-body-02">
                      No story has been added for this artwork.
                    </div>
                  </div>
                ) : null)}

              {selectedTab === 'files' && (
                <div className="ArtworkDetail-fileList">
                  {artwork?.artwork_files?.length ? (
                    artwork.artwork_files.map((file) => (
                      <ArtworkFileRow
                        file={file}
                        key={file.id}
                        extraFileData={file}
                      />
                    ))
                  ) : (
                    <div className="ArtworkDetail-desc num">
                      <div className="f-body-02">No files have been added.</div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default ArtworkDetail;

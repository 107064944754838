import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';

// Utils
import { getRoomCrop } from '../utils/getRoomCrop';

// Constants
const ASPECT_RATIO = 2 / 1;
const MIN_ROOM_WIDTH_INCHES = 4 * 12;
const MAX_ROOM_WIDTH_INCHES = 16 * 12;
const STEP_ROOM = 12; // Go up or down by 12 inches

/**
 * Generate an array of sizes, based on the config above.
 */
function generateRoomSizes() {
  let roomSizes = [];
  for (
    let i = MIN_ROOM_WIDTH_INCHES;
    i <= MAX_ROOM_WIDTH_INCHES;
    i += STEP_ROOM
  ) {
    let widthInches = i;
    let heightInches = widthInches / ASPECT_RATIO;
    roomSizes.push({
      widthInches,
      heightInches,
    });
  }
  return roomSizes;
}

/**
 * Fallback value for the current size
 */
let getCurrentSizeFromInitialWidth = (initialWidth) => {
  for (let i = 0; i < roomSizes.length; i++) {
    let size = roomSizes[i];
    if (size.widthInches === initialWidth) {
      return i;
    }
  }
  throw new Error('The initial size was not found in the client room sizes');
};

let roomSizes = generateRoomSizes();

let getIsInBounds = (boundingBox, roomRect) => {
  if (
    parseInt(boundingBox.top, 10) < parseInt(roomRect.top, 10) ||
    parseInt(boundingBox.left, 10) < parseInt(roomRect.left, 10) ||
    parseInt(boundingBox.right, 10) > parseInt(roomRect.right, 10) ||
    parseInt(boundingBox.bottom, 10) > parseInt(roomRect.bottom, 10)
  ) {
    return false;
  } else {
    return true;
  }
};

export let useRoomResizer = ({
  boundingBox,
  initialWidth,
  placements,
  onResize,
  onFit,
}) => {
  let [currentSizeIndex, setCurrentSizeIndex] = useState(null);
  let prevPlacements = usePrevious(placements);
  let prevSizeIndex = usePrevious(currentSizeIndex); // Ignore initialWidth default values

  /**
   * Infer a default size from the `roomSizes`, based on the initial size
   */
  let initialWidthFallbackUsed = false;
  if (currentSizeIndex == null) {
    currentSizeIndex = getCurrentSizeFromInitialWidth(initialWidth);
    initialWidthFallbackUsed = true;
  }

  let currentSize = roomSizes[currentSizeIndex];

  let roomRect = useMemo(
    () => ({
      top: 0,
      right: currentSize.widthInches,
      bottom: currentSize.heightInches,
      left: 0,
    }),
    [currentSize]
  );

  let isInBounds = useMemo(() => getIsInBounds(boundingBox, roomRect), [
    boundingBox,
    roomRect,
  ]);

  let wasInBounds = usePrevious(isInBounds);

  /**
   * If the 'initial' size (i.e. data from the API) changes
   * then reset this local state
   */
  let prevInitialWidth = usePrevious(initialWidth);
  useEffect(() => {
    if (initialWidth !== prevInitialWidth) {
      setCurrentSizeIndex(null);
    }
  }, [initialWidth, prevInitialWidth]);

  /**
   * If the placements have just gone in bounds
   * (i.e. user has dragged the artworks inside the room)
   * then trigger the onFit callback.
   */
  useEffect(() => {
    let cameInBounds = isInBounds === true && wasInBounds === false;
    let placementsChanged =
      placements !== prevPlacements && prevPlacements != null;
    let roomSizeChanged =
      currentSizeIndex !== prevSizeIndex && !initialWidthFallbackUsed;

    if ((cameInBounds || roomSizeChanged) && placementsChanged) {
      onFit({
        isInBounds,
        roomSize: roomSizes[currentSizeIndex],
      });
    }
  }, [
    placements,
    currentSizeIndex,
    prevSizeIndex,
    prevPlacements,
    initialWidthFallbackUsed,
    onFit,
    isInBounds,
    wasInBounds,
  ]);

  /**
   * User can choose a new index from the room sizes array
   */
  let setRoomSize = useCallback(
    (roomSize) => {
      let idx = roomSizes.indexOf(roomSize);
      setCurrentSizeIndex(idx);

      // Reposition the artworks immediately, to avoid any incorrect bounds calculations.
      let roomCrop = getRoomCrop(roomSize, currentSize);
      onResize({
        roomCrop,
      });
    },
    [currentSize, onResize]
  );

  return useMemo(
    () => ({
      currentSize: roomSizes[currentSizeIndex],
      currentSizeIndex,
      roomSizes,
      setRoomSize,
      isInBounds,
    }),
    [currentSizeIndex, setRoomSize, isInBounds]
  );
};

import React, { useCallback } from 'react';
import { parseISO, format } from 'date-fns';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';

// Components
import LazyImage from '../LazyImage';
import Button from '../Button';

// Assets
import './RoomsIndexRow.css';

// Hooks
import { ChildPresence, useChildPresence } from './hooks/useChildPresence';
import { useTrackIntersections } from './hooks/useTrackIntersections';

let RowThumb = React.memo(({ placement }) => {
  let { row_thumb: thumb } = placement.artwork.featured_image;
  let { setRef } = useChildPresence();
  return (
    <div ref={setRef}>
      <LazyImage
        width={thumb.width}
        height={thumb.height}
        src={thumb.url}
        alt={placement.artwork.title}
      />
    </div>
  );
});

export let RoomsIndexRow = React.memo(({ room, onDeleteClick }) => {
  let onDeleteBtnClick = useCallback(
    (evt) => {
      evt.preventDefault();
      onDeleteClick({
        room,
      });
    },
    [onDeleteClick, room]
  );

  let placementsWithImages = room.artwork_placements.filter((val) =>
    Boolean(val.artwork.featured_image)
  );

  let {
    setRef,
    observeElement,
    unobserveElement,
    intersectedElements,
  } = useTrackIntersections();

  let count = intersectedElements.length;

  return (
    <Link className="RoomsIndexRow" to={`/rooms/${room.id}`}>
      <div className="RoomsIndexRow-title">
        <div className="f-subhead-01">{room.title || 'New room'}</div>
      </div>
      <div className="RoomsIndexRow-meta">
        <div className="RoomsIndexRow-metaDefault num f-body-01">
          {room.updated_at && format(parseISO(room.updated_at), 'Y-MM-dd')}
        </div>
        <div className="RoomsIndexRow-metaHover num f-body-01">
          {placementsWithImages.length}{' '}
          {pluralize('artwork', placementsWithImages.length)}
        </div>
      </div>
      <div className="RoomsIndexRow-thumbs">
        <div className="RoomsIndexRow-thumbsInner" ref={setRef}>
          <ChildPresence
            onElementAdd={observeElement}
            onElementRemove={unobserveElement}
          >
            {placementsWithImages.map((placement) => {
              return <RowThumb placement={placement} key={placement.id} />;
            })}
          </ChildPresence>
        </div>
        <div className="RoomsIndexRow-deleteBtn">
          <Button
            secondary
            elProps={{ type: 'button' }}
            icon="close"
            onClick={onDeleteBtnClick}
          />
        </div>
        {count > 0 && placementsWithImages.length - count > 0 && (
          <div className="RoomsIndexRow-extraCount">
            +{placementsWithImages.length - count}
          </div>
        )}
      </div>
    </Link>
  );
});

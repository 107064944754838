import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { useHoverDirty } from 'react-use';
import composeRefs from '@seznam/compose-react-refs';

// Assets
import './ScaleSlider.css';

// Hooks
import { useRect } from '../../hooks/useRect';
import { useMouse } from '../../hooks/Mouse';

// Utils
import { formatRoomSize } from '../../utils/formatRoomSize';
import closest from '../../utils/closest';

let posToTooltipDict = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
};

let ScaleSlider = React.memo(
  ({
    values,
    value,
    vertical = false,
    onHoverChange,
    onChange,
    isInBounds,
    position,
  }) => {
    let currentValue = values[value];
    let { client } = useMouse();
    let { [vertical ? 'y' : 'x']: clientX } = client;
    let ref = useRef(null);
    let { rect, setRef } = useRect();
    let [mouseDown, setMouseDown] = useState(false);
    let [meterX, setMeterX] = useState(0);
    let isHovering = useHoverDirty(ref);

    let normalizedClientX = clientX - (vertical ? rect.top : rect.left);

    let notches = useMemo(() => {
      let notches = new Map();
      for (let i = 0; i < values.length; i++) {
        let value = values[i];
        if (!value.isBlocking) {
          notches.set(values[i], {
            value: values[i],
          });
        }
      }
      return notches;
    }, [values]);

    let notchCount = notches.size;
    let notchGapPx = 100 / (notchCount - 1);
    let notchesWithPixels = useMemo(() => {
      let i = 0;
      let map = new Map();
      for (let notch of notches.values()) {
        let px = notchGapPx * i;
        map.set(notch.value, {
          ...notch,
          px,
        });
        i++;
      }
      return map;
    }, [notches, notchGapPx]);

    let notchesByPixels = useMemo(() => {
      let map = new Map();
      for (let notch of notchesWithPixels.values()) {
        map.set(notch.px, notch);
      }
      return map;
    }, [notchesWithPixels]);

    let notchPixelsArr = useMemo(() => {
      return Array.from(notchesWithPixels.values()).map((value) => value.px);
    }, [notchesWithPixels]);

    let px = currentValue ? notchesWithPixels.get(currentValue)?.px : 0;

    let onPointerDown = useCallback((evt) => {
      evt.currentTarget.setPointerCapture(evt.pointerId);
      setMouseDown(true);
    }, []);

    let onPointerCancel = useCallback((evt) => {
      evt.currentTarget.releasePointerCapture(evt.pointerId);
      setMouseDown(false);
    }, []);

    let onWindowMouseUp = useCallback(() => {
      setMouseDown(false);
    }, []);

    useEffect(() => {
      if (mouseDown) {
        window.addEventListener('mouseup', onWindowMouseUp);
      }
      return () => {
        window.removeEventListener('mouseup', onWindowMouseUp);
      };
    }, [mouseDown, onWindowMouseUp]);

    useEffect(() => {
      if (mouseDown) {
        let closestPx = closest(notchPixelsArr, normalizedClientX - meterX);
        let value = notchesByPixels.get(closestPx);
        if (value !== currentValue) {
          onChange(value);
        }
      }
    }, [
      notchPixelsArr,
      notchesByPixels,
      normalizedClientX,
      currentValue,
      meterX,
      mouseDown,
      onChange,
    ]);

    let rectUnit = vertical ? rect.height : rect.width;

    let refreshMeterX = useCallback(() => {
      let newX = normalizedClientX - px;
      newX = Math.max(0, newX);
      newX = Math.min(rectUnit - 100, newX);
      setMeterX(newX);
    }, [normalizedClientX, px, rectUnit]);

    useEffect(() => {
      if (!mouseDown) {
        refreshMeterX();
      }
    }, [mouseDown, refreshMeterX]);

    useEffect(() => {
      onHoverChange(isHovering);
    }, [isHovering, onHoverChange]);

    let x = meterX;

    return (
      <motion.div
        ref={composeRefs(ref, setRef)}
        className={cx('ScaleSlider', {
          'ScaleSlider--vertical': vertical,
          'ScaleSlider--warning': !isInBounds,
        })}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: isHovering ? 1 : 0,
        }}
      >
        <div className="ScaleSlider-inner">
          <motion.div
            className="ScaleSlider-meter"
            animate={{
              [vertical ? 'y' : 'x']: x,
            }}
            transition={{
              type: 'spring',
              stiffness: 1000,
              damping: 100,
            }}
          >
            <motion.div
              className="ScaleSlider-handleArea"
              onPointerDown={onPointerDown}
              onPointerCancel={onPointerCancel}
              animate={{
                [vertical ? 'top' : 'left']: px,
              }}
              transition={{
                type: 'spring',
                stiffness: 1000,
                damping: 1000,
              }}
            >
              <motion.div className="ScaleSlider-handle" />
              <motion.div
                className={`tooltip tooltip--${posToTooltipDict[position]} f-caption-01 num`}
                initial={false}
                animate={{ opacity: mouseDown ? 1 : 0 }}
                style={{
                  transition: 'none',
                }}
              >
                {formatRoomSize(currentValue.roomSize)}
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </motion.div>
    );
  }
);

export default ScaleSlider;

import React, { useContext, useEffect, useState } from 'react';

let RoomPreviewTransitionContext = React.createContext(null);

export let RoomPreviewTransitionProvider = (props) => {
  let [isTransitioning, setTransitioning] = useState(false);
  let [isIntending, setIntending] = useState(false);

  let startTransition = () => {
    setTransitioning(true);
  };

  useEffect(() => {
    if (isTransitioning) {
      setTimeout(() => {
        setTransitioning(false);
        setIntending(false);
      }, 2000);

      return () => {
        setTransitioning(false);
        setIntending(false);
      };
    }
  }, [isTransitioning]);

  return (
    <RoomPreviewTransitionContext.Provider
      value={{ isTransitioning, startTransition, isIntending, setIntending }}
      {...props}
    />
  );
};

export let useRoomPreviewTransition = () => {
  let ctx = useContext(RoomPreviewTransitionContext);
  return ctx;
};

import roundQuarter from './roundQuarter';

let decimalsToFractions = new Map([
  [0.25, '¼'],
  [0.5, '½'],
  [0.75, '¾'],
]);

export default function formatArtworkSize(artwork) {
  // Width
  let width = roundQuarter(artwork.width_inches);
  let widthWhole = Math.floor(width);
  let widthRemainder = width % widthWhole;
  let widthFraction = widthRemainder
    ? ` ${decimalsToFractions.get(widthRemainder)}`
    : '';

  // Length (a.k.a Height)
  let length = roundQuarter(artwork.height_inches);
  let lengthWhole = Math.floor(length);
  let lengthRemainder = length % lengthWhole;
  let lengthFraction = lengthRemainder
    ? ` ${decimalsToFractions.get(lengthRemainder)}`
    : '';

  let ret = `${lengthWhole}${lengthFraction} × ${widthWhole}${widthFraction}`;

  // Depth
  if (artwork.depth_inches) {
    let depth = roundQuarter(artwork.depth_inches);
    let depthWhole = Math.floor(depth);
    let depthRemainder = depth % depthWhole;
    let depthFraction = depthRemainder
      ? ` ${decimalsToFractions.get(depthRemainder)}`
      : '';

    ret += ` × ${depthWhole}${depthFraction}`;
  }

  ret += '″';

  return ret;
}

import { useCallback, useRef, useState } from 'react';

export let useTrackIntersections = () => {
  let ref = useRef(null);
  let observerRef = useRef(null);
  let targetQueueRef = useRef(new Map());
  let [intersectedElements, setIntersectedElements] = useState([]);

  let resetIntersects = useCallback(() => {
    setIntersectedElements([]);
  }, []);

  let handleIntersect = useCallback((entry) => {
    setIntersectedElements((state) => {
      if (!state.includes(entry.target)) {
        return [...state, entry.target];
      }
      return state;
    });
  }, []);

  let handleNonIntersect = useCallback((entry) => {
    setIntersectedElements((state) => {
      if (state.includes(entry.target)) {
        return state.filter((v) => v !== entry.target);
      }
      return state;
    });
  }, []);

  let setRef = useCallback(
    (node) => {
      if (ref.current) {
        if (observerRef.current) {
          observerRef.current.disconnect();
          observerRef.current = null;
          resetIntersects();
        }
      }
      if (node) {
        observerRef.current = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.intersectionRatio > 0.5) {
                handleIntersect(entry);
              } else {
                handleNonIntersect(entry);
              }
            });
          },
          {
            root: node,
            rootMargin: '0px',
            threshold: 0.5,
          }
        );
        for (let el of targetQueueRef.current.keys()) {
          observerRef.current.observe(el);
        }
        targetQueueRef.current = new Map();
      }
      ref.current = node;
    },
    [handleIntersect, handleNonIntersect, resetIntersects]
  );

  let observeElement = useCallback((el) => {
    if (observerRef.current) {
      observerRef.current.unobserve(el);
    }
    if (!targetQueueRef.current.has(el)) {
      targetQueueRef.current.set(el, true);
    }
  }, []);

  let unobserveElement = useCallback((el) => {
    if (observerRef.current) {
      observerRef.current.unobserve(el);
    }
    if (targetQueueRef.current.has(el)) {
      targetQueueRef.current.delete(el);
    }
  }, []);

  return {
    setRef,
    observeElement,
    unobserveElement,
    intersectedElements,
  };
};

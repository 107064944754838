import { useCallback, useMemo, useState } from 'react';

export let useCallQueue = () => {
  let [callQueue, setCallQueue] = useState([]);

  let triggerNextCall = useCallback(
    (data) => {
      let call = callQueue[0];

      setCallQueue([
        {
          ...callQueue[0],
          status: 'loading',
        },
        ...callQueue.slice(1),
      ]);

      call
        .fn(data)
        .then((res) => {
          // Remove this call from the front of the queue, now it is complete.
          setCallQueue((callQueue) => callQueue.slice(1));
        })
        .catch((err) => {
          console.error('Call Queue error', err);
        });
    },
    [callQueue]
  );

  let enqueueCall = useCallback(
    (name, data, fn, initial = false) => {
      let newCallQueue = [
        ...callQueue,
        {
          name,
          data,
          fn,
          status: 'queued',
          initial,
        },
      ];
      setCallQueue(newCallQueue);
    },
    [callQueue]
  );

  let hook = useMemo(
    () => ({
      callQueue,
      triggerNextCall,
      enqueueCall,
    }),
    [callQueue, triggerNextCall, enqueueCall]
  );

  return hook;
};

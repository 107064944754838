import React from 'react';

export default function ArrowLeft24() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 12H6V13H18V12Z" fill="currentColor" />
      <path
        d="M10.75 17.1L6.15 12.5L10.75 7.90002"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import { motion } from 'framer-motion';
import axios from 'axios';
// import { Link } from 'react-router-dom';

// Assets
import './LoginScreen.css';

// Components
import Button from '../Button';
import { TextField } from '../_forms';
import {
  ConfirmDialog,
  ConfirmDialogPortal,
} from '../ConfirmDialog/ConfirmDialog';

// Hooks
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useErrors } from '../../hooks/Error';
import { Heading } from '../../hooks/DocumentOutline';
import { useRandomColor } from '../../hooks/useRandomColor';

let loginSchema = Yup.object().shape({
  email: Yup.string().email().required().label('E-mail address'),
  password: Yup.string().required().label('Password'),
});

let forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required().label('E-mail address'),
});

let logo = require('../../images/logo.svg');

export default function LoginScreen() {
  let { login } = useAuth();
  let { setError } = useErrors();
  let [isLoading, setLoading] = useState(false);
  let [forgotPasswordDialogVisible, setForgotPasswordDialogVisible] = useState(
    false
  );
  let { setToast } = useToast();

  let formik = useFormik({
    validationSchema: loginSchema,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async () => {
      setLoading(true);
      try {
        await login({
          email: formik.values.email,
          password: formik.values.password,
        });
      } catch (err) {
        setLoading(false);
        formik.setSubmitting(false);
        if (err.response) {
          Object.keys(err.response.data?.errors || {}).forEach((field) => {
            let errors = err.response.data.errors[field];
            let error = Array.isArray(errors) ? errors[0] : errors;
            formik.setFieldError('general', error);
          });
        }
      }
    },
  });

  let isFormInvalid = Boolean(Object.keys(formik.errors).length);

  let firstError =
    isFormInvalid && formik.submitCount > 0
      ? Object.values(formik.errors)[0]
      : null;

  let onForgotPasswordClick = useCallback(() => {
    setForgotPasswordDialogVisible(true);
  }, []);

  let forgotPasswordFormik = useFormik({
    validationSchema: forgotPasswordSchema,
    enableReinitialize: true,
    initialValues: {
      email: formik.values.email,
    },
    onSubmit: async () => {
      try {
        await axios.get('/sanctum/csrf-cookie');
        await axios.post('/password/email', {
          email: forgotPasswordFormik.values.email,
        });
      } catch (err) {
        if (err.response) {
          for (let field of Object.keys(err.response.data?.errors)) {
            let errors = err.response.data.errors[field];
            let error = Array.isArray(errors) ? errors[0] : errors;
            forgotPasswordFormik.setFieldError('general', error);
            break;
          }
        } else {
          setError({
            message:
              'Something went wrong when sending the forgotten password email.',
          });
        }
        return;
      }
      setForgotPasswordDialogVisible(false);
      forgotPasswordFormik.setSubmitting(false);
      setToast({
        message: `A reset email has been sent to ${forgotPasswordFormik.values.email}`,
      });
    },
  });

  let isForgotPasswordFormInvalid = Boolean(
    Object.keys(forgotPasswordFormik.errors).length
  );

  let firstForgotPasswordError =
    isForgotPasswordFormInvalid && forgotPasswordFormik.submitCount > 0
      ? Object.values(forgotPasswordFormik.errors)[0]
      : null;

  let onForgotPasswordDialogConfirm = useCallback(
    (evt) => {
      forgotPasswordFormik.handleSubmit(evt);
    },
    [forgotPasswordFormik]
  );

  let onForgotPasswordDialogCancel = useCallback(() => {
    setForgotPasswordDialogVisible(false);
  }, []);

  let { color: randomColor, image: randomImage } = useRandomColor();

  return (
    <div
      className={cx('LoginScreen', { 'is-loading': isLoading })}
      style={{
        '--theme-input-hover':
          randomColor && !isLoading
            ? `var(--color-brand-${randomColor}-15)`
            : undefined,
        '--theme-base-bg': randomColor
          ? `var(--color-brand-${randomColor}-10)`
          : undefined,
      }}
    >
      <div className="LoginScreen-imageCol">
        <img src={randomImage} alt="" className="LoginScreen-imageBg" />
        <img
          src={logo}
          alt="Rookery - An art recollection tool"
          className="LoginScreen-logo"
          width={368}
          height={75}
        />
      </div>
      <div className="LoginScreen-formCol">
        <div className="LoginScreen-loader"></div>
        <motion.div
          className="LoginScreen-title"
          initial={false}
          animate={{ opacity: isLoading ? 0 : 1 }}
        >
          <Heading className="f-heading-01">Sign in</Heading>
        </motion.div>
        <form className="LoginScreen-form" onSubmit={formik.handleSubmit}>
          <motion.div
            className="LoginScreen-formInner"
            initial={false}
            animate={{ opacity: isLoading ? 0 : 1 }}
          >
            <TextField
              autoFocus
              placeholder="E-mail address*"
              name="email"
              autoComplete="email"
              onChange={formik.handleChange}
              value={formik.values.email || ''}
              formik={formik}
            />
            <TextField
              placeholder="Password*"
              name="password"
              type="password"
              autoComplete="current-password"
              onChange={formik.handleChange}
              value={formik.values.password || ''}
              formik={formik}
              note={
                <button
                  type="button"
                  className="link"
                  onClick={onForgotPasswordClick}
                >
                  Forgot password?
                </button>
              }
            />
            <div className="LoginScreen-footer">
              <div
                className={cx('f-caption-01 LoginScreen-formNote', {
                  'is-error': Boolean(formik.errors.general || firstError),
                })}
              >
                {formik.errors.general || firstError || '*Required'}
              </div>
              <Button
                tabIndex="0"
                label={formik.isSubmitting ? 'Signing in...' : 'Sign in'}
                elProps={{ type: 'submit' }}
                error={
                  isFormInvalid &&
                  !formik.isSubmitting &&
                  formik.submitCount > 0
                }
                fauxDisabled={
                  !formik.dirty || isFormInvalid || formik.isSubmitting
                }
              />
            </div>
          </motion.div>
        </form>
      </div>

      {forgotPasswordDialogVisible && (
        <ConfirmDialogPortal>
          <ConfirmDialog
            onConfirm={onForgotPasswordDialogConfirm}
            onCancel={onForgotPasswordDialogCancel}
            confirmLabel={
              forgotPasswordFormik.isSubmitting ? 'Sending...' : 'Send'
            }
            isConfirmDisabled={forgotPasswordFormik.isSubmitting}
            title="Forgot password?"
            message={
              <>
                <p>Send a Password Reset link to:</p>
                <div>
                  <TextField
                    type="email"
                    autoFocus
                    value={forgotPasswordFormik.values.email || ''}
                    placeholder="E-mail address"
                    onChange={forgotPasswordFormik.handleChange}
                    name="email"
                    formik={forgotPasswordFormik}
                  />
                </div>
              </>
            }
            footerNote={
              <div
                className={cx({
                  'is-error': Boolean(
                    forgotPasswordFormik.errors.general ||
                      firstForgotPasswordError
                  ),
                })}
              >
                {forgotPasswordFormik.errors.general ||
                  firstForgotPasswordError ||
                  ''}
              </div>
            }
          />
        </ConfirmDialogPortal>
      )}
    </div>
  );
}

import React from 'react';

export default function List24() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H24V24H0V0Z" />
      <path
        d="M10.5 13.5H6.5V17.5H10.5V13.5Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M10.5 6.5H6.5V10.5H10.5V6.5Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path d="M18 6H13V7H18V6Z" fill="black" />
      <path d="M18 8H13V9H18V8Z" fill="black" />
      <path d="M18 10H13V11H18V10Z" fill="black" />
      <path d="M18 13H13V14H18V13Z" fill="black" />
      <path d="M18 15H13V16H18V15Z" fill="black" />
      <path d="M18 17H13V18H18V17Z" fill="black" />
    </svg>
  );
}

import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import axios from 'axios';
import qs from 'qs';
import piexifjs from 'piexifjs';

// Assets
import './ImageUploadZone.css';

// Components
import Button from '../Button';

// Hooks
import { useErrors } from '../../hooks/Error';
import { Heading } from '../../hooks/DocumentOutline';
import { usePreloads } from '../ImagePreloader/hooks/usePreload';

// Utils
import sleeper from '../../utils/sleeper';
import uploadFileToS3 from '../../utils/uploadFileToS3';

// Constants
const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/png'];
const MAX_FILE_SIZE_BYTES =
  (process.env.REACT_APP_MAX_FILE_SIZE_MB || 20) * 1000000;
const MIN_FILE_DIMENSION_PX =
  process.env.REACT_APP_MIN_FILE_DIMENSION_PX || 100;
const LOADING_STATUS_PENDING = 'pending';
const LOADING_STATUS_UPLOADING = 'uploading';
const BASE64_MARKER = ';base64,';

export default function ImageUploadZone({
  onPreviewChange = () => null,
  onComplete = () => null,
  onOpen = () => null,
  uploadBtnRef,
}) {
  let [validationError, setValidationError] = useState(null);
  let { setError } = useErrors();
  let [loadingStatus, setLoading] = useState(null);
  let [preloadableImages, setPreloadableImages] = useState([]);

  usePreloads(preloadableImages);

  let s3SignAndUpload = useCallback(async (file, size) => {
    let data;
    try {
      data = (
        await axios.get(
          '/api/s3/presign?' +
            qs.stringify({
              type: file.type,
              name: file.name,
            })
        )
      ).data;
    } catch (err) {
      console.error('Presign failed: ', err);
      throw err;
    }

    await uploadFileToS3(
      data.formInputs,
      data.formAttributes.action,
      data.key,
      file
    );

    // Gets the signed URLs ASAP
    // so we can prefetch while the user is filling out the form
    let artworkImage = await axios.get(
      `/api/me/artwork_images/detail?s3_path=${data.key}&width_px=${size.width}&height_px=${size.height}`
    );

    if (artworkImage?.data) {
      setPreloadableImages([
        artworkImage.data.detail.lqip_url,
        artworkImage.data.detail.url,
        artworkImage.data.square_thumb.lqip_url,
        artworkImage.data.square_thumb.url,
      ]);
    }

    return {
      key: data.key,
    };
  }, []);

  /**
   * Validate the chosen image, and upload it if it's valid.
   */
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setValidationError(null);
      setLoading(LOADING_STATUS_PENDING);
      onPreviewChange(null);
      onOpen(true);

      setTimeout(() => {
        if (rejectedFiles?.length) {
          if (!ACCEPTED_FILE_TYPES.includes(rejectedFiles[0].type)) {
            setLoading(false);
            setValidationError({
              message: 'Please use a correct file format',
            });
          } else if (rejectedFiles[0].size > MAX_FILE_SIZE_BYTES) {
            setLoading(false);
            setValidationError({
              message: 'Please reduce the size of your image',
            });
          } else {
            setLoading(false);
            setValidationError({
              message: 'This file was invalid',
            });
          }
          return;
        }

        // No known way of this being the case
        // but being defensive just in case.
        if (!acceptedFiles.length) {
          setLoading(false);
          setValidationError({
            message: 'No file was uploaded',
          });
        }

        const reader = new FileReader();

        reader.onload = () => {
          const img = new Image();
          let acceptedFile = acceptedFiles[0];

          if (acceptedFile.type === 'image/jpeg') {
            let exifData = piexifjs.load(reader.result);
            exifData.GPS = {};

            const scrubbed = piexifjs.insert(
              piexifjs.dump(exifData),
              reader.result
            );

            img.src = scrubbed;

            const mime = scrubbed.split(BASE64_MARKER)[0].split(':')[1];
            const bytes = atob(scrubbed.split(BASE64_MARKER)[1]);
            let writer = new Uint8Array(new ArrayBuffer(bytes.length));

            for (var i = 0; i < bytes.length; i++) {
              writer[i] = bytes.charCodeAt(i);
            }

            acceptedFile = new File([writer.buffer], acceptedFile.name, {
              type: mime,
            });
          } else {
            img.src = reader.result;
          }

          img.onload = () => {
            // Check the dimensions are large enough...
            if (
              img.naturalWidth < MIN_FILE_DIMENSION_PX &&
              img.naturalHeight < MIN_FILE_DIMENSION_PX
            ) {
              setLoading(false);
              setValidationError({
                message: 'The file dimensions are not large enough.',
              });
            } else {
              // Image passed validation, so set as preview
              // and upload the image to ImageKit.io

              // Used for the preview
              onPreviewChange({
                src: reader.result,
                width: img.naturalWidth,
                height: img.naturalHeight,
              });

              setLoading(LOADING_STATUS_UPLOADING);

              /**
               * Ensure the uploading state is shown for at least 2 seconds
               */
              Promise.all([
                s3SignAndUpload(acceptedFile, {
                  width: img.naturalWidth,
                  height: img.naturalHeight,
                }),
                sleeper(2000),
              ])
                .then(([res]) => {
                  setLoading(false);
                  onComplete({
                    key: res.key,
                    width: img.naturalWidth,
                    height: img.naturalHeight,
                  });
                })
                .catch((err) => {
                  console.error('Upload error', err);
                  setLoading(false);
                  onPreviewChange(null);
                  setError({
                    message: 'Something went wrong when uploading your image.',
                    error: err,
                  });
                });
            }
          };
        };

        reader.readAsDataURL(acceptedFiles[0]);
      }, 100);
    },
    [setError, onComplete, onPreviewChange, onOpen, s3SignAndUpload]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILE_TYPES.join(', '),
    maxSize: MAX_FILE_SIZE_BYTES,
    multiple: false,
    noClick: true,
  });

  /**
   * Change the main title
   * with a unique key for every point we want to fade it out & in again...
   */
  let title = {};
  if (loadingStatus === LOADING_STATUS_UPLOADING) {
    title.key = 'uploading';
    title.text = (
      <>
        Your <br /> image is uploading...
      </>
    );
  } else if (loadingStatus === LOADING_STATUS_PENDING) {
    title.key = 'loading';
    title.text = '';
  } else if (validationError?.message) {
    title.key = validationError?.message;
    title.text = validationError?.message;
  } else {
    title.key = 'default';
    let isActive = isDragActive; // || acceptedFiles?.length || rejectedFiles?.length;
    title.text = (isActive ? 'Drop' : 'Drag') + ' your artwork here';
  }

  return (
    <div
      className={cx('ImageUploadZone', {
        'is-dragging': isDragActive,
        'is-error': validationError?.message,
        'is-loading': loadingStatus,
        'is-uploading': loadingStatus === LOADING_STATUS_UPLOADING,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps({})} />
      <div className="ImageUploadZone-col ImageUploadZone-leftCol">
        <div className="ImageUploadZone-dropPlaceholder" onClick={open}></div>
      </div>
      <div className="ImageUploadZone-col ImageUploadZone-rightCol">
        <div className="ImageUploadZone-title">
          <AnimatePresence exitBeforeEnter>
            {title?.key && (
              <motion.div
                key={title.key}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Heading className="f-heading-01">{title.text}</Heading>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="ImageUploadZone-footer">
          <div className="ImageUploadZone-uploadNote f-caption-01">
            JPEG or PNG, {MAX_FILE_SIZE_BYTES / 1000000}MB max.
          </div>

          {loadingStatus !== LOADING_STATUS_UPLOADING &&
            loadingStatus !== LOADING_STATUS_PENDING && (
              <div className="ImageUploadZone-uploadBtn">
                <Button
                  label="Upload image"
                  onClick={open}
                  ref={uploadBtnRef}
                />
              </div>
            )}

          <div className="ImageUploadZone-loader" />
        </div>
      </div>
    </div>
  );
}

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { format, startOfYear, setYear } from 'date-fns';
import { queryCache, useMutation } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import cx from 'classnames';

// Assets
import './ArtworkForm.css';

// Components
import { TextField } from '../_forms';
import Button from '../Button';
import ImageUploadZone from '../ImageUploadZone';
import LazyImage from '../LazyImage';
import {
  ConfirmDialog,
  ConfirmDialogPortal,
} from '../ConfirmDialog/ConfirmDialog';
import ArtworkSizeEstimator from '../ArtworkSizeEstimator';
import FileUploadZone from '../FileUploadZone';

// Hooks
import { useToast } from '../../hooks/Toast';
import { useDetailPanel } from '../DetailPanel/DetailPanel';
import { useContain } from '../../hooks/useContain';
import { useErrors } from '../../hooks/Error';
import { useBackgroundState } from '../../hooks/BackgroundState';
import { useRoomCacheBuster } from '../../hooks/RoomCacheBuster';
import { Heading } from '../../hooks/DocumentOutline';

// Utils
import { getArtwork, addArtwork, updateArtwork } from '../../utils/endpoints';
import { formatDateForYearInput } from '../../utils/dates';
import sleeper from '../../utils/sleeper';

// Config
const MAX_DESCRIPTION_LENGTH = 500;
const MAX_STORY_LENGTH = 2000;

let artworkFormSchema = Yup.object().shape({
  title: Yup.string().required().label('Title'),
  date_made: Yup.number().label('Created'),
  date_acquired: Yup.number().label('Acquired'),
  width_inches: Yup.number().label('Width (inches)').required(),
  height_inches: Yup.number().label('Length (inches)').required(),
  depth_inches: Yup.number().label('Depth (inches)').nullable(),
  description: Yup.string()
    .nullable()
    .label('Observations')
    .max(MAX_DESCRIPTION_LENGTH),
  story: Yup.string().nullable().label('Story').max(MAX_STORY_LENGTH),
  artist_name: Yup.string().label('Artist').nullable(),
  artwork_files: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().required(),
        s3_path: Yup.string().required(),
      })
    )
    .defined()
    .label('Artwork files'),
  featured_image: Yup.object()
    .label('Primary image')
    .shape({
      s3_path: Yup.string()
        // S3 image should be required if the ImageKit path isn't being used
        // (backwards compatibility)
        .nullable()
        .test(function (value) {
          let { ik_id } = this.parent;
          if (!ik_id) {
            return Boolean(value);
          } else {
            return true;
          }
        }),
      // ImageKit ID should be required if the S3 path isn't being used
      // (backwards compatibility)
      ik_id: Yup.string()
        .nullable()
        .test(function (value) {
          let { s3_path } = this.parent;
          if (!s3_path) {
            return Boolean(value);
          } else {
            return true;
          }
        }),
      ik_path: Yup.string()
        .nullable()
        .test(function (value) {
          let { s3_path } = this.parent;
          if (!s3_path) {
            return Boolean(value);
          } else {
            return true;
          }
        }),
      width_px: Yup.number(),
      height_px: Yup.number(),
    })
    .nullable(),
});

let initialArtworkData = {
  title: '',
  description: '',
  date_made: '',
  date_acquired: '',
  story: '',
  width_inches: '',
  depth_inches: '',
  height_inches: '',
  artist_name: '',
  featured_image: null,
  artwork_files: [],
};

let nullValuesToEmptyStrings = (data) => {
  let ret = {};
  for (let [key, value] of Object.entries(data)) {
    ret[key] = value || '';
  }
  return ret;
};

let formatArtworkAsFormData = (data) =>
  data
    ? nullValuesToEmptyStrings({
        ...data,
        date_made: formatDateForYearInput(data.date_made),
        date_acquired: formatDateForYearInput(data.date_acquired),
        artwork_files: data.artwork_files.map((file) => ({
          id: file.id,
          s3_path: file.s3_path,
          name: file.name,
        })),
        featured_image: data.featured_image
          ? {
              id: data.featured_image.id,
              s3_path: data.featured_image.s3_path,
              ik_id: data.featured_image.ik_id,
              ik_path: data.featured_image.ik_path,
              width_px: data.featured_image.width_px,
              height_px: data.featured_image.height_px,
            }
          : null,
      })
    : null;

export default function ArtworkForm({ id }) {
  let params = useParams();
  id = id === undefined ? params.id : id;
  let history = useHistory();
  let location = useLocation();
  let { getUrlWithBackground } = useBackgroundState();

  let [editingImage, setEditingImage] = useState(false);

  let [artworkData, setArtworkData] = useState(initialArtworkData);
  let artworkFormData = useMemo(() => formatArtworkAsFormData(artworkData), [
    artworkData,
  ]);

  let { setToast } = useToast();
  let { setError } = useErrors();
  let { bustRoomCache } = useRoomCacheBuster();

  let [mutate] = useMutation(id ? updateArtwork : addArtwork);

  // We can't use mutate's status directly, as there are other
  // async functions that are part of the visual 'isSaving' state
  let [isSaving, setSaving] = useState(false);

  let detailPanel = useDetailPanel();
  let {
    isRequestingClose = false,
    preventClose,
    cancelRequestClose,
    closePanel,
  } = detailPanel || {};

  let [isClosing, setClosing] = useState(false);

  let formik = useFormik({
    enableReinitialize: true,
    initialValues: artworkFormData,
    validationSchema: artworkFormSchema,
    onSubmit: async () => {
      setSaving(true);

      let date_made = formik.values.date_made
        ? format(
            startOfYear(setYear(new Date(), formik.values.date_made)),
            'yyyy-MM-dd'
          )
        : null;

      let date_acquired = formik.values.date_acquired
        ? format(
            startOfYear(setYear(new Date(), formik.values.date_acquired)),
            'yyyy-MM-dd'
          )
        : null;

      let handleError = (error) => {
        console.error('error', error);
        setError({
          message: 'Your artwork could not be saved.',
          error,
        });
        formik.setSubmitting(false);
        formik.setTouched(false);
        setSaving(false);
      };

      try {
        await mutate(
          {
            // 'Hidden' fields
            id: formik.values.id,
            sync: formik.values.sync,
            featured_image: formik.values.featured_image,
            artwork_files: formik.values.artwork_files,
            // Directly editable fields
            title: formik.values.title,
            description: formik.values.description,
            date_made,
            date_acquired,
            artist_name: formik.values.artist_name,
            width_inches: formik.values.width_inches,
            height_inches: formik.values.height_inches,
            depth_inches: formik.values.depth_inches,
            story: formik.values.story,
          },
          {
            throwOnError: true,
          }
        );
      } catch (err) {
        handleError(err);
        return;
      }

      bustRoomCache();

      let fetch = [
        queryCache.removeQueries('me/rooms/:id', {
          force: true,
          exact: false,
        }),
        queryCache.removeQueries('me/artworks', {
          force: true,
          exact: false,
        }),
      ];

      if (id) {
        fetch.push(
          queryCache.refetchQueries(['me/artworks/:id', { id }], {
            exact: true,
            force: true,
          })
        );
      }

      Promise.all([sleeper(2000), fetch])
        .then(() => {
          setToast({
            message: `Your artwork has been ${id ? 'updated' : 'added'}.`,
          });

          setClosing(true);
          formik.setSubmitting(false);
          formik.setTouched(false);
          document
            .getElementById('AppMain')
            .scrollTo({ top: 0, behavior: 'smooth' });

          if (detailPanel && editingFrom && !isRequestingClose) {
            let url = getUrlWithBackground(editingFrom);
            history.push(url);
          } else if (detailPanel) {
            closePanel();
          } else {
            history.push('/artworks');
          }
        })
        .catch((err) => {
          handleError(err);
        });
    },
  });

  /**
   * Get the artwork by :id param
   */
  let {
    data: { data: artwork } = {},
    status: artworkStatus,
    error,
  } = useQuery(id ? ['me/artworks/:id', { id }] : null, (key, vars) =>
    getArtwork(vars.id)
  );

  useEffect(() => {
    if (id && error && artworkStatus !== 'loading') {
      setError({
        message: "Something went wrong trying to find your artwork's details.",
        error,
      });
    }
  }, [id, error, artworkStatus, setError]);

  /**
   * Update `artworkData` whenever the sync value changes
   */
  useEffect(() => {
    if (artwork && !isClosing) {
      if (artworkData === initialArtworkData) {
        setArtworkData(artwork);
      } else if (artwork.sync !== artworkData.sync) {
        setArtworkData(artwork);
        setPreviewImage(null);
      }
    }
  }, [artworkData, artwork, setToast, isClosing]);

  let uploadedImage = formik.values.featured_image;

  let onImageUploadZoneComplete = useCallback(
    (res) => {
      setEditingImage(false);
      formik.setFieldValue(
        'featured_image',
        res
          ? {
              s3_path: res.key,
              width_px: res.width,
              height_px: res.height,
            }
          : null
      );
      formik.setFieldTouched('featured_image', true);
    },
    [formik]
  );

  let [previewImage, setPreviewImage] = useState(null);
  let onImageUploadZonePreviewChange = useCallback((preview) => {
    setPreviewImage(preview);
  }, []);

  let image =
    previewImage ||
    (artworkData?.featured_image
      ? {
          src: artworkData.featured_image.detail.url,
          width: artworkData.featured_image.detail.width,
          height: artworkData.featured_image.detail.height,
        }
      : null);

  let { dimensions, setRef } = useContain({
    aspectRatio: image ? image.width / image.height : 1,
  });

  let queryParams = new URLSearchParams(location.search);
  let editingFrom = queryParams.get('editingFrom');
  let initialTab = queryParams.get('initialTab') || 'details';

  /**
   * Handle form cancellation
   */

  let [isCancelling, setCancelling] = useState(false);

  let cancel = useCallback(() => {
    setCancelling(false);
    if (editingFrom && !isRequestingClose) {
      let url = getUrlWithBackground(editingFrom);
      history.push(url);
    } else {
      closePanel();
    }
  }, [
    editingFrom,
    closePanel,
    getUrlWithBackground,
    history,
    isRequestingClose,
  ]);

  let shouldPreventClose = formik.dirty || isSaving;

  let onCancelClick = useCallback(() => {
    if (shouldPreventClose) {
      setCancelling(true);
    } else {
      cancel();
    }
  }, [shouldPreventClose, cancel]);

  useEffect(() => {
    if (isRequestingClose && shouldPreventClose) {
      if (typeof preventClose === 'function') {
        preventClose();
        setCancelling(true);
      }
    }
  }, [isRequestingClose, preventClose, shouldPreventClose]);

  let showUploadPanel = !uploadedImage || editingImage;
  if (id && !artwork) {
    showUploadPanel = false;
  }

  let uploadBtnRef = useRef(null);

  let onImageUploadZoneOpen = useCallback(() => {
    if (uploadedImage) {
      setEditingImage(true);
    }
  }, [uploadedImage]);

  let isFormInvalid = Boolean(Object.keys(formik.errors).length);

  let firstError =
    isFormInvalid && formik.submitCount > 0
      ? Object.values(formik.errors)[0]
      : null;
  firstError = Array.isArray(firstError) ? firstError[0] : firstError;

  let [isEstimating, setEstimating] = useState(false);
  let [originalSizeValues, setOriginalSizeValues] = useState(null);

  let onEstimateSizeClick = useCallback(() => {
    setEstimating(true);
    setOriginalSizeValues({
      width: formik.values.width_inches,
      height: formik.values.height_inches,
    });
  }, [formik]);

  let onSizeEstimatorChange = useCallback(
    ({ width, height }) => {
      formik.setFieldValue('width_inches', width);
      formik.setFieldTouched('width_inches', true);
      formik.setFieldValue('height_inches', height);
      formik.setFieldTouched('height_inches', true);
    },
    [formik]
  );

  let onSizeEstimatorApply = useCallback(({ width, height }) => {
    setOriginalSizeValues(null);
    setEstimating(false);
  }, []);

  let onSizeEstimatorCancel = useCallback(() => {
    formik.setFieldValue('width_inches', originalSizeValues.width);
    formik.setFieldTouched('width_inches', true);
    formik.setFieldValue('height_inches', originalSizeValues.width);
    formik.setFieldTouched('height_inches', true);
    setEstimating(false);
  }, [originalSizeValues, formik]);

  let [selectedTab, setSelectedTab] = useState(initialTab);

  let fileUploadBtnRef = useRef(null);

  let [hasPendingFiles, setHasPendingFiles] = useState(false);

  let onFileUploadStatusChange = useCallback((hasPendingFiles) => {
    setHasPendingFiles(hasPendingFiles);
  }, []);

  let onFileUploadZoneChange = useCallback(
    (files) => {
      formik.setFieldValue(
        'artwork_files',
        files.map((file) => ({
          id: file.id,
          mime_type: file.mime_type,
          s3_path: file.s3_path,
          name: file.name,
        }))
      );
      formik.setFieldTouched('files', true);
    },
    [formik]
  );

  useEffect(() => {
    if (formik.dirty) {
      window.onbeforeunload = function () {
        return true;
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [formik.dirty]);

  return (
    <div className="ArtworkForm">
      <div
        className="ArtworkForm-col ArtworkForm-imageCol"
        aria-hidden={showUploadPanel}
      >
        <div className="ArtworkForm-imageColInner" ref={setRef}>
          {image && (
            <div className="ArtworkForm-imageChangeBtnContainer">
              <LazyImage
                alt="artwork preview"
                src={image.src}
                contained
                width={dimensions?.width || image.width}
                height={dimensions?.height || image.height}
              />
              <div className="ArtworkForm-imageChangeBtn">
                <Button
                  icon="upload"
                  onClick={() => {
                    if (uploadBtnRef.current) {
                      uploadBtnRef.current.click();
                    }
                  }}
                >
                  <div className="tooltip tooltip--top f-caption-01">
                    Change image
                  </div>
                </Button>
              </div>
            </div>
          )}
        </div>
        <AnimatePresence>
          {isEstimating && (
            <motion.div
              className="ArtworkForm-sizeEstimator"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.3, ease: [0.0, 0.0, 0.3, 1.0] },
              }}
              exit={{
                opacity: 0,
                transition: { duration: 0.25, ease: [0.5, 0.0, 0.1, 1.0] },
              }}
            >
              <ArtworkSizeEstimator
                image={{
                  url: image.src,
                  width: image.width,
                  height: image.height,
                }}
                onChange={onSizeEstimatorChange}
                onApply={onSizeEstimatorApply}
                onCancel={onSizeEstimatorCancel}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <form
        className="ArtworkForm-col ArtworkForm-formCol"
        onSubmit={formik.handleSubmit}
        aria-hidden={showUploadPanel}
      >
        <div className={cx('ArtworkForm-formColScroll')}>
          <div className="ArtworkForm-title">
            <Heading className="f-heading-01 ArtworkForm-titleInner">
              {selectedTab === 'story'
                ? 'Enter artwork story'
                : selectedTab === 'files'
                ? 'Upload artwork files'
                : (id ? 'Edit' : 'Enter') + ' artwork details'}
            </Heading>
            <div className="ArtworkForm-tabBar">
              <div className="ArtworkForm-tabs">
                <Button
                  label="Details"
                  secondary
                  selected={selectedTab === 'details'}
                  onClick={() => setSelectedTab('details')}
                  elProps={{
                    type: 'button',
                  }}
                />
                <Button
                  label="Story"
                  secondary
                  selected={selectedTab === 'story'}
                  onClick={() => setSelectedTab('story')}
                  elProps={{
                    type: 'button',
                  }}
                />
                <Button
                  label="Files"
                  secondary
                  selected={selectedTab === 'files'}
                  onClick={() => setSelectedTab('files')}
                  elProps={{
                    type: 'button',
                  }}
                />
              </div>
              {selectedTab === 'files' && (
                <Button
                  label="Upload"
                  secondary
                  onClick={(evt) => {
                    evt.preventDefault();
                    if (fileUploadBtnRef.current) {
                      fileUploadBtnRef.current.click();
                    }
                  }}
                  elProps={{
                    type: 'button',
                  }}
                />
              )}
            </div>
          </div>
          <div className="ArtworkForm-form">
            <div
              className="ArtworkForm-formInner"
              style={{
                display: selectedTab === 'details' ? undefined : 'none',
              }}
            >
              <TextField
                label="Title*"
                name="title"
                onChange={formik.handleChange}
                value={formik.values.title || ''}
                formik={formik}
                autoFocus
              />
              <TextField
                label="Artist"
                name="artist_name"
                onChange={formik.handleChange}
                value={formik.values.artist_name || ''}
                formik={formik}
              />
              <div className="ArtworkForm-sizeFields">
                <div className="ArtworkForm-sizeField">
                  <TextField
                    label="L*"
                    note="Inches"
                    type="text"
                    name="height_inches"
                    value={formik.values.height_inches || ''}
                    onChange={formik.handleChange}
                    formik={formik}
                    disabled={isEstimating}
                    className="num"
                  />
                </div>
                <div className="ArtworkForm-sizeField">
                  <TextField
                    label="W*"
                    note="Inches"
                    type="text"
                    name="width_inches"
                    value={formik.values.width_inches || ''}
                    onChange={formik.handleChange}
                    formik={formik}
                    disabled={isEstimating}
                    className="num"
                  />
                </div>
                <div className="ArtworkForm-sizeField">
                  <TextField
                    label="D"
                    note="Inches"
                    type="text"
                    name="depth_inches"
                    value={formik.values.depth_inches || ''}
                    onChange={formik.handleChange}
                    formik={formik}
                    className="num"
                  />
                </div>
                {!isEstimating && (
                  <div className="ArtworkForm-sizeFieldsNote f-caption-01">
                    Not sure?{' '}
                    <button
                      type="button"
                      className="link"
                      onClick={onEstimateSizeClick}
                    >
                      Estimate size
                    </button>
                  </div>
                )}
              </div>
              <div className="ArtworkForm-fieldGroup">
                <TextField
                  label="Created"
                  note="YYYY"
                  type="number"
                  name="date_made"
                  value={formik.values.date_made || ''}
                  onChange={formik.handleChange}
                  formik={formik}
                  className="num"
                />
                <TextField
                  label="Acquired"
                  note="YYYY"
                  type="number"
                  name="date_acquired"
                  value={formik.values.date_acquired || ''}
                  onChange={formik.handleChange}
                  formik={formik}
                  className="num"
                />
              </div>
              <div className="ArtworkForm-descriptionField">
                <TextField
                  name="description"
                  placeholder="Observations"
                  maxLength={MAX_DESCRIPTION_LENGTH}
                  multiline
                  value={formik.values.description || ''}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div
              className="ArtworkForm-formInner"
              style={{
                display: selectedTab === 'story' ? undefined : 'none',
              }}
            >
              <TextField
                name="story"
                placeholder="What does this artwork mean to you?"
                maxLength={MAX_STORY_LENGTH}
                multiline
                value={formik.values.story || ''}
                onChange={formik.handleChange}
              />
            </div>

            <div
              className="ArtworkForm-formInner"
              style={{
                display: selectedTab === 'files' ? undefined : 'none',
              }}
            >
              <FileUploadZone
                uploadBtnRef={fileUploadBtnRef}
                files={formik.values.artwork_files}
                extraArtworkData={artwork}
                onChange={onFileUploadZoneChange}
                onStatusChange={onFileUploadStatusChange}
              />
            </div>

            <div className="ArtworkForm-formFooter">
              <div
                className={cx('ArtworkForm-formNote f-caption-01', {
                  'is-error': firstError,
                })}
              >
                {firstError ? (
                  <span className="ArtworkForm-error">{firstError}</span>
                ) : selectedTab === 'files' ? (
                  'JPG, PNG, MP3, MP4, PDF. 20MB max.'
                ) : selectedTab === 'details' ? (
                  '*Required'
                ) : null}
              </div>
              <div className="ArtworkForm-formActions">
                <Button
                  label="Cancel"
                  elProps={{ type: 'button' }}
                  secondary
                  onClick={onCancelClick}
                />
                <Button
                  tabIndex="0"
                  label="Save"
                  elProps={{ type: 'submit' }}
                  error={isFormInvalid && formik.submitCount > 0}
                  fauxDisabled={isFormInvalid}
                  disabled={hasPendingFiles}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <motion.div
        className="ArtworkForm-uploadZone"
        initial={false}
        animate={{ opacity: showUploadPanel ? 1 : 0 }}
        style={{ pointerEvents: showUploadPanel ? 'auto' : 'none' }}
        aria-hidden={!showUploadPanel}
      >
        <ImageUploadZone
          onOpen={onImageUploadZoneOpen}
          onComplete={onImageUploadZoneComplete}
          onPreviewChange={onImageUploadZonePreviewChange}
          uploadBtnRef={uploadBtnRef}
        />
      </motion.div>
      <motion.div
        className="ArtworkForm-savingState"
        initial={false}
        animate={{ opacity: isSaving ? 1 : 0 }}
        style={{ pointerEvents: isSaving ? 'auto' : 'none' }}
      />
      <ConfirmDialogPortal>
        {isCancelling && (
          <ConfirmDialog
            title="You are sure you want to close the form?"
            message="Your form changes will not be saved."
            confirmLabel="Yes"
            cancelLabel="No"
            onConfirm={() => {
              cancel();
            }}
            onCancel={() => {
              setCancelling(false);
              cancelRequestClose();
            }}
          />
        )}
      </ConfirmDialogPortal>
    </div>
  );
}

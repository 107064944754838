import { useCallback, useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';

const DRAG_THRESHOLD_PX = 20;

export let useDraggableWithinWall = ({
  currentPx,
  wallConstraints,
  onValidDrop,
}) => {
  let [startPx, setStartPx] = useState(null);
  let [clientPx, setClientPx] = useState(null);
  let [draftPx, setDraftPx] = useState(null);

  let targetRef = useRef(null);
  let [isMouseDown, setMouseDown] = useState(false);
  let [isDragging, setIsDragging] = useState(false);
  let [targetRect, setTargetRect] = useState(null);

  /**
   * Set the delta x/y, as well as the draft position of the artwork
   * (constrained by the wall size)
   */
  let onDrag = useCallback((evt) => {
    setClientPx({
      left: evt.pageX,
      top: evt.pageY,
    });
  }, []);

  let deltaPx = useMemo(
    () =>
      startPx && clientPx
        ? {
            left: clientPx.left - startPx.left,
            top: clientPx.top - startPx.top,
          }
        : null,
    [clientPx, startPx]
  );

  /**
   * Set the draft, based on wall constraints
   */
  useEffect(() => {
    if (isMouseDown && deltaPx) {
      let leftPx = currentPx.left + deltaPx.left;
      let topPx = currentPx.top + deltaPx.top;
      leftPx = Math.max(0, leftPx);
      leftPx = Math.min(wallConstraints.width - targetRect.width, leftPx);
      topPx = Math.max(0, topPx);
      topPx = Math.min(wallConstraints.height - targetRect.height, topPx);
      if (!draftPx || draftPx.left !== leftPx || draftPx.top !== topPx) {
        setDraftPx({
          left: leftPx,
          top: topPx,
        });
      }
    }
  }, [deltaPx, wallConstraints, targetRect, draftPx, currentPx, isMouseDown]);

  /**
   * Update the draftPx based on the latest deltaPx
   */
  useEffect(() => {}, [
    isMouseDown,
    deltaPx,
    draftPx,
    wallConstraints,
    targetRect,
    currentPx,
  ]);

  /**
   * Reset all the internal state,
   * and send the new draftPx to the drop callback, for which it is now responsible.
   */
  let onDrop = useCallback(
    (evt) => {
      setMouseDown(false);
      setClientPx(null);
      setStartPx(null);
      window.removeEventListener('mousemove', onDrag);
      window.removeEventListener('mouseup', onDrop);
    },
    [onDrag]
  );

  /**
   * Capture the current target, and its bounds, on pointerDown
   */
  let startDrag = useCallback(
    (evt) => {
      targetRef.current = evt.currentTarget;
      let rect = evt.currentTarget.getBoundingClientRect();
      setMouseDown(true);
      setTargetRect(rect);
      setStartPx({
        left: evt.pageX,
        top: evt.pageY,
      });
      window.addEventListener('mousemove', onDrag);
      window.addEventListener('mouseup', onDrop);
    },
    [onDrag, onDrop]
  );

  /**
   * When the mouse gets related, resolve whether it's a valid drop
   * by checking the latest state
   */
  useEffect(() => {
    if (!isMouseDown && draftPx && isDragging) {
      setDraftPx(null);
      setIsDragging(false);
      onValidDrop({
        draftPx,
      });
    }
  }, [isMouseDown, draftPx, isDragging, onValidDrop]);

  /**
   * Treat the artwork as dragging
   * once it goes beyond a given threshold
   */
  useEffect(() => {
    if (
      !isDragging &&
      isMouseDown &&
      Boolean(deltaPx) &&
      (Math.abs(deltaPx.top) > DRAG_THRESHOLD_PX ||
        Math.abs(deltaPx.left) > DRAG_THRESHOLD_PX)
    ) {
      setIsDragging(true);
    }
  }, [isDragging, isMouseDown, deltaPx]);

  return {
    draftPx: isDragging ? draftPx : null,
    startDrag,
    isDragging,
    isMouseDown,
  };
};

import React from 'react';

export default function Rooms24() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 8.5H5.5V18.5H15.5V8.5Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M18.5 5.5H8.5V15.5H18.5V5.5Z"
        stroke="black"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

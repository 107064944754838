import React, { useCallback, useContext, useMemo, useRef } from 'react';

let ChildPresenceContext = React.createContext(null);

export let ChildPresence = (props) => {
  let { onElementAdd, onElementRemove } = props;
  let value = useMemo(() => ({ onElementAdd, onElementRemove }), [
    onElementAdd,
    onElementRemove,
  ]);
  return <ChildPresenceContext.Provider value={value} {...props} />;
};

export let useChildPresence = () => {
  let ref = useRef(null);
  let { onElementRemove, onElementAdd } = useContext(ChildPresenceContext);

  let setRef = useCallback(
    (node) => {
      if (ref.current) {
        onElementRemove(ref.current);
      }
      if (node) {
        onElementAdd(node);
      }
      ref.current = node;
    },
    [onElementAdd, onElementRemove]
  );

  return {
    setRef,
  };
};

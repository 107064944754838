import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';
import { usePrevious } from 'react-use';
import qs from 'qs';

// Assets
import './FileUploadZone.css';

// Components
import ArtworkFileRow from '../ArtworkFileRow';

// Hooks
import { useErrors } from '../../hooks/Error';

// Utils
import uploadFileToS3 from '../../utils/uploadFileToS3';

// Config
const ACCEPTED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'audio/mpeg',
  'video/mpeg',
  'video/mp4',
];

const MAX_FILE_SIZE_BYTES = 20 * 1000000; // 20 MB

export default function FileUploadZone({
  files,
  uploadBtnRef,
  onChange,
  onStatusChange,
  extraArtworkData,
}) {
  let { setError } = useErrors();

  let [pendingFiles, setPendingFiles] = useState([]);
  let [failedFiles, setFailedFiles] = useState([]);

  let s3SignAndUpload = useCallback(async (file, size) => {
    let data;
    try {
      data = (
        await axios.get(
          '/api/s3/presign?' +
            qs.stringify({
              name: file.name,
              type: file.type,
            })
        )
      ).data;
    } catch (err) {
      console.error('Presign failed: ', err);
      throw err;
    }

    await uploadFileToS3(
      data.formInputs,
      data.formAttributes.action,
      data.key,
      file
    );
    return {
      key: data.key,
      mime_type: data.mime_type,
    };
  }, []);

  let onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      // Show an error message for the first rejected file in the list
      if (rejectedFiles?.length) {
        if (!ACCEPTED_FILE_TYPES.includes(rejectedFiles[0].type)) {
          setError({
            message: `One of your files (${rejectedFiles[0].path}) is not an allowed file type.`,
          });
        } else if (rejectedFiles[0].size > MAX_FILE_SIZE_BYTES) {
          setError({
            message: `One of your files (${rejectedFiles[0].path}) is too large`,
          });
        } else {
          setError({
            message: `One of your files (${rejectedFiles[0].path}) is invalid`,
          });
        }
      }

      setPendingFiles(acceptedFiles);

      // Continue to upload any accepted files
      acceptedFiles.forEach((file) => {
        s3SignAndUpload(file)
          .then((res) => {
            // Remove it as a pending file...
            setPendingFiles((state) =>
              state.filter((pendingFile) => pendingFile.path !== file.path)
            );

            // Send it to formik...
            onChange(
              files.concat([
                {
                  s3_path: res.key,
                  mime_type: res.mime_type,
                  name: file.path,
                },
              ])
            );
          })
          .catch((err) => {
            // Remove it as a pending file...
            setPendingFiles((state) =>
              state.filter((pendingFile) => pendingFile.path !== file.path)
            );
            // Class it as a failed file...
            setFailedFiles((state) => state.concat([file]));
          });
      });
    },
    [setError, files, onChange, s3SignAndUpload]
  );

  let { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILE_TYPES.join(', '),
    maxSize: MAX_FILE_SIZE_BYTES,
    multiple: true,
    noClick: true,
  });

  let prevPendingFiles = usePrevious(pendingFiles);

  useEffect(() => {
    if (prevPendingFiles && !prevPendingFiles.length && pendingFiles.length) {
      setFailedFiles([]);
    }
  }, [pendingFiles, prevPendingFiles]);

  useEffect(() => {
    onStatusChange(Boolean(pendingFiles.length));
  }, [pendingFiles, onStatusChange]);

  let onFailedFileClick = useCallback((file) => {
    setFailedFiles((state) =>
      state.filter((failedFile) => failedFile.path !== file.path)
    );
  }, []);

  let onFileDeleteClick = useCallback(
    (fileToDelete) => {
      onChange(files.filter((file) => file.s3_path !== fileToDelete.s3_path));
    },
    [files, onChange]
  );

  return (
    <div
      className={cx('FileUploadZone', {
        'is-dropping': isDragActive,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps({})} />
      <button type="button" onClick={open} hidden ref={uploadBtnRef} />
      {isDragActive ||
      (!files.length && !pendingFiles.length && !failedFiles.length) ? (
        <div className="FileUploadZone-instruction">
          {`${isDragActive ? 'Drop' : 'Drag'} your files here`}
        </div>
      ) : (
        <>
          {files.map((file, i) => (
            <ArtworkFileRow
              file={file}
              extraFileData={extraArtworkData?.artwork_files?.find(
                (fileData) => fileData.s3_path === file.s3_path
              )}
              key={i}
              onDeleteClick={onFileDeleteClick}
              editable={true}
            />
          ))}
          {failedFiles.map((file, i) => (
            <ArtworkFileRow
              file={file}
              key={i}
              onDeleteClick={onFailedFileClick}
              editable={true}
              failed={true}
            />
          ))}
          {pendingFiles.map((file, i) => (
            <ArtworkFileRow file={file} key={i} uploading={true} />
          ))}
        </>
      )}
    </div>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';
import * as Sentry from '@sentry/browser';

// Assets
// (Loading general styles before component styles)
import 'normalize.css';
import './styles';

// Components
import App from './components/App';

// Hooks
import { AuthProvider } from './hooks/Auth';
import { ToastProvider } from './hooks/Toast';
import { ErrorProvider } from './hooks/Error';
import { ThemeProvider } from './hooks/Theme';
import { RoomCacheBusterProvider } from './hooks/RoomCacheBuster';
import { RoomPreviewTransitionProvider } from './hooks/RoomPreviewTransition';

// Utils
import sleeper from './utils/sleeper';

var entries = require('object.entries');

axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.baseURL = process.env.REACT_APP_API_URL || '';

axios.interceptors.request.use(
  async (config) => {
    if (process.env.REACT_APP_SIMULATE_API_DELAY) {
      await sleeper(process.env.REACT_APP_SIMULATE_API_DELAY);
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

window.axios = axios; // Useful for API debugging.

let queryConfig = {
  retry: 1,
};

if (process.env.REACT_APP_SENTRY_DSN !== 'false') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
}

/**
 * Due to the specific fonts used (Sohne and JJannon), there are specific vertical alignment issues
 * similar to: https://bugzilla.mozilla.org/show_bug.cgi?id=480196
 * So we need to alleviate this issue by offsetting various fonts by a relative value.
 */
let isMac = navigator.userAgent.indexOf('Mac OS X') !== -1;
let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

if (!isMac || isFirefox) {
  document.body.classList.add('has-font-alignment-issue');
}

/**
 * Shim Object.entries if it is not natively supported
 */
if (!Object.entries) {
  entries.shim();
}

ReactDOM.render(
  <Router>
    <Route
      render={() => (
        <ReactQueryConfigProvider config={queryConfig}>
          <ErrorProvider>
            <ToastProvider>
              <AuthProvider>
                <ThemeProvider>
                  <RoomPreviewTransitionProvider>
                    <RoomCacheBusterProvider>
                      <App />
                    </RoomCacheBusterProvider>
                  </RoomPreviewTransitionProvider>
                </ThemeProvider>
              </AuthProvider>
            </ToastProvider>
          </ErrorProvider>
        </ReactQueryConfigProvider>
      )}
    />
  </Router>,
  document.getElementById('root')
);

import React, { useContext, useState } from 'react';

let ThemeContext = React.createContext();

export const LIGHT_MODE = 'light';
export const DARK_MODE = 'dark';

export let useTheme = () => {
  let { theme, setTheme } = useContext(ThemeContext);
  return { theme, setTheme };
};

export let ThemeProvider = (props) => {
  let [theme, setTheme] = useState('light');
  return <ThemeContext.Provider value={{ theme, setTheme }} {...props} />;
};

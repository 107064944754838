import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Button from '../Button';
import { AnimatePresence, motion } from 'framer-motion';
import { FocusOn } from 'react-focus-on';

// Assets
import './ConfirmDialog.css';

let variants = {
  exit: { opacity: 0, duration: 0.1 },
  enter: { opacity: 1, duration: 0.3 },
};

let innerVariants = {
  exit: { y: 10, duration: 0.1 },
  enter: { y: 0, duration: 0.3 },
};

export let ConfirmDialogPortal = ({ children }) => {
  return ReactDOM.createPortal(
    <AnimatePresence>{children}</AnimatePresence>,
    document.getElementById('dialogPortal')
  );
};

export let ConfirmDialog = ({
  title,
  message,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  onConfirm,
  onCancel,
  footerNote,
  isConfirmDisabled = false,
}) => {
  let onDismiss = useCallback(() => {
    onCancel();
  }, [onCancel]);

  let onSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      onConfirm(evt);
    },
    [onConfirm]
  );

  return (
    <motion.div
      className="ConfirmDialog light-mode"
      initial="exit"
      animate="enter"
      exit="exit"
      variants={variants}
    >
      <motion.div
        variants={innerVariants}
        className="ConfirmDialog-box"
        aria-label={title}
      >
        <FocusOn onClickOutside={onDismiss} onEscapeKey={onDismiss}>
          <form onSubmit={onSubmit}>
            <div className="ConfirmDialog-inner">
              <p className="ConfirmDialog-title f-subhead-01">{title}</p>
              {message && <div className="ConfirmDialog-msg">{message}</div>}
            </div>
            <div className="ConfirmDialog-actions">
              <div className="ConfirmDialog-footerNote f-caption-01">
                {footerNote || null}
              </div>
              <div className="ConfirmDialog-actionsInner">
                <Button
                  secondary
                  elProps={{ type: 'button' }}
                  label={cancelLabel}
                  onClick={onDismiss}
                />
                <Button
                  label={confirmLabel}
                  disabled={isConfirmDisabled}
                  elProps={{
                    type: 'submit',
                  }}
                />
              </div>
            </div>
          </form>
        </FocusOn>
      </motion.div>
    </motion.div>
  );
};

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

ConfirmDialog.defaultProps = {};

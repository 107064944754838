import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

// Assets
import './LazyImage.css';

// Components
import icons from '../_icons';
import ImagePreloader from '../ImagePreloader';

let LazyImage = React.forwardRef(
  (
    {
      alt,
      preload,
      width,
      height,
      src,
      initial,
      animate,
      exit,
      contained = false,
      style = {},
      placeholder,
      className,
      ...props
    },
    ref
  ) => {
    let imgRef = useRef(null);
    let [status, setStatus] = useState('loading');
    let [animateLoad, setAnimateLoad] = useState(false);

    let onLoad = useCallback(() => {
      setStatus('loaded');
    }, []);

    let onError = useCallback(() => {
      setStatus('error');
    }, []);

    if (contained) {
      style = {
        ...style,
        width,
        height,
      };
    }

    let imgStyle = {
      opacity: status === 'loaded' ? 1 : 0,
      width: contained ? '100%' : undefined,
      height: contained ? '100%' : undefined,
      maxWidth: contained ? width : undefined,
      maxHeight: contained ? height : undefined,
    };

    /**
     * If 100ms has passed, once the image loads, fade it in.
     */
    useEffect(() => {
      let timeout = setTimeout(() => {
        setAnimateLoad(true);
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <div
        className={cx('LazyImage', {
          'has-placeholder': Boolean(placeholder),
          'has-loaded': status === 'loaded',
          'should-animate': animateLoad,
        })}
        ref={ref}
        style={style}
      >
        {placeholder && (
          <img
            alt=""
            className="LazyImage-imgPlaceholder"
            src={placeholder}
            width={width}
            height={height}
          />
        )}
        <img
          ref={imgRef}
          className={cx('LazyImage-img', className)}
          alt={alt}
          width={width}
          src={src}
          height={height}
          onLoad={onLoad}
          {...props}
          onError={onError}
          loading="lazy"
          style={imgStyle}
        />
        {status === 'loading' && !placeholder && (
          <div className="LazyImage-loading">
            <icons.loading />
          </div>
        )}
        {preload && <ImagePreloader url={preload} />}
      </div>
    );
  }
);

export default LazyImage;

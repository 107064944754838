import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTimeoutFn, usePrevious } from 'react-use';

let ToastContext = React.createContext();

function ToastProvider({ timeoutMs = 5000, children, ...props }) {
  let [toast, setToastState] = useState(null);
  let [, , resetTimeout] = useTimeoutFn(removeToast, timeoutMs);
  let previousToast = usePrevious(toast);

  function removeToast() {
    if (toast) {
      setToastState(null);
    }
  }

  let setToast = useCallback((val) => {
    setToastState(val);
  }, []);

  useEffect(() => {
    if (toast !== previousToast) {
      resetTimeout();
    }
  });

  const value = useMemo(() => [toast, setToast], [toast, setToast]);

  return (
    <ToastContext.Provider value={value} {...props}>
      {children}
    </ToastContext.Provider>
  );
}

function useToast() {
  let context = useContext(ToastContext);
  if (!context) {
    throw new Error(`useToast must be used within a ToastProvider`);
  }

  let [toast, setToast] = context;

  let hideToast = () => {
    setToast(null);
  };

  return {
    toast,
    setToast,
    hideToast,
  };
}

export { ToastProvider, useToast };

import React from 'react';

export let Bench = React.memo(({ width }) => {
  let ratio = 200 / 90;

  return (
    <svg
      width={width}
      height={width / ratio}
      viewBox="0 0 200 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5 72.5H15.5V11.5L23 1.49997H169.5L176.5 11.5V72.5H170.5V17.5H21.5V72.5Z"
        fill="white"
      />
      <path
        d="M15.5 11.5H176.5M15.5 11.5L23 1.49997H169.5L176.5 11.5H15.5ZM15.5 11.5V72.5H21.5V17.5H170.5V72.5H176.5V11.5H15.5Z"
        stroke="black"
      />
      <path
        d="M164.5 59L170.5 72.5V17.5H164.5V59Z"
        fill="white"
        stroke="black"
      />
      <path d="M27.5 59L21.5 72.5V17.5H27.5V59Z" fill="white" stroke="black" />
      <path d="M176 83H16V84H176V83Z" fill="#3C7DC3" />
      <path
        d="M18.2989 0.492054L10.215 11.438L11.0194 12.032L19.1033 1.08613L18.2989 0.492054Z"
        fill="#3C7DC3"
      />
      <path d="M104 77H87V90H104V77Z" fill="white" />
      <path
        d="M93.956 83.992C94.244 83.584 94.796 83.26 95.516 83.26C96.608 83.26 97.352 84.136 97.352 85.228C97.352 86.332 96.512 87.172 95.42 87.172C94.352 87.172 93.608 86.464 93.428 85.36L92.324 85.588C92.624 87.22 93.8 88.132 95.42 88.132C97.148 88.132 98.432 86.836 98.432 85.204C98.432 83.572 97.328 82.324 95.648 82.324C94.892 82.324 94.304 82.624 93.932 82.996L94.16 80.392H97.964V79.384H93.272L92.9 83.812L93.956 83.992ZM99.207 80.776H99.831V80.908C99.831 81.436 99.543 81.712 99.015 81.76V82.384C99.975 82.36 100.575 81.772 100.575 80.86V79.384H99.207V80.776Z"
        fill="#3C7DC3"
      />
      <path
        d="M3.007 1.33597C2.923 2.33197 2.587 2.67997 1.567 2.75197L0.643005 2.81197V3.66397H2.71899V9.99997H3.871V1.33597H3.007ZM5.17101 2.77597H5.795V2.90797C5.795 3.43597 5.507 3.71196 4.979 3.75996V4.38397C5.939 4.35997 6.539 3.77197 6.539 2.85997V1.38397H5.17101V2.77597Z"
        fill="#3C7DC3"
      />
      <path d="M16 81H15V86H16V81Z" fill="#3C7DC3" />
      <path d="M193 73V13H192V73H193Z" fill="#3C7DC3" />
      <path d="M200 34H184V52H200V34Z" fill="white" />
      <path
        d="M188.408 46.052V47H194.336V45.992H189.98L191.924 44.408C193.16 43.412 194.168 42.404 194.168 40.94C194.168 39.356 193.064 38.252 191.324 38.252C189.74 38.252 188.48 39.416 188.48 40.952C188.48 41.288 188.54 41.576 188.672 41.948H189.752C189.632 41.648 189.56 41.324 189.56 41.012C189.56 40.028 190.268 39.236 191.3 39.236C192.356 39.236 193.04 39.956 193.04 41.012C193.04 42.14 192.128 42.968 190.952 43.94L188.408 46.052ZM195.218 39.776H195.842V39.908C195.842 40.436 195.554 40.712 195.026 40.76V41.384C195.986 41.36 196.586 40.772 196.586 39.86V38.384H195.218V39.776Z"
        fill="#3C7DC3"
      />
      <path d="M195 13V12H190V13H195Z" fill="#3C7DC3" />
      <path d="M195 73V72H190V73H195Z" fill="#3C7DC3" />
      <path d="M177 81H176V86H177V81Z" fill="#3C7DC3" />
    </svg>
  );
});

import React from 'react';

export default function Close24() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5962 15.3886L8.11096 6.90332L7.40386 7.61043L15.8891 16.0957L16.5962 15.3886Z"
        fill="currentColor"
      />
      <path
        d="M15.8891 6.90339L7.40381 15.3887L8.11092 16.0958L16.5962 7.6105L15.8891 6.90339Z"
        fill="currentColor"
      />
    </svg>
  );
}

import React from 'react';

export default function Delete24() {
  return (
    <svg
      className="icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 10V18H8V10H16ZM17 9H7V19H17V9Z" fill="black" />
      <path d="M18 7H6V8H18V7Z" fill="black" />
      <path d="M9.5 7V5.5H14.5V7" stroke="black" strokeMiterlimit="10" />
    </svg>
  );
}

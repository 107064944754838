import { useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';

// Utils
import roundQuarter from '../../../utils/roundQuarter';

// Config
// const ESTIMATOR_MIN_HEIGHT_INCHES = 1 * 12;
// const ESTIMATOR_MAX_HEIGHT_INCHES = 10 * 12;
// const ESTIMATOR_MIN_WIDTH_INCHES = 1 * 12;
// const ESTIMATOR_MAX_WIDTH_INCHES = 10 * 12;

export let useEstimatorSizes = ({
  width,
  height,
  maxWidthInches,
  maxHeightInches,
}) => {
  let isLandscape = width > height;
  let ratio = width / height;

  /**
   * Slider changes
   */
  let sizeInchesRange = useMemo(() => {
    let ret = [];
    let min = 1 * 12;

    let getDims = (val) => {
      let width, height;
      if (isLandscape) {
        height = val;
        width = roundQuarter(val * ratio);
      } else {
        width = val;
        height = roundQuarter(val / ratio);
      }
      return {
        width,
        height,
      };
    };

    let val = min;
    let { width, height } = getDims(val);

    while (width <= maxWidthInches && height <= maxHeightInches) {
      ret.push({
        width,
        height,
        label: `${height}" × ${width}"`,
      });
      // Prepare next dimensions
      val++;
      let dims = getDims(val);
      width = dims.width;
      height = dims.height;
    }
    return ret;
  }, [isLandscape, ratio, maxWidthInches, maxHeightInches]);

  /**
   * Current size state
   */
  let [currentSize, setCurrentSize] = useState(null);
  currentSize =
    currentSize == null
      ? sizeInchesRange[Math.round(sizeInchesRange.length / 2)]
      : currentSize;

  // If the sizes array changes, then re-calculate the index
  let prevSizeInchesRange = usePrevious(sizeInchesRange);
  useEffect(() => {
    if (
      currentSize != null &&
      sizeInchesRange !== prevSizeInchesRange &&
      typeof prevSizeInchesRange !== 'undefined'
    ) {
      let idx = sizeInchesRange.indexOf(currentSize);
      idx = idx === -1 ? null : idx;
      setCurrentSize(sizeInchesRange[idx]);
    }
  }, [currentSize, sizeInchesRange, prevSizeInchesRange]);

  return {
    sizeInchesRange,
    currentSize,
    setCurrentSize,
  };
};

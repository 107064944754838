import React, { useCallback, useRef, useState } from 'react';
import cx from 'classnames';

import './TextField.css';

export function TextField({
  name,
  note,
  formik,
  maxLength,
  multiline = false,
  width,
  type = 'text',
  onClick,
  label = null, // Use label to show the placeholder as a note when filled
  className,
  ...props
}) {
  let inputRef = useRef(null);

  let [isFocused, setFocused] = useState(false);

  let onFocus = useCallback(() => {
    setFocused(true);
  }, []);

  let onBlur = useCallback(() => {
    setFocused(false);
  }, []);

  let handleClick = useCallback(
    (evt) => {
      if (!isFocused && inputRef.current) {
        inputRef.current.focus();
      }
      onClick && onClick(evt);
    },
    [isFocused, onClick]
  );

  let charsLeft = null;
  if (maxLength) {
    charsLeft = maxLength - props.value.length;
  }

  let Node = multiline ? 'textarea' : 'input';
  type = multiline ? undefined : type;

  // Infer any errors using formik context
  let { errors, touched } = formik || {};
  let formError = errors && errors[name];
  let isTouched = touched && touched[name];

  if (label && props.value) {
    note = label;
  }

  return (
    <div
      onClick={handleClick}
      className={cx('TextField', {
        'is-focused': isFocused,
        'TextField--multiline': multiline,
        'TextField--error': Boolean(formError) && isTouched,
      })}
      style={{
        width,
      }}
    >
      <div className="TextField-inner">
        <Node
          ref={inputRef}
          type={type}
          className={cx('TextField-input f-body-01', className)}
          name={name}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={label || undefined}
          {...props}
        />
        {note && <div className="TextField-note f-caption-01">{note}</div>}
        {maxLength && (
          <div
            className={cx('TextField-note num f-caption-01', {
              // 'is-warning': charsLeft <= 50 && charsLeft >= 0,
              'is-error': charsLeft < 0,
            })}
          >
            {charsLeft}
          </div>
        )}
      </div>
    </div>
  );
}

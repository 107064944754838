import React, { useCallback, useContext, useEffect, useState } from 'react';

let MouseContext = React.createContext();

export let useMouse = () => {
  let context = useContext(MouseContext);
  let [client, setClient] = useState({ x: 0, y: 0 });

  let onWindowMouseMove = useCallback(
    (evt) => {
      if (!context) {
        setClient({
          x: evt.clientX,
          y: evt.clientY,
        });
      }
    },
    [context]
  );

  useEffect(() => {
    if (!context) {
      window.addEventListener('mousemove', onWindowMouseMove);
      return () => {
        window.removeEventListener('mousemove', onWindowMouseMove);
      };
    }
  }, [context, onWindowMouseMove]);

  return (
    context || {
      client,
    }
  );
};

export let MouseProvider = (props) => {
  let value = useMouse();
  return <MouseContext.Provider value={value} {...props} />;
};

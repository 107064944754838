import React from 'react';

export default function CaretDown24() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 11L12 15L16 11" stroke="currentColor" />
    </svg>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import { AnimatePresence } from 'framer-motion';

export let Portal = ({ id, children }) => {
  return ReactDOM.createPortal(
    <AnimatePresence>{children}</AnimatePresence>,
    document.getElementById(id)
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FocusOn } from 'react-focus-on';
import { usePrevious, useWindowSize, useScroll } from 'react-use';
import {
  Route,
  NavLink,
  Switch,
  Redirect,
  useLocation,
  matchPath,
  Link,
} from 'react-router-dom';
import cx from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';
import { Helmet } from 'react-helmet';

// Assets
import './App.css';

// Components
import LoginScreen from '../LoginScreen';
import icons from '../_icons';
import InventoryIndex from '../InventoryIndex';
import RoomsIndex from '../RoomsIndex';
import { DetailPanel } from '../DetailPanel/DetailPanel';
import ArtworkForm from '../ArtworkForm';
import AddArtworkScreen from '../AddArtworkScreen';
import SystemError from '../SystemError';
import RoomComposer from '../RoomComposer';
import ArtworkDetail from '../ArtworkDetail';
import Button from '../Button';
import Toast from '../Toast';
import Error404 from '../Error404';
import ResetPassword from '../ResetPassword';
import AccountDisabled from '../AccountDisabled';
import SettingsScreen from '../SettingsScreen';
import RoomsIndexPreviewable from '../RoomsIndexPreviewable';

// Hooks
import { useToast } from '../../hooks/Toast';
import { useAuth } from '../../hooks/Auth';
import { useErrors } from '../../hooks/Error';
import {
  BackgroundStateProvider,
  useBackgroundState,
} from '../../hooks/BackgroundState';
import { useTheme } from '../../hooks/Theme';
import { ScrollProvider } from '../../hooks/Scroll';
import { Heading } from '../../hooks/DocumentOutline';
import { useInputType } from '../../hooks/useInputType';
import { useRoomPreviewTransition } from '../../hooks/RoomPreviewTransition';

function RouteScreen({
  children,
  className = 'AppPage',
  isFixedPanel = false,
}) {
  let { rootLocation } = useBackgroundState();
  let { isTransitioning: isRoomPreviewTransition } = useRoomPreviewTransition();
  let routeAnimationDisabled =
    rootLocation.state?.disableRouteAnimation || false;
  return (
    <motion.div
      className={className}
      initial={
        routeAnimationDisabled && !isRoomPreviewTransition
          ? false
          : { opacity: 0 }
      }
      animate={{
        opacity: 1,
        transition: { delay: isRoomPreviewTransition ? 0.4 : 0.4 },
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: isRoomPreviewTransition ? 0.5 : 0.1,
          delay: isRoomPreviewTransition ? 1 : 0,
        },
      }}
      style={{
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
      }}
    >
      {children}
    </motion.div>
  );
}

let AppMain = React.memo(({ children, fixed = false }) => {
  let ref = useRef(null);
  let scrollXY = useScroll(ref);
  let [scrollbarWidth, setScrollbarWidth] = useState(0);
  let { rootLocation } = useBackgroundState();

  useEffect(() => {
    setTimeout(() => {
      ref.current && ref.current.scrollTo({ top: 0 });
    }, 200);
  }, [rootLocation.pathname]);

  useEffect(() => {
    if (ref.current) {
      setScrollbarWidth(ref.current.offsetWidth - ref.current.clientWidth);
    }
  }, [ref]);

  return (
    <div
      className={cx('App-main', fixed ? 'App-main--fixed' : '')}
      id="AppMain"
      ref={ref}
    >
      <ScrollProvider value={{ scrollXY, scrollbarWidth }}>
        {children}
      </ScrollProvider>
    </div>
  );
});

let backgroundColors = [
  'orange',
  'yellow',
  'lime',
  'green',
  'slate',
  'blue',
  'red',
  'brown',
];

function App() {
  let { toast, hideToast, setToast } = useToast();
  let { loggedIn, logout, user, isActive } = useAuth();
  let location = useLocation();
  let { isIntending, isTransitioning } = useRoomPreviewTransition();

  /**
   * Handle error visibility
   */

  let { errors, clearError, setError } = useErrors();
  let error = errors[0];

  useEffect(() => {
    let timeout;
    if (error) {
      timeout = setTimeout(() => {
        clearError(error.id);
      }, 10000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [error, clearError]);

  let onSystemErrorClick = useCallback(
    (error) => {
      clearError(error.id);
    },
    [clearError]
  );

  /**
   * Handle navigation menu toggling
   */

  let [menuOpen, setMenuOpen] = useState(false);
  let onMenuClick = useCallback(
    (evt) => {
      evt.preventDefault();
      setMenuOpen(!menuOpen);
    },
    [menuOpen]
  );

  let onLogoutSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      setMenuOpen(false);
      logout();
    },
    [logout]
  );

  let handleAppNavOutsideClick = useCallback(() => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  }, [menuOpen]);

  let onNavLinkClick = useCallback(() => {
    setMenuOpen(false);
  }, []);

  let { rootLocation, hasBackground } = useBackgroundState();

  /**
   * Set the background color whenever the rootLocation changes
   */
  let rootLocationPathname = rootLocation.pathname;
  let prevRootLocation = usePrevious(rootLocationPathname);
  let [backgroundColor, setBackgroundColor] = useState(null);
  let routeAnimationDisabled =
    rootLocation.state?.disableRouteAnimation || false;

  if (
    prevRootLocation &&
    rootLocationPathname.includes('rooms') &&
    prevRootLocation.includes('rooms')
  ) {
    routeAnimationDisabled = true;
  }

  useEffect(() => {
    let pathChanged =
      prevRootLocation && rootLocationPathname !== prevRootLocation;

    if (pathChanged && !routeAnimationDisabled) {
      let randomBackgroundColor =
        backgroundColors[Math.floor(Math.random() * backgroundColors.length)];
      setBackgroundColor(randomBackgroundColor);
    }
  }, [rootLocationPathname, prevRootLocation, routeAnimationDisabled]);

  /**
   * Reset the background color after x seconds once it changes
   */
  let prevBackgroundColor = usePrevious(backgroundColor);
  useEffect(() => {
    let timeout = null;
    if (backgroundColor !== prevBackgroundColor && backgroundColor != null) {
      timeout = setTimeout(() => {
        setBackgroundColor(null);
      }, 500);
    }
    return () => {
      if (timeout) {
        // clearTimeout(timeout);
      }
    };
  }, [backgroundColor, prevBackgroundColor]);

  let { theme } = useTheme();

  let compatError = false;
  let { width, height } = useWindowSize();
  if (width < 800 || height < 500) {
    compatError = true;
  }

  useHotkeys('ctrl+e', () => {
    if (
      process.env.REACT_APP_DESIGN_TESTS == null
        ? true
        : process.env.REACT_APP_DESIGN_TESTS
    ) {
      try {
        throw new Error('Sample error.');
      } catch (error) {
        setError({
          message: 'Sample error message.',
          error,
        });
      }
    }
  });

  useHotkeys('ctrl+t', () => {
    if (
      process.env.REACT_APP_DESIGN_TESTS == null
        ? true
        : process.env.REACT_APP_DESIGN_TESTS
    ) {
      setToast({
        message: 'Sample toast message.',
      });
    }
  });

  let navItems = [
    {
      to: '/rooms-preview',
      label: 'Rooms',
    },
    {
      to: '/artworks',
      label: 'Index',
    },
    {
      to: '/settings',
      label: 'Settings',
    },
  ];

  let settingsPathMatches = Boolean(
    matchPath(rootLocation.pathname, { path: '/settings' })
  );

  let roomDetailPathMatches = Boolean(
    matchPath(rootLocationPathname, { path: '/rooms/:id' }) ||
      matchPath(rootLocationPathname, { path: '/rooms/new' })
  );

  let showInactiveError = !isActive && !settingsPathMatches;

  let inputType = useInputType();

  return (
    <div
      className={`App ${menuOpen ? ' is-menu-open' : ''} f-body ${theme}-mode ${
        error ? ' is-error' : ''
      }`}
      style={{
        '--theme-base-bg': backgroundColor
          ? `var(--color-brand-${backgroundColor}-10)`
          : undefined,
      }}
    >
      <AnimatePresence exitBeforeEnter>
        {error && (
          <>
            <motion.div
              key={error.id + '-mask'}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ease: [0.0, 0.0, 0.3, 1.0] }}
              exit={{
                opacity: 0,
              }}
              className="App-errorMask"
            />
            <motion.div
              key={error.id}
              initial={{ y: '-100%' }}
              animate={{ y: '0%' }}
              transition={{ ease: [0.0, 0.0, 0.3, 1.0] }}
              exit={{
                y: '-100%',
              }}
              className="App-error"
            >
              <SystemError error={error} onClick={onSystemErrorClick} />
            </motion.div>
          </>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {loggedIn && user ? (
          <motion.div
            className="App-inner"
            key="loggedin"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div
              className={cx('App-sidebar', {
                'is-hidden': isIntending || isTransitioning,
              })}
            >
              <FocusOn
                onClickOutside={handleAppNavOutsideClick}
                onEscapeKey={handleAppNavOutsideClick}
                enabled={menuOpen}
              >
                <AnimatePresence>
                  <motion.div
                    initial={false}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { delay: 0.2 } }}
                    className={cx('App-sidebarInner', {
                      'light-mode': menuOpen,
                    })}
                    onClick={onMenuClick}
                  >
                    <div className="App-sidebarTop">
                      <button
                        className="App-sidebarToggleBtn"
                        onClick={(evt) => {
                          evt.stopPropagation();
                          onMenuClick(evt);
                        }}
                      >
                        {menuOpen ? <icons.close /> : <icons.menu />}
                      </button>
                    </div>
                    <div className="App-sidebarBottom">
                      <div className="App-sidebarWordmark f-subhead-01">
                        Rookery
                      </div>
                    </div>
                  </motion.div>
                </AnimatePresence>

                <div className="App-nav light-mode">
                  <ul>
                    {navItems.map(({ label, ...props }, i) => (
                      <li key={label}>
                        <NavLink
                          className="App-navLink"
                          onClick={onNavLinkClick}
                          {...props}
                          style={{
                            zIndex: navItems.length - i,
                          }}
                        >
                          <Heading className="f-heading-01">{label}</Heading>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                  <div className="App-navBottom">
                    <Button
                      onClick={onNavLinkClick}
                      element={Link}
                      className="link"
                      label={user.data.name}
                      secondary
                      elProps={{
                        to: '/settings',
                      }}
                    />
                  </div>
                  <div className="App-navArtworkAdd">
                    <form onSubmit={onLogoutSubmit}>
                      <Button
                        onClick={onNavLinkClick}
                        label="Sign out"
                        elProps={{
                          type: 'submit',
                        }}
                      />
                    </form>
                  </div>
                </div>
              </FocusOn>
            </div>

            <AppMain fixed={roomDetailPathMatches}>
              {showInactiveError ? (
                <AccountDisabled />
              ) : (
                <BackgroundStateProvider isBackground={hasBackground}>
                  <AnimatePresence exitBeforeEnter={!routeAnimationDisabled}>
                    <Switch location={rootLocation} key={rootLocationPathname}>
                      <Route path="/" exact>
                        <RouteScreen>
                          <Redirect to="/rooms-preview" />
                        </RouteScreen>
                      </Route>
                      <Route path="/artworks" exact>
                        <RouteScreen>
                          <InventoryIndex />
                        </RouteScreen>
                      </Route>
                      <Route path="/artworks/new">
                        <RouteScreen>
                          <AddArtworkScreen />
                        </RouteScreen>
                      </Route>
                      <Route path="/artworks/edit/:id">
                        <RouteScreen>
                          <AddArtworkScreen />
                        </RouteScreen>
                      </Route>
                      <Route path="/artworks/:id">
                        <RouteScreen>
                          <ArtworkDetail />
                        </RouteScreen>
                      </Route>
                      <Route path="/rooms" exact>
                        <RouteScreen>
                          <RoomsIndex />
                        </RouteScreen>
                      </Route>
                      <Route path="/rooms-preview" exact>
                        <RouteScreen isRoomPreview>
                          <RoomsIndexPreviewable />
                        </RouteScreen>
                      </Route>
                      <Route path={['/rooms/new', '/rooms/:id']}>
                        <RouteScreen isFixedPanel>
                          <div className="App-fixedPanel">
                            <RoomComposer />
                          </div>
                        </RouteScreen>
                      </Route>
                      <Route path="/reset">
                        <RouteScreen>
                          <ResetPassword />
                        </RouteScreen>
                      </Route>
                      <Route path="/settings">
                        <RouteScreen>
                          <SettingsScreen />
                        </RouteScreen>
                      </Route>
                      <Route path="*">
                        <RouteScreen>
                          <Error404 />
                        </RouteScreen>
                      </Route>
                    </Switch>
                  </AnimatePresence>
                </BackgroundStateProvider>
              )}
            </AppMain>

            {!showInactiveError && (
              <div className="App-detailPanel">
                <AnimatePresence exitBeforeEnter>
                  {hasBackground && (
                    <DetailPanel>
                      <AnimatePresence exitBeforeEnter>
                        <Switch
                          location={location}
                          key={`modal–${location.pathname}`}
                        >
                          <Route path="/artworks/new">
                            <RouteScreen className="DetailPage-routePage">
                              <ArtworkForm />
                            </RouteScreen>
                          </Route>
                          <Route path="/artworks/edit/:id">
                            <RouteScreen className="DetailPage-routePage">
                              <ArtworkForm />
                            </RouteScreen>
                          </Route>
                          <Route path="/artworks/:id">
                            <RouteScreen className="DetailPage-routePage">
                              <ArtworkDetail />
                            </RouteScreen>
                          </Route>
                        </Switch>
                      </AnimatePresence>
                    </DetailPanel>
                  )}
                </AnimatePresence>
              </div>
            )}
          </motion.div>
        ) : loggedIn ? (
          <motion.div
            className="App-loading"
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="App-loadingLoader"></div>
          </motion.div>
        ) : (
          <motion.div
            className="App-login"
            key="login"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Switch>
              <Route path="/reset">
                <ResetPassword />
              </Route>
              <Route path="*">
                <LoginScreen key="login" />
              </Route>
            </Switch>
          </motion.div>
        )}
      </AnimatePresence>

      <div id="detailPanelPortal" className="App-detailPanel"></div>

      <div id="dialogPortal" className="App-dialog"></div>

      <AnimatePresence>
        {toast && (
          <motion.div
            key={toast.message}
            initial={{ y: '100%' }}
            animate={{ y: '0px' }}
            exit={{ y: '100%' }}
            className="App-toast"
            transition={{
              ease: 'easeInOut',
            }}
          >
            <Toast
              message={toast.message}
              type={toast.type}
              onCloseClick={() => hideToast(toast.id)}
            />
          </motion.div>
        )}
      </AnimatePresence>

      {compatError && (
        <FocusOn>
          <div className="App-compatError">
            <div className="CompatError">
              <div className="CompatError-msg">
                <Heading className="f-heading-01">
                  Sorry, Rookery does not yet support smaller screens.
                </Heading>
              </div>
              <div className="CompatError-title f-subhead-01">Rookery</div>
            </div>
          </div>
        </FocusOn>
      )}

      {inputType === 'touch' && (
        <FocusOn>
          <div className="App-compatError">
            <div className="CompatError">
              <div className="CompatError-msg">
                <Heading className="f-heading-01">
                  Sorry, Rookery does not yet support touch screens.
                </Heading>
              </div>
              <div className="CompatError-title f-subhead-01">Rookery</div>
            </div>
          </div>
        </FocusOn>
      )}

      <Helmet>
        <meta property="og:url" content={window.location.href} />
      </Helmet>
    </div>
  );
}

export default App;

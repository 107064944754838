import React, { useCallback } from 'react';
import cx from 'classnames';

// Assets
import './ArtworkFileRow.css';

// Components
import Button from '../Button';

export default function ArtworkFileRow({
  file,
  extraFileData = null,
  onDeleteClick,
  editable = false,
  failed = false,
  uploading = false,
}) {
  let handleDeleteClick = useCallback(
    (evt) => {
      onDeleteClick(file);
    },
    [file, onDeleteClick]
  );

  // `name` will be used once the file has been uploaded
  // `path` comes from the local file
  let name = file.name || file.path;

  // Laravel doesn't like to save-back computed attributes, so we pass it down as "extra file data"
  // that doesn't get submitted with the form
  let url = extraFileData?.url;

  let El = url ? 'a' : 'div';

  let linkOpts = url
    ? {
        href: url,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};

  return (
    <div
      className={cx('ArtworkFileRow', {
        'is-new': !file.id && !failed && !uploading,
        'is-failed': failed,
      })}
    >
      <El className="ArtworkFileRow-inner" {...linkOpts}>
        {uploading ? 'Uploading file...' : name}
      </El>
      {editable && (
        <div className="ArtworkFileRow-deleteBtn">
          <Button
            secondary
            elProps={{ type: 'button' }}
            icon="close"
            onClick={handleDeleteClick}
          />
        </div>
      )}
    </div>
  );
}

import React from 'react';

export default function Menu24() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 7H6V8H18V7Z" fill="currentColor" />
      <path d="M18 11H6V12H18V11Z" fill="currentColor" />
      <path d="M18 15H6V16H18V15Z" fill="currentColor" />
    </svg>
  );
}

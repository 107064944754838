import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Button.css';

import icons from '../_icons';

export let Button = React.forwardRef(function Button(
  {
    label,
    icon,
    onClick,
    element = 'button',
    secondary = false,
    disabled = false,
    fauxDisabled = false,
    error = false,
    selected = false,
    elProps,
    children,
  },
  ref
) {
  let Icon = icon ? icons[icon] : null;
  let Element = element;

  return (
    <Element
      ref={ref}
      className={cx('Button', {
        'Button--withIcon': Boolean(icon),
        'Button--primary': !error && !secondary,
        'Button--secondary': !error && secondary,
        'Button--disabled': !error && fauxDisabled,
        'Button--selected': selected,
        'Button--error': error,
      })}
      onClick={onClick}
      disabled={disabled}
      {...elProps}
    >
      <span className="Button-inner">
        {Icon && (
          <span className="Button-icon">
            <Icon />
          </span>
        )}
        {label && <span className="Button-label f-body-01">{label}</span>}
        {children}
      </span>
    </Element>
  );
});

Button.propTypes = {
  /**
   * The button's main text label
   */
  label: PropTypes.string,
  /**
   * A string, matching the key of one of the project's icons
   */
  icon: PropTypes.string,
  /**
   * The name of an HTML element to use for the button
   */
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Fired when clicking the button
   * @param evt Original MouseEvent from React
   */
  onClick: PropTypes.func,
  /**
   * If true, the button will be secondary instead of primary
   */
  secondary: PropTypes.bool,
  /**
   * If `true`, the button will be look & be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the button will look disabled, but onClick events still trigger
   */
  fauxDisabled: PropTypes.bool,
  /**
   * If `true`, the button will be red to signal an error state
   */
  error: PropTypes.bool,
  /**
   * Any other native props for the React element
   */
  elProps: PropTypes.object,
  /**
   * If `true`, it will appear selected
   */
  selected: PropTypes.bool,
};

Button.defaultProps = {
  element: 'button',
  onClick: () => null,
  secondary: false,
  selected: false,
  disabled: false,
  fauxDisabled: false,
};

import React from 'react';

export default function Add24() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.5 6V19" stroke="currentColor" strokeMiterlimit="10" />
      <path d="M19 12.5H6" stroke="currentColor" strokeMiterlimit="10" />
    </svg>
  );
}

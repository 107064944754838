import { useEffect, useState } from 'react';
import whatInput from 'what-input';

export let useInputType = () => {
  let [inputType, setInputType] = useState(null);
  useEffect(() => {
    let onInputChange = (type) => {
      setInputType(type);
    };
    whatInput.registerOnChange(onInputChange, 'intent');
    return () => {
      whatInput.unRegisterOnChange(onInputChange);
    };
  });
  return inputType;
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import IndexTable from '../IndexTable';
import { useDebounce } from 'react-use';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import qs from 'qs';

// Assets
import './InventoryIndex.css';

// Components
import Button from '../Button';
import SearchField from '../_forms/SearchField';
import Portal from '../Portal';
import AcknowledgementDialog from '../AcknowledgementDialog';
import IndexLayout from '../IndexLayout';

// Hooks
import { useErrors } from '../../hooks/Error';
import { useBackgroundState } from '../../hooks/BackgroundState';

export default function InventoryIndex() {
  let { setError } = useErrors();

  // Store whether an error was triggered from this component
  // so that we can prevent react-query from retrying
  let [errored, setErrored] = useState(false);

  let queryParams = new URLSearchParams(window.location.search);
  let searchTerm = queryParams.get('q');
  let history = useHistory();
  let searchTermLastUpdatedRef = useRef(null);
  let [prevSearchTerm, setPrevSearchTerm] = useState('');

  let { getUrlWithBackground } = useBackgroundState();

  let getArtworks = useCallback(
    (key, vars) => axios.get(`/api/me/artworks`),
    []
  );

  useEffect(() => {
    searchTermLastUpdatedRef.current = Date.now();
  }, [searchTerm]);

  let [searchInputValue, setSearchInputValue] = useState(null);

  let onSearchInputChange = useCallback((evt) => {
    setSearchInputValue({
      value: evt.target.value,
      updatedAt: Date.now(),
    });
  }, []);

  // Regularly sync the text field to the URL
  useDebounce(
    () => {
      if (searchInputValue?.value !== searchTerm) {
        setPrevSearchTerm(searchTerm);
        history.replace({
          search: qs.stringify({
            ...Object.fromEntries(queryParams),
            order_dir: searchInputValue?.value
              ? undefined
              : queryParams.get('order_dir') || undefined,
            order_by: searchInputValue?.value
              ? undefined
              : queryParams.get('order_by') || undefined,
            q: searchInputValue?.value || undefined,
          }),
        });
      }
    },
    500,
    [searchInputValue]
  );

  // Sync the URL to the text field, if it is older
  useEffect(() => {
    if (searchInputValue === searchTerm) {
      return;
    }

    let now = Date.now();
    if (
      searchTermLastUpdatedRef &&
      (!searchInputValue?.updatedAt ||
        searchTermLastUpdatedRef.current > searchInputValue?.updatedAt)
    ) {
      setSearchInputValue({
        value: searchTerm,
        updatedAt: now,
      });
    }
  }, [searchInputValue, searchTerm]);

  let {
    data: artworks,
    error: artworksError,
    status: artworksStatus,
  } = useInfiniteQuery(
    !errored && ['me/artworks', { q: '' }],
    () => {
      return getArtworks();
    },
    {
      staleTime: 30 * 1000,
      getFetchMore: (lastGroup, allGroups) =>
        lastGroup.data.current_page === lastGroup.data.last_page
          ? null
          : lastGroup.data.current_page + 1,
    }
  );

  useEffect(() => {
    if (artworksStatus === 'error') {
      setErrored(true);
      setError({
        error: artworksError,
        message:
          'Something went wrong getting your artworks. Please try again.',
      });
    }
  }, [artworksError, artworksStatus, setError]);

  let artworksFirstPage = artworks[0]?.data?.data?.length;
  let isLoading = artworksStatus === 'loading' && !errored;
  let isErrored = errored;
  let isEmpty = !isLoading && !isErrored && !artworksFirstPage;
  let [dialogDismissed, setDialogDismissed] = useState(false);

  let actions = (
    <>
      <div className="InventoryIndex-search">
        <SearchField
          onChange={onSearchInputChange}
          value={searchInputValue?.value}
        />
      </div>
      <Button
        label="Add artwork"
        icon="add"
        element={Link}
        elProps={{
          to: getUrlWithBackground('/artworks/new'),
        }}
      />
    </>
  );

  return (
    <div className="InventoryIndex">
      <IndexLayout title="Index" actions={actions}>
        {isErrored ? (
          <div className="InventoryIndex-error">
            Something went wrong.
            <br />
            <br />
            <button className="link" onClick={() => window.location.reload()}>
              Reload
            </button>
          </div>
        ) : (
          <IndexTable
            searchTerm={searchTerm}
            prevSearchTerm={prevSearchTerm}
            searchInputValue={searchInputValue?.value || ''}
            isParentEmpty={isEmpty}
            isParentLoading={isLoading}
          />
        )}
      </IndexLayout>
      <Portal id="dialogPortal">
        {isEmpty && !dialogDismissed && (
          <AcknowledgementDialog
            title="Welcome to Rookery"
            message="Select the ‘Add Artwork’ button below to upload your artwork and get started."
            confirmLabel="Add artwork"
            onConfirm={() => {
              history.push(getUrlWithBackground('/artworks/new'));
              setDialogDismissed(true);
            }}
            onCancel={() => {
              setDialogDismissed(true);
            }}
          />
        )}
      </Portal>
    </div>
  );
}

import React, { useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Assets
import './RoomPreview.css';

// Config
import { PERSPECTIVE_DEGREES } from '../../config/room';

// Components
import RoomPreviewArtwork from './RoomPreviewArtwork';

// Hooks
import { RoomScaleProvider } from '../RoomComposer/hooks/useRoomScale';
import { useContain } from '../../hooks/useContain';

const DEFAULT_ASPECT_RATIO = 2 / 1;
const DEFAULT_ROOM_WIDTH = 144;

export default function RoomPreview({
  room = null,
  placements = null,
  fade = true,
}) {
  let aspectRatio = room
    ? room.width_inches / room.height_inches
    : DEFAULT_ASPECT_RATIO;
  let { setRef, dimensions: wallDimensionsPx } = useContain({
    aspectRatio,
  });

  let scaleValues = useMemo(
    () => ({
      widthInches: room ? room.width_inches : DEFAULT_ROOM_WIDTH,
      widthPx: wallDimensionsPx?.width,
    }),
    [room, wallDimensionsPx]
  );

  return (
    <div className="RoomPreview">
      <div className="RoomPreview-inner" ref={setRef}>
        <div
          className="RoomPreview-wall"
          style={{
            width: wallDimensionsPx?.width || undefined,
            height: wallDimensionsPx?.height || undefined,
          }}
        >
          <div
            className="RoomPreview-wallLine"
            style={{
              top: 0,
              left: 0,
              transform: `rotate(${PERSPECTIVE_DEGREES}deg) translate(-100%, -100%)`,
              transformOrigin: '0% 0%',
            }}
          ></div>
          <div
            className="RoomPreview-wallLine"
            style={{
              bottom: 0,
              left: 0,
              transform: `rotate(${
                0 - PERSPECTIVE_DEGREES
              }deg) translate(-100%, 100%)`,
              transformOrigin: '0% 0%',
            }}
          ></div>
          <div
            className="RoomPreview-wallLine"
            style={{
              top: 0,
              right: 0,
              transform: `rotate(${
                0 - PERSPECTIVE_DEGREES
              }deg) translate(100%, 0)`,
              transformOrigin: '100% 0%',
            }}
          ></div>
          <div
            className="RoomPreview-wallLine"
            style={{
              bottom: 0,
              right: 0,
              transform: `rotate(${PERSPECTIVE_DEGREES}deg) translate(100%, 0)`,
              transformOrigin: '100% 0%',
            }}
          ></div>
          <AnimatePresence exi>
            {scaleValues.widthPx && room && (
              <RoomScaleProvider value={scaleValues} key={room.id}>
                <motion.div
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: fade ? 0.2 : 1 }}
                  exit={{ scale: 0.9, opacity: 0 }}
                  transition={{ ease: [0.0, 0.0, 0.3, 1.0], duration: 0.3 }}
                  className="RoomPreview-artworks"
                >
                  {placements?.map((placement) => (
                    <RoomPreviewArtwork
                      placement={placement}
                      key={placement.id}
                    />
                  ))}
                </motion.div>
              </RoomScaleProvider>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

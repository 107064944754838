import React from 'react';
import { Link } from 'react-router-dom';
import { useLifecycles } from 'react-use';

// Components
import Button from '../Button';

// Assets
import './Error404.css';

// Hooks
import { useTheme, LIGHT_MODE, DARK_MODE } from '../../hooks/Theme';
import { Heading } from '../../hooks/DocumentOutline';

export let Error404 = () => {
  let { setTheme } = useTheme();

  useLifecycles(
    () => {
      setTheme(DARK_MODE);
    },
    () => {
      setTheme(LIGHT_MODE);
    }
  );

  return (
    <div className="Error404">
      <div className="Error404-leftCol Error404-col">
        <Heading className="f-heading-01">404</Heading>
      </div>
      <div className="Error404-rightCol Error404-col">
        <Heading className="f-heading-01">
          The page you were looking for does not exist.
        </Heading>
        <div className="Error404-footer">
          <div className="Error404-footerNote">
            Please check the URL and try again.
          </div>
          <div className="Error404-footerAction">
            <Button element={Link} elProps={{ to: '/' }} label="Go to Index" />
          </div>
        </div>
      </div>
    </div>
  );
};
